import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import defaultTheme from "./themes"
import { ThemeProvider } from "@material-ui/core"
import Slide from "@material-ui/core/Slide"
import i18next from "i18next"
import { I18nextProvider } from "react-i18next"
import { SnackbarProvider } from "notistack"
import { store } from "./store/index"
import { Provider } from "react-redux"
import common_en from "./locales/en/pasted.json"
import common_zh from "./locales/zh_Hant_HK/pasted.json"
import common_cn from "./locales/zh_CN/pasted.json"

i18next.init({
  interpolation: { escapeValue: true },
  lng: "en", // language to use
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "span"],
  },
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    zh: {
      common: common_zh,
    },
    cn: {
      common: common_cn,
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <ThemeProvider theme={defaultTheme}>
          <SnackbarProvider
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            TransitionComponent={Slide}>
            <App />
          </SnackbarProvider>
        </ThemeProvider>
      </I18nextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
)
