import { store } from "../store"
import { isEmpty } from "lodash"
import { collectType } from "../common/config"
import { find } from "lodash"
import { itemType } from "../common/config"
import { addDays, compareAsc } from "date-fns"
import { parseyyyyMMdd2Date, isDateInRange, getOnlyDate } from "./helpers"

const stepsCanSkip = {
  billing: data => {
    const { member, settings } = store.getState()

    return hasBillingMethod(settings.billing, member.user)
    // return member?.memberCards?.length > 0 || member?.user?.billingMethod
  },
  account: data => {
    // can skip if user is logged in
    const { auth } = store.getState()
    return auth?.accessToken
  },
}

export const stepsGetError = {
  boxes: data => {
    const { boxes, appliedPromo, deliveryTime } = data
    const { settings } = store.getState()

    // Check at least a box item was selected
    if (!settings?.items.some(item => boxes[item.type] > 0)) {
      return true
    }

    const { subtotal } = calculateOrderTotal({ boxes, settings, appliedPromo })
    if (subtotal < settings?.customFields?.min_charge) {
      return true
    }

    if (appliedPromo?.code) {
      return hasItems(boxes, itemType.DOCUMENT)
    }

    if (appliedPromo?.code) {
      return isPromoCodeExpired(
        appliedPromo,
        deliveryTime?.collect,
        deliveryTime?.collectDate?.date
      )
    }
  },
  address: data => {
    const { deliveryAddress } = data
    return isEmpty(deliveryAddress)
  },
  scheduleAddress: data => {
    const { deliveryAddress, deliveryTime } = data
    const { roomFloor, buildingName, area, phone } = deliveryAddress
    const { timeslot, date } = deliveryTime
    return !roomFloor || !buildingName || !area || !date || !phone || !timeslot
  },
  time: data => {
    const { deliveryTime } = data
    return isEmpty(deliveryTime)
  },
}

export const canGoToStep = (data, { steps, step } = {}) => {
  const curI = steps.indexOf(step)

  let i = 0
  // from 0 to curI, all steps must validate, else we stop at the first not validating
  for (; i <= curI; i++) {
    // if currentStep is undefined, start is -1, so we start searching from 0, all good
    const canSkip = stepsCanSkip[steps[i]]

    if (canSkip && canSkip(data)) {
      // can skip
      continue
    }
    const getError = stepsGetError[steps[i]]
    if (!getError?.(data)) {
      // no error, so continue
      continue
    }

    // this step is not valid, so return it
    return steps[i]
  }
}

export default function getStep(data, { steps, step, noSkip, mode } = {}) {
  const curI = steps.indexOf(step)

  let i = 0
  // from 0 to curI, all steps must validate, else we stop at the first not validating
  for (; i <= curI; i++) {
    // if currentStep is undefined, start is -1, so we start searching from 0, all good
    const canSkip = stepsCanSkip[steps[i]]

    if (canSkip && canSkip(data, mode)) {
      // can skip
      continue
    }
    const getError = stepsGetError[steps[i]]
    if (!getError?.(data, mode)) {
      // no error, so continue
      continue
    }

    // this step is not valid, so return it
    return steps[i]
  }

  if (noSkip) return step || steps[i]

  // then check next steps, return the first one you can't skip
  for (; i < steps.length; i++) {
    const canSkip = stepsCanSkip[steps[i]]

    if (canSkip && canSkip(data, mode)) {
      // can skip
      continue
    }
    // can't skip, so return
    return steps[i]
  }

  return undefined // we're done, all steps are passed
}

export const hasItems = (boxes, type) => {
  return boxes && boxes[type]
}

export const buildOrderData = data => {
  const { boxes, appliedPromo, deliveryAddress, deliveryTime } = data
  const deliveryTimeData = {
    ...deliveryTime,
    date: deliveryTime.date.date,
    collectDate:
      deliveryTime.collect === collectType.IMMEDIATELY
        ? deliveryTime.date.date
        : deliveryTime.collectDate.date,
    collectTimeslot:
      deliveryTime.collect === collectType.IMMEDIATELY
        ? deliveryTime.timeslot
        : deliveryTime.collectTimeslot,
  }
  const deliveryAddressData = {
    ...deliveryAddress,
    address: `${deliveryAddress.roomFloor}, ${deliveryAddress.buildingName}`,
  }
  const customFields = {
    address_line1: deliveryAddress.roomFloor,
    address_line2: deliveryAddress.buildingName,
    covidexposure: deliveryTime.covidexposure ? "Yes" : "No",
    staircarry: deliveryTime.staircarry ? "Yes" : "No",
    exxtracarecover: deliveryTime.exxtracarecover
      ? deliveryTime.exxtracarecoverValue
      : 0,
    promocode: appliedPromo?.code ? appliedPromo.code : "",
  }

  // add exxtracarecover_name to customFields of exxtracarecover is selected
  if (deliveryTime.exxtracarecover) {
    const insurances = getInsurancesFromSettings()
    const insuranceFee = find(insurances, {
      amount: deliveryTime?.exxtracarecoverValue,
    })
    customFields.exxtracarecover_name = insuranceFee.title['en']
  }

  return boxes
    ? {
        ...deliveryAddressData,
        ...deliveryTimeData,
        boxCounts: boxes,
        appliedPromo,
        customFields,
      }
    : {
        ...deliveryAddressData,
        ...deliveryTimeData,
        customFields,
      }
}

export const orderToStoreData = order => {
  const data = {
    appliedPromo: order.customFields.promocode
      ? { code: order.customFields.promocode }
      : null,
    deliveryAddress: {
      phone: order.phone,
      roomFloor: order.customFields.address_line1,
      buildingName: order.customFields.address_line2,
      area: order.area,
      instructions: order.instructions,
      altPhone: order.altPhone,
    },
    deliveryTime: {
      date: { date: order.date },
      timeslot: order.timeslot,
      collectDate: { date: order.collectDate },
      collectTimeslot: order.collectTimeslot,
      collect: order.collect,
      covidexposure: order.customFields.covidexposure === "Yes",
      staircarry: order.customFields.staircarry === "Yes",
      exxtracarecover: order.customFields.exxtracarecover !== 0 ? true : false,
      exxtracarecoverValue: order.customFields.exxtracarecover
        ? order.customFields.exxtracarecover
        : 0,
    },
    error: null,
  }

  if (order.boxCounts) {
    data.boxes = order.boxCounts
  }

  return data
}

export const getTotalBoxes = boxCounts => {
  return boxCounts
    ? Object.values(boxCounts).reduce((total, value) => total + value)
    : 0
}

export const calculateOrderTotal = ({
  boxes,
  appliedPromo,
  settings,
  deliveryAddress,
  deliveryTime,
  numberOfItems,
  mode,
}) => {
  const deliveryRegion = find(settings?.areas, { id: deliveryAddress?.area })

  if (mode === "deliver_to_me") {
    let total = 0
    // add delivery and pickup region fee
    if (deliveryRegion?.surcharge) {
      total += deliveryRegion?.surcharge
    }
    const deliveryFee =
      settings?.customFields?.delivery_charge_trip +
      settings?.customFields?.delivery_charge_item * numberOfItems

    return {
      orderDetailItems: null,
      deliveryFee,
      total: total + deliveryFee,
      deliveryRegion,
    }
  } else {
    const orderDetailItems = settings?.items
      ?.map(item => ({
        id: item.id,
        title: item.title.en,
        appliedCode: item.type === "bin" && appliedPromo?.code,
        priceAfterAppliedCode:
          item.type === "bin" && appliedPromo?.code
            ? item.price - Math.abs(appliedPromo?.amount)
            : item.price,
        price: item.price,
        quantity: boxes[item.type],
        subtotal:
          item.type === "bin" && appliedPromo?.code
            ? (item.price - Math.abs(appliedPromo?.amount)) * boxes[item.type]
            : item.price * boxes[item.type],
      }))
      .filter(item => item.quantity > 0)
    // subtotal
    const subtotal = orderDetailItems?.reduce(
      (acc, obj) => acc + obj.subtotal,
      0
    )
    let total =
      subtotal < settings?.customFields?.min_charge
        ? settings?.customFields?.min_charge
        : subtotal

    // add delivery and pickup region fee
    if (deliveryRegion?.surcharge) {
      total += deliveryRegion?.surcharge
    }

    if (deliveryTime?.exxtracarecover) {
      total += deliveryTime?.exxtracarecoverValue * getTotalBoxes(boxes)
    }

    return {
      orderDetailItems,
      deliveryRegion,
      subtotal,
      total,
    }
  }
}

export const isEditableOrCancellation = (order, minimumDayOfCancellation) => {
  const date =
    order.collect === collectType.IMMEDIATELY ? order.date : order.collectDate
  const today = new Date()
  const cancellationDate = addDays(
    parseyyyyMMdd2Date(date),
    minimumDayOfCancellation
  )
  // if today greater than cancellationDate then show too late message
  // compareAsc(today, cancellationDate) return 1 mean today after cancellationDate
  return compareAsc(today, cancellationDate) !== 1
}

export const isPromoCodeExpired = (promoCode, collect, collectDate) => {
  const validPromoCode =
    collect === collectType.IMMEDIATELY
      ? isDateInRange(
          getOnlyDate(new Date()),
          parseyyyyMMdd2Date(promoCode.validFrom),
          parseyyyyMMdd2Date(promoCode.validTo)
        )
      : isDateInRange(
          parseyyyyMMdd2Date(collectDate),
          parseyyyyMMdd2Date(promoCode.validFrom),
          parseyyyyMMdd2Date(promoCode.validTo)
        )
  return !validPromoCode
}

export const getPromotionsFromSettings = () => {
  const { settings } = store.getState()
  const { promotions } = settings.customFields
  if (promotions) {
    try {
      const promotionsToJson = JSON.parse(promotions)
      return promotionsToJson.promos
    } catch (err) {
      return []
    }
  }
  return []
}

export const getInsurancesFromSettings = () => {
  const { settings } = store.getState()
  const { insurances } = settings.customFields
  if (insurances) {
    try {
      const insurancesToJson = JSON.parse(insurances)
      return insurancesToJson.insurances
    } catch (err) {
      return []
    }
  }
  return []
}

export const getFirstInsurancesOption = () => {
  const insurances = getInsurancesFromSettings()
  return insurances.length > 0 ? insurances[0].amount : 0
}

export const hasBillingMethod = function (billingMethod, user) {
  const userBillingMethod = user && user.billingMethod;

  if (!billingMethod) {
    return true;
  }

  else if (userBillingMethod === 'invoice') {
    return true;
  }

  else {
    return userBillingMethod === billingMethod;
  }
};
