import { useState } from "react"
import { PropTypes } from "prop-types"
import clsx from "clsx"
import { Box, Divider, makeStyles, alpha } from "@material-ui/core"
import Item from "./components/Item"
import FButton from "../../../components/F.Button"
import { CarouselPlugins } from "./components/CarouselPlugins"
import Carousel, { Dots } from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"
import FStepper from "../../../components/F.Stepper"
import { useTranslation } from "react-i18next"
import { orderSteps, itemType } from "../../../common/config"
import {
  getTotalBoxes,
  calculateOrderTotal,
  hasItems,
  getPromotionsFromSettings,
} from "../../../common/orderHelpers"
import {
  isDateInRange,
  getOnlyDate,
  parseyyyyMMdd2Date,
} from "../../../common/helpers"
import { useSnackbar } from "notistack"
import { applyPromoCode } from "../../../store/features/orderBoxes/orderBoxesSlice"
import { useDispatch } from "react-redux"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 0, 2, 0),
    backgroundColor: alpha(theme.palette.common.white, 0.93),
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2, 3, 2, 3),
    },
  },
  orderBoxesContainer: {
    flexGrow: 1,
    position: "relative",
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(4),
    },
  },
  orderBoxes: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
    overflowY: "scroll",
    scrollbarWidth: "none" /* Firefox */,
    "-ms-overflow-style": "none" /* IE and Edge */,
    "&::-webkit-scrollbar": {
      display: "none" /* Safari and Chrome */,
    },
  },
  itemsDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  itemsMobile: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  onlyOneItemsMobile: {
    padding: theme.spacing(0, 3, 0, 3),
  },
  orderBoxesAction: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0, 3, 0, 3),
    [theme.breakpoints.up("sm")]: {
      padding: 0,
    },
  },
  divider: {
    backgroundColor: "#707070",
    margin: theme.spacing(4, 0, 4, 0),
    opacity: 0.25,
  },
  dots: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& .BrainhubCarousel__dot": {
      padding: theme.spacing(1),
      "&::before": {
        width: 9,
        height: 9,
        backgroundColor: "#D6D6D6",
      },
      "&--selected": {
        "&::before": {
          backgroundColor: "#CC0001",
        },
      },
    },
  },
  errorMessage: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
  },
}))

const OrderBoxes = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { settings, boxes, appliedPromo, onComplete, gotoStep } = props
  const [t] = useTranslation("common")
  const totalBoxes = getTotalBoxes(boxes)
  const [dotValue, setDotValue] = useState(0)
  const [promos] = useState(() => getPromotionsFromSettings())
  const { enqueueSnackbar } = useSnackbar()

  const handleChange = value => {
    if (value < 0 || value >= settings?.items?.length) return
    if (value === 0 || value) {
      setDotValue(value)
    }
  }

  const handleNext = () => {
    if (totalBoxes > 0) {
      // check promo code
      if (appliedPromo?.code) {
        const promo = promos?.find(
          promo =>
            promo.code.toUpperCase() === appliedPromo?.code.toUpperCase(),
        )
        if (promo) {
          // check if bins are selected
          if (hasItems(boxes, itemType.DOCUMENT)) {
            enqueueSnackbar(
              t("orderFlow.message.promotionsAppliedToPlasticBoxOnly"),
              {
                variant: "error",
              },
            )
            return
          }
          // check code is expired
          const inRange = isDateInRange(
            getOnlyDate(new Date()),
            parseyyyyMMdd2Date(promo.validFrom),
            parseyyyyMMdd2Date(promo.validTo),
          )
          if (!inRange) {
            enqueueSnackbar(t("orderFlow.message.theCodeEnteredIsExpired"), {
              variant: "error",
            })
            return
          }
          // passed and calculateOrderTotal
          const { subtotal } = calculateOrderTotal({
            boxes,
            settings,
            appliedPromo: promo,
          })
          if (subtotal < settings?.customFields?.min_charge) {
            promo.minBox &&
              enqueueSnackbar(
                t("orderFlow.message.minChargeWithCode", {
                  minBox: promo.minBox,
                }),
                {
                  variant: "error",
                },
              )
            return
          }
          dispatch(applyPromoCode(promo))
          onComplete && onComplete({ step: orderSteps.BOXES })
        } else {
          enqueueSnackbar(t("orderFlow.message.theCodeEnteredIsInvalid"), {
            variant: "error",
          })
        }
      } else {
        const { subtotal } = calculateOrderTotal({ boxes, settings })
        if (subtotal < settings?.customFields?.min_charge) {
          enqueueSnackbar(t("orderFlow.message.doesNotReachMinCharge"), {
            variant: "error",
          })
          return
        }
        onComplete && onComplete({ step: orderSteps.BOXES })
      }
    } else {
      enqueueSnackbar(t("orderFlow.message.mustSelectBoxesOrBins"), {
        variant: "error",
      })
    }
  }

  return (
    <Box className={classes.root}>
      <FStepper gotoStep={gotoStep} activeStep={0} />
      <Box className={classes.orderBoxesContainer}>
        <Box className={classes.orderBoxes}>
          <Box className={classes.itemsDesktop}>
            {settings?.items?.map((item, index, arr) => (
              <Box key={item.id}>
                <Item data={item} />
                {arr.length - 1 !== index && (
                  <Divider className={classes.divider} />
                )}
              </Box>
            ))}
          </Box>
          <Box
            className={
              settings?.items?.length > 1
                ? classes.itemsMobile
                : clsx(classes.itemsMobile, classes.onlyOneItemsMobile)
            }>
            <Carousel
              draggable={false}
              plugins={
                settings?.items?.length > 1 ? CarouselPlugins : undefined
              }
              value={dotValue}
              slides={settings?.items?.map(item => (
                <Item key={item.id} data={item} />
              ))}
              onChange={handleChange}></Carousel>
            {settings?.items?.length > 1 && (
              <Dots
                className={classes.dots}
                onChange={handleChange}
                value={dotValue}
                number={settings?.items?.length}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box className={classes.orderBoxesAction}>
        <FButton
          title={t("global.form.button.label.next")}
          mt={1}
          onClick={handleNext}
        />
      </Box>
    </Box>
  )
}

OrderBoxes.propTypes = {
  boxes: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
}

export default OrderBoxes
