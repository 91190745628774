import PropTypes from "prop-types"
import { Box } from "@material-ui/core"
import { financial, mapSiteLngToBackendLng } from "../../../../common/helpers"
import { getInsurancesFromSettings, getTotalBoxes } from "../../../../common/orderHelpers"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import FeeItem from "./FeeItem"
import PromoCode from "./PromoCode"
import { find } from "lodash"

const AdditionalFee = props => {
  const [t] = useTranslation("common")
  const { boxes, appliedPromo, deliveryFee, deliveryTime, deliveryRegion, mode } =
    props
  const { lng } = useSelector(state => state.lng)
  const insurances = getInsurancesFromSettings()
  const insuranceFee = find(insurances, {
    amount: deliveryTime?.exxtracarecoverValue,
  })

  return (
    <Box>
      {deliveryFee && (
        <FeeItem
          title={t("orderFlow.label.deliveryFee")}
          desc={t("orderFlow.text.tripBinBoxCharge")}
          fee={`HK$${financial(deliveryFee)}`}
        />
      )}
      {appliedPromo?.code && <PromoCode code={appliedPromo.code} />}
      {mode !== "deliver_to_me" && (
        <>
          {deliveryTime?.exxtracarecover ? (
            <FeeItem
              title={t("orderFlow.label.exXtraCareFee")}
              desc={
                insuranceFee
                  ? insuranceFee.description[mapSiteLngToBackendLng(lng)]
                  : ""
              }
              subDesc={t("orderFlow.text.insuranceFeeDescription")}
              fee={`HK$${financial(insuranceFee.amount * getTotalBoxes(boxes))}`}
            />
          ) : (
            <FeeItem
              title={t("orderFlow.label.exXtraCareFee")}
              desc={t("orderFlow.text.insuranceFeeDescription")}
              fee={t("global.label.free")}
            />
          )}
        </>
      )}
      {deliveryRegion?.surcharge > 0 && (
        <FeeItem
          title={t("orderFlow.label.additionalFee")}
          desc={deliveryRegion.title[lng]}
          fee={`HK$${financial(deliveryRegion.surcharge)}`}
        />
      )}
    </Box>
  )
}

AdditionalFee.propTypes = {
  deliveryRegion: PropTypes.object,
  subtotal: PropTypes.number,
}

export default AdditionalFee
