import { useState, useEffect } from "react"
import {
  useRouteMatch,
  Switch,
  Route,
  useHistory,
  useParams,
} from "react-router-dom"
import { Box, Typography, Fade, makeStyles, alpha } from "@material-ui/core"
import CantEditOrder from "../../layout/CantEditOrder"
import Page from "../../layout/Page"
import getStepHelper, {
  buildOrderData,
  orderToStoreData,
  isEditableOrCancellation,
} from "../../common/orderHelpers"
import { apiEditOrder } from "../../api/order"
import { ROUTES } from "../../common/config"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { fetchMemberInfo } from "../../store/features/member/memberSlice"
import DeleteIcon from "../../assets/icons/delete.png"

const STEPS = ["scheduleAddress", "summary"]

const getStepPath = (curStep, routeMatch) => {
  return `${routeMatch}/${curStep}`
}

const useStyles = makeStyles(theme => ({
  editMessage: {
    color: theme.palette.common.white,
    backgroundColor: alpha("#FFAB02", 0.9),
    textAlign: "center",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}))

const ReScheduleFlow = props => {
  const classes = useStyles()
  const [t] = useTranslation("common")
  const { routes } = props
  const { sid } = useParams()
  const history = useHistory()
  const routeMatch = useRouteMatch()
  const dispatch = useDispatch()
  const { memberValetOrders } = useSelector(state => state.member)
  const settings = useSelector(state => state.settings)
  const [order, setOrder] = useState()
  const [data, setData] = useState()
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    history.replace(getStepPath(STEPS[0], routeMatch.url))
  }, [routeMatch.url, history])

  useEffect(() => {
    const order = memberValetOrders?.find(o => o.sid === sid)
    if (order) {
      setOrder(order)
      setData(orderToStoreData(order))
    }
  }, [memberValetOrders, sid])

  useEffect(() => {
    dispatch(fetchMemberInfo())
  }, [dispatch])

  const editOrder = async data => {
    try {
      setSubmitting(true)
      const newData = buildOrderData(data)
      await apiEditOrder(order?.id, { ...order, ...newData })
      history.push(`${ROUTES.VALET}/items-user`)
    } catch (err) {
      console.error(err)
    } finally {
      setSubmitting(false)
    }
  }

  const onStepComplete = ({ step, stepData }) => {
    const newData = { ...data, ...stepData }
    const newStep = getStepHelper(newData, { step, steps: STEPS })
    if (!newStep) {
      editOrder(data)
    } else {
      setData(newData)
      history.push(getStepPath(newStep, routeMatch.url))
    }
  }

  const stepProps = {
    ...data,
    onComplete: onStepComplete,
    numberOfItems: order?.deliverItems?.length + order?.collectItems?.length,
    submitting,
  }

  return order &&
    isEditableOrCancellation(
      order,
      settings?.customFields?.minimum_day_of_cancellation
    ) ? (
    <Box>
      <Fade in={routeMatch != null} timeout={700} unmountOnExit>
        <Typography className={classes.editMessage}>
          {`${t(
            "orderFlow.text.youCurrentlyEditingUpcomingOrder"
          )} #${order?.sid.toUpperCase()}`}
        </Typography>
      </Fade>
      <Switch>
        {routes?.map(({ path, Component, meta, exact }) => (
          <Route
            path={`${routeMatch.url}${path}`}
            key={`${routeMatch.url}${path}`}
            exact={exact}
          >
            <Page headline={meta?.headline} background={meta?.background}>
              <img
                onClick={() => history.push(`${ROUTES.VALET}/items-user`)}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                }}
                src={DeleteIcon}
                width={44}
                alt="back"
              />
              <Component {...stepProps} />
            </Page>
          </Route>
        ))}
      </Switch>
    </Box>
  ) : (
    <Page headline="global.text.slogan">
      <CantEditOrder />
    </Page>
  )
}

export default ReScheduleFlow
