import { useState } from "react"
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  useTheme,
  IconButton,
  Divider,
  MenuItem,
  MenuList,
  ListItemIcon,
  ListItemText,
  Slide,
  Fade,
  Paper,
  ClickAwayListener,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import Logo from "../assets/images/mobile/logo.png"
import LogoDesktop from "../assets/images/desktop/logo.png"
import HamburgerIcon from "../assets/icons/hamburger_menu.png"
import ItemsWithYouIcon from "../assets/icons/items_with_you.png"
import ItemsInStorageIcon from "../assets/icons/items_in_storage.png"
import OrderBoxesIcon from "../assets/icons/order_boxes.png"
import HelpIcon from "../assets/icons/help.png"
import PersonIcon from "../assets/icons/person.png"
import { useMediaQuery } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { ROUTES } from "../common/config"
import MemberMenu from "./MemberMenu"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { mapSiteLngToBackendLng } from "../common/helpers"
import ReactGA from "react-ga4"

const useStyles = makeStyles(theme => ({
  root: {},
  appBar: {
    backgroundColor: "transparent",
    boxShadow: "none",
    position: "relative",
    "& .MuiToolbar-gutters": {
      [theme.breakpoints.up("sm")]: {
        paddingRight: 0,
      },
    },
  },
  toolbar: {
    backgroundColor: "#CA2026",
    display: "flex",
    alignItems: "center",
    minHeight: 54,
    [theme.breakpoints.up("sm")]: {
      minHeight: 66,
    },
    "& .hamburger-menu": {
      [theme.breakpoints.up("sm")]: {
        display: "none",
        paddingRight: 0,
      },
    },
    "& .desktop-nav": {
      justifySelf: "center",
      display: "flex",
      flexGrow: 1,
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.up("sm")]: {
        paddingLeft: theme.spacing(4),
        justifyContent: "flex-start",
        alignItems: "center",
        cursor: "pointer",
      },
      "& .logo": {
        width: 152,
        [theme.breakpoints.up("sm")]: {
          width: 192,
          marginRight: "4%",
        },
      },
    },
    "& .divider": {
      backgroundColor: "#EA332F",
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
        height: 66,
      },
    },
  },
  btnLogin: {
    padding: "6px 0",
    textTransform: "initial",
    minWidth: "auto",
    fontSize: 10,
    fontWeight: 400,
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    "& .MuiButton-label": {
      alignItems: "flex-end",
      flexDirection: "column",
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
      },
      "& .MuiButton-startIcon": {
        marginLeft: 0,
        marginRight: 0,
        alignSelf: "center",
        alignItems: "center",
        // justifyContent: "center",
        [theme.breakpoints.up("sm")]: {
          alignSelf: "initial",
          marginLeft: theme.spacing(-0.5),
          marginRight: theme.spacing(1),
        },
      },
    },
  },
  menuListPaper: {
    boxShadow: "none",
    background: "#CA2026 0% 0% no-repeat padding-box",
    opacity: 0.91,
    position: "absolute",
    top: 53,
    left: 0,
    right: 0,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  menuList: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: "#FFF",
    marginLeft: "-50%",
    transform: "translateX(50%)",
  },
  memuItem: {
    color: theme.palette.common.white,
    textTransform: "initial",
    padding: theme.spacing(1, 2),
    justifyContent: "flex-start",
    // [theme.breakpoints.up("sm")]: {
    //   justifyContent: "flex-start",
    // },
    "& .MuiButton-startIcon": {
      marginLeft: 0,
      marginRight: 38,
      alignItems: "center",
      [theme.breakpoints.up("sm")]: {
        marginRight: 22,
      },
    },
  },
  menuListDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.common.white,
    },
    "& .MuiListItem-root": {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      "& .MuiListItemIcon-root": {
        minWidth: 40,
        alignItems: "center",
      },
      "& .MuiListItemText-root": {
        "& span": {
          fontSize: theme.typography.pxToRem(16),
          color: theme.palette.common.white,
        },
      },
    },
  },
}))

const menuItems = [
  {
    title: "global.label.itemsWithMe",
    startIcon: ItemsWithYouIcon,
    route: `${ROUTES.VALET}/items-user`,
    ga: "Items with me",
  },
  {
    title: "global.label.itemsInStorage",
    startIcon: ItemsInStorageIcon,
    route: `${ROUTES.VALET}/items-storage`,
    ga: "Items in storage",
  },
  {
    title: "global.label.orderBoxes",
    startIcon: OrderBoxesIcon,
    route: `${ROUTES.VALET}/order-boxes/boxes`,
    ga: "Order boxes",
  },
]

const Header = () => {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const [t] = useTranslation("common")
  const upSm = useMediaQuery(theme.breakpoints.up("sm"))
  const { accessToken } = useSelector(state => state.auth)
  const { lng } = useSelector(state => state.lng)
  const { helpUrl } = useSelector(state => state.settings)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const handleMenuMobileClose = event => {
    setMobileMenuOpen(false)
  }

  const handleMenuItemClick = menuItem => {
    history.push(menuItem.route)
    setMobileMenuOpen(false)
    ReactGA.ga("send", {
      hitType: "pageview",
      page: `${menuItem.route}`,
      title: `${menuItem.ga}`,
    })
  }

  const handleDesktopMenuItemClick = menuItem => {
    history.push(menuItem.route)
    ReactGA.ga("send", {
      hitType: "pageview",
      page: `${menuItem.route}`,
      title: `${menuItem.ga}`,
    })
  }

  const openHelpLink = () => {
    if (helpUrl) {
      window.open(`${helpUrl}?lang=${mapSiteLngToBackendLng(lng)}`, "_blank")
      ReactGA.ga("send", {
        hitType: "pageview",
        page: `${helpUrl}?lang=${mapSiteLngToBackendLng(lng)}`,
        title: "Help",
      })
    }
  }

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="relative">
        <Toolbar className={classes.toolbar}>
          <Box className="hamburger-menu" width={accessToken ? 42 : 34}>
            {accessToken && (
              <IconButton
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                edge="start"
                color="inherit"
                aria-label="menu">
                <img src={HamburgerIcon} alt="menu" />
              </IconButton>
            )}
          </Box>
          <Box className="desktop-nav">
            <img
              className="logo"
              onClick={() =>
                accessToken
                  ? history.push(`${ROUTES.VALET}/items-user`)
                  : history.push(ROUTES.LOGIN)
              }
              src={upSm ? LogoDesktop : Logo}
              alt="Logo"
            />
            <Fade in={Boolean(accessToken)} timeout={350} unmountOnExit>
              <MenuList className={classes.menuListDesktop}>
                {menuItems.map((menuItem, index) => (
                  <MenuItem
                    key={`${index}${menuItem.title}`}
                    onClick={() => handleDesktopMenuItemClick(menuItem)}>
                    <ListItemIcon>
                      <img
                        src={menuItem.startIcon}
                        width={28}
                        alt={t(menuItem.title)}
                      />
                    </ListItemIcon>
                    <ListItemText primary={t(menuItem.title)} />
                  </MenuItem>
                ))}
                <MenuItem onClick={openHelpLink}>
                  <ListItemIcon>
                    <img
                      src={HelpIcon}
                      width={28}
                      alt={t("global.label.help")}
                    />
                  </ListItemIcon>
                  <ListItemText primary={t("global.label.help")} />
                </MenuItem>
              </MenuList>
            </Fade>
          </Box>
          <Divider orientation="vertical" className="divider" />
          {accessToken ? (
            <MemberMenu />
          ) : (
            <Button
              onClick={() => history.push(ROUTES.LOGIN)}
              color="inherit"
              className={classes.btnLogin}
              startIcon={
                <img
                  src={PersonIcon}
                  width={upSm ? 28 : 24}
                  alt="person-icon"
                />
              }>
              {t("login.button.label.login")}
            </Button>
          )}
        </Toolbar>
        <Slide
          direction="right"
          in={mobileMenuOpen}
          timeout={350}
          unmountOnExit>
          <Paper className={classes.menuListPaper}>
            <ClickAwayListener onClickAway={handleMenuMobileClose}>
              <Box className={classes.menuList}>
                {menuItems.map((menuItem, index) => (
                  <Button
                    key={`${index}${menuItem.title}`}
                    className={classes.memuItem}
                    color="inherit"
                    fullWidth
                    startIcon={
                      <img
                        src={menuItem.startIcon}
                        width={18}
                        alt={t(menuItem.title)}
                      />
                    }
                    onClick={() => handleMenuItemClick(menuItem)}>
                    {t(menuItem.title)}
                  </Button>
                ))}
                <Button
                  className={classes.memuItem}
                  color="inherit"
                  fullWidth
                  onClick={() => {
                    setMobileMenuOpen(false)
                    openHelpLink()
                  }}
                  startIcon={
                    <img
                      src={HelpIcon}
                      width={18}
                      alt={t("global.label.help")}
                    />
                  }>
                  {t("global.label.help")}
                </Button>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Slide>
      </AppBar>
    </div>
  )
}

export default Header
