import { PropTypes } from "prop-types"
import { Box, makeStyles } from "@material-ui/core"
import DownIcon from "../assets/icons/down.png"
import DownActiveIcon from "../assets/icons/down_active.png"
import UpIcon from "../assets/icons/up.png"
import UpActiveIcon from "../assets/icons/up_active.png"
import { sortType } from "../common/config"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.typography.pxToRem(25),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down(340)]: {
      marginRight: theme.spacing(0.5),
    },
  },
  image: {
    cursor: "pointer",
    width: 40,
    height: 40,
    [theme.breakpoints.down(400)]: {
      width: 34,
      height: 34,
    },
  },
}))

const FSort = props => {
  const classes = useStyles()
  const { sortDirection, handleSort } = props

  return (
    <Box className={classes.root} display="flex" alignItems="center">
      <img
        className={classes.image}
        src={sortDirection === sortType.ASC ? DownActiveIcon : DownIcon}
        alt="sort asc"
        onClick={() => handleSort(sortType.ASC)}
      />
      <img
        className={classes.image}
        src={sortDirection === sortType.DESC ? UpActiveIcon : UpIcon}
        alt="sort desc"
        onClick={() => handleSort(sortType.DESC)}
      />
    </Box>
  )
}

FSort.propTypes = {
  sortDirection: PropTypes.string.isRequired,
  handleSort: PropTypes.func.isRequired,
}

export default FSort
