import { Divider, makeStyles, Box } from "@material-ui/core"
import CircleLogo from "../assets/images/desktop/circle_logo.png"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    padding: "35px 28px 10px 28px",
    [theme.breakpoints.up("sm")]: {
      marginTop: 60,
      padding: "35px 28px 10px 28px",
    },
    marginTop: 15,
  },
  topDivider: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  divider: {
    margin: "20px 0 10px 0",
  },
}))

const AuthLayout = props => {
  const classes = useStyles()
  const { children } = props

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <img width={90} src={CircleLogo} alt="Logo" />
      </Box>
      <Divider className={classes.topDivider} />
      {children}
    </Box>
  )
}
export default AuthLayout
