import { Grid, Typography, makeStyles } from "@material-ui/core"
import PropTypes from "prop-types"
import { financial, formatDate2ddMMyyyy } from "../../../common/helpers"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(theme => ({
  invoiceLine: {
    marginBottom: theme.spacing(1),
  },
  invoiceLineItem: {
    marginBottom: theme.spacing(0.5),
  },
  title: {
    color: "#3B3B3B",
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
  },
  content: {
    color: "#807F7F",
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
  },
}))

const InvoiceLine = props => {
  const classes = useStyles()
  const [t] = useTranslation("common")
  const { desc, quantity, price, startDate, endDate, total } = props.data

  return (
    <Grid container className={classes.invoiceLine}>
      <Grid className={classes.invoiceLineItem} item xs={12}>
        <Typography className={classes.title}>{desc}</Typography>
        <Typography className={classes.content}>
          {endDate
            ? `${
                startDate && formatDate2ddMMyyyy(startDate)
              } - ${formatDate2ddMMyyyy(endDate)}`
            : startDate && formatDate2ddMMyyyy(startDate)}
        </Typography>
      </Grid>
      <Grid className={classes.invoiceLineItem} item xs={6}>
        <Typography className={classes.title}>
          {t("global.label.price")}
        </Typography>
        <Typography className={classes.content}>{`${quantity} x HK$${financial(
          price
        )}`}</Typography>
      </Grid>
      <Grid className={classes.invoiceLineItem} item xs={6}>
        <Typography className={classes.title} align="right">
          {t("global.label.total")}
        </Typography>
        <Typography className={classes.content} align="right">
          {`HK$${financial(total)}`}
        </Typography>
      </Grid>
    </Grid>
  )
}

InvoiceLine.propTypes = {
  data: PropTypes.object.isRequired,
}

export default InvoiceLine
