import { Dialog, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  promoDialog: {
    "& .MuiPaper-rounded": {
      borderRadius: 0,
    },
    [theme.breakpoints.up("sm")]: {
      "& .MuiDialog-scrollPaper": {
        justifyContent: props =>
          props.justifyContent ? props.justifyContent : "flex-start",
        alignItems: props => (props.alignItems ? props.alignItems : "flex-end"),
      },
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiDialog-paper": {
        margin: theme.spacing(3),
      },
      "& .MuiDialog-paperFullWidth": {
        width: "calc(100% - 24px)",
      },
      "& .MuiDialog-paperScrollPaper": {
        width: "calc(100% - 24px)",
      },
    },
    [theme.breakpoints.down(414)]: {
      "& .MuiDialog-paper": {
        margin: theme.spacing(1),
      },
      "& .MuiDialog-paperFullWidth": {
        width: "calc(100% - 8px)",
      },
      "& .MuiDialog-paperScrollPaper": {
        width: "calc(100% - 8px)",
      },
    },
  },
  promoImage: {
    height: "auto",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 700,
      height: "auto",
    },
  },
}))

const FDialogImage = props => {
  const classes = useStyles(props)
  const { open, setOpen, imageSrc } = props
  return (
    <Dialog
      maxWidth="md"
      onClose={(_, reason) => {
        if (reason === "backdropClick") {
          setOpen(false)
        }
      }}
      className={classes.promoDialog}
      open={open}
    >
      <img className={classes.promoImage} src={imageSrc} alt="dialog" />
    </Dialog>
  )
}

export default FDialogImage
