import { useState } from "react"
import PropTypes from "prop-types"
import {
  makeStyles,
  Box,
  Typography,
  CardMedia,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"
import QuantityControl from "./QuantityControl"
import LoudspeakerIcon from "../../../../assets/icons/loudspeaker.png"
import { useTranslation } from "react-i18next"
import { itemFixedData } from "../../../../data/itemFixedData"
import { useSelector, useDispatch } from "react-redux"
import { promoImages } from "../../../../data/dialogImages"
import FDialogImage from "../../../../components/F.DialogImage"
import { itemType } from "../../../../common/config"
import { getTotalBoxes } from "../../../../common/orderHelpers"
import { changePromoCode } from "../../../../store/features/orderBoxes/orderBoxesSlice"
import FTexField from "../../../../components/F.TextField"
import ReactGA from "react-ga4"

const useStyles = makeStyles(theme => ({
  itemInfoLineContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  itemInfoLine: {
    marginBottom: theme.spacing(0.8),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(0.8),
    },
  },
  label: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  textContent: {
    color: "#6B6B6B",
  },
  promoContainer: {
    backgroundColor: "#FBAD14",
    borderRadius: 23,
    color: theme.palette.common.white,
    alignItems: "center",
    cursor: "pointer",
  },
  noteMessage: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  promoIcon: {
    width: 20,
    height: 20,
    margin: theme.spacing(0.5, 0.5, 0.5, 1.5),
  },
  promoText: {
    flexGrow: 1,
    textAlign: "center",
    marginLeft: -24,
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  promoTextField: {
    marginTop: theme.spacing(2),
    width: "99.9%",
  },
}))

const Item = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { lng } = useSelector(state => state.lng)
  const { customFields } = useSelector(state => state.settings)
  const { data } = props
  const { boxes, appliedPromo } = useSelector(state => state.orderBoxes)
  const totalBoxes = getTotalBoxes(boxes)
  const { image, type, price } = data
  const [openPromo, setOpenPromo] = useState(false)
  const [t] = useTranslation("common")
  const fixedData = itemFixedData[type]
  const theme = useTheme()
  const upSm = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <Box>
      <img src={image} alt={type} width={"100%"} />
      {fixedData && (
        <Box className={classes.itemInfoLineContainer}>
          <Box className={classes.itemInfoLine}>
            <Typography className={classes.label} display="inline">
              {`${t("orderFlow.label.maximumWeight")} : `}
            </Typography>
            <Typography className={classes.textContent} display="inline">
              {fixedData.maximum_weight}
            </Typography>
          </Box>
          <Box className={classes.itemInfoLine}>
            <Typography className={classes.label} display="inline">
              {`${t("orderFlow.label.monthlyStorageCharge")} : `}
            </Typography>
            <Typography className={classes.textContent} display="inline">
              {`HK$${price}`}
            </Typography>
          </Box>
          <Box className={classes.itemInfoLine}>
            <Typography className={classes.label} display="inline">
              {`${t("orderFlow.label.minimumMonthlyCharge")} : `}
            </Typography>
            <Typography className={classes.textContent} display="inline">
              {`HK$${customFields?.min_charge}`}
            </Typography>
          </Box>
          <Box className={classes.itemInfoLine}>
            <Typography className={classes.label} display="inline">
              {`${t("orderFlow.label.minimumStoragePeriod")} : `}
            </Typography>
            <Typography className={classes.textContent} display="inline">
              {`${fixedData.minimum_storage_period} ${t(
                "orderFlow.label.months",
              )}`}
            </Typography>
          </Box>
          <Box className={classes.itemInfoLine}>
            <Typography className={classes.label} display="inline">
              {`${t("orderFlow.label.pickupBoxes")} : `}
            </Typography>
            <Typography display="inline" className={classes.textContent}>
              {`HK$${customFields?.delivery_charge_trip}/${t(
                "orderFlow.label.trip",
              )} + HK$${customFields?.delivery_charge_item}/${t(
                fixedData.message_key,
              ).toLowerCase()} (${t("orderFlow.label.firstTripFree")})`}
            </Typography>
          </Box>
          <Box className={classes.itemInfoLine}>
            <Typography className={classes.label} display="inline">
              {`${t("orderFlow.label.deliverBoxes")} : `}
            </Typography>
            <Typography className={classes.textContent} display="inline">
              {`HK$${customFields?.delivery_charge_trip}/${t(
                "orderFlow.label.trip",
              )} + HK$${customFields?.delivery_charge_item}/${t(
                fixedData.message_key,
              ).toLowerCase()}`}
            </Typography>
          </Box>
        </Box>
      )}
      <QuantityControl boxType={type} />
      {totalBoxes <= 0 && (
        <Typography className={classes.noteMessage} align="center">
          {`${t("orderFlow.message.mustSelectBoxesOrBins")}`}
        </Typography>
      )}

      {type === itemType.BIN && (
        <>
          {customFields?.promotion_enable?.toLowerCase() === "on" && (
            <>
              <Box
                onClick={() => {
                  setOpenPromo(true)
                  ReactGA.ga("send", {
                    hitType: "event",
                    eventCategory: "Button",
                    eventAction: "Money saving promotions",
                    eventLabel: "Money saving promotions",
                  })
                }}
                className={classes.promoContainer}
                display="flex">
                <CardMedia
                  className={classes.promoIcon}
                  image={LoudspeakerIcon}
                />
                <Typography className={classes.promoText}>
                  {`${t("orderFlow.label.moneySavePromotions")}`}
                </Typography>
              </Box>
              <FDialogImage
                setOpen={setOpenPromo}
                open={openPromo}
                imageSrc={
                  upSm
                    ? `${process.env.PUBLIC_URL}/images/${promoImages[lng].desktop}`
                    : `${process.env.PUBLIC_URL}/images/${promoImages[lng].mobile}`
                }
              />
            </>
          )}

          <FTexField
            inputlabelcolor="#6B6B6B"
            className={classes.promoTextField}
            onChange={e => dispatch(changePromoCode(e.target.value))}
            value={appliedPromo?.code || ""}
            label={t("orderFlow.form.label.gotAPromoOrReferralCode")}
          />
        </>
      )}
    </Box>
  )
}

Item.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Item
