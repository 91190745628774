import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import MemberBox from "../../../layout/MemberBox"
import WavesBox from "../../../layout/WavesBox"
import OrderDetailTable from "./components/OrderDetailTable"
import FButton from "../../../components/F.Button"
import SaveIcon from "../../../assets/icons/save_file.png"
import {
  financial,
  formatTimeslotOption,
  formatddLLL_EEEE,
} from "../../../common/helpers"
import { calculateOrderTotal } from "../../../common/orderHelpers"
import { collectType, orderSteps } from "../../../common/config"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import AdditionalFee from "./components/AdditionalFee"
import ReactGA from "react-ga4"

const useStyles = makeStyles(theme => ({
  orderSummary: {
    margin: "5px 24px 0px 24px",
    flexGrow: 1,
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      margin: "16px 16px 0px 16px",
    },
  },
  orderSummaryContent: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
    overflowY: "scroll",
    scrollbarWidth: "none" /* Firefox */,
    "-ms-overflow-style": "none" /* IE and Edge */,
    "&::-webkit-scrollbar": {
      display: "none" /* Safari and Chrome */,
    },
  },
  primaryText: {
    color: "#3B3B3B",
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  secondaryText: {
    color: "#756F86",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  note: {
    color: "#756F86",
    fontSize: theme.typography.pxToRem(14),
  },
  total: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(1.5),
    fontSize: theme.typography.pxToRem(22),
    color: "#3B3B3B",
  },
  btnSubmit: {
    margin: "24px",
    [theme.breakpoints.up("sm")]: {
      margin: "16px 16px 16px 16px",
    },
  },
}))

const OrderSummary = props => {
  const classes = useStyles()
  const {
    boxes,
    appliedPromo,
    settings,
    deliveryAddress,
    deliveryTime,
    onComplete,
    submitting,
    mode,
    numberOfItems,
  } = props
  const [t] = useTranslation("common")
  const { lng } = useSelector(state => state.lng)
  const theme = useTheme()
  const upSm = useMediaQuery(theme.breakpoints.up("sm"))
  const { orderDetailItems, deliveryRegion, deliveryFee, subtotal, total } =
    calculateOrderTotal({
      boxes,
      appliedPromo,
      settings,
      deliveryAddress,
      deliveryTime,
      mode,
      numberOfItems,
    })

  const handleSubmit = async () => {
    onComplete && onComplete({ step: orderSteps.SUMMARY })
    ReactGA.ga("send", {
      hitType: "event",
      eventCategory: "Button",
      eventAction: "Submit order",
      eventLabel: "Submit order",
    })
  }

  return (
    <MemberBox title={t("global.form.heading.orderSummary")}>
      <Box className={classes.orderSummary}>
        <Box className={classes.orderSummaryContent}>
          <WavesBox>
            <Box mb={upSm ? 2 : 1}>
              <Typography className={classes.primaryText}>
                {t("global.label.address")}
              </Typography>
              <Typography className={classes.secondaryText}>
                {`${deliveryAddress?.roomFloor}, ${deliveryAddress?.buildingName}`}
              </Typography>
            </Box>
            <Box mb={upSm ? 2 : 1}>
              <Typography className={classes.primaryText}>
                {t("global.label.phone")}
              </Typography>
              <Typography className={classes.secondaryText}>
                {deliveryAddress?.phone}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.primaryText}>
                {mode === "deliver_to_me"
                  ? t("global.form.label.deliveryDate")
                  : t("orderFlow.label.collectionDate")}
              </Typography>
              <Typography className={classes.secondaryText}>
                {deliveryTime?.collect === collectType.IMMEDIATELY
                  ? `${formatddLLL_EEEE(
                      deliveryTime?.date?.date,
                      lng,
                    )} ${formatTimeslotOption(deliveryTime?.timeslot)}`
                  : `${formatddLLL_EEEE(
                      deliveryTime?.collectDate?.date,
                      lng,
                    )} ${formatTimeslotOption(deliveryTime?.collectTimeslot)}`}
              </Typography>
            </Box>
          </WavesBox>
          {orderDetailItems && <OrderDetailTable items={orderDetailItems} />}
          <Divider className={classes.divider} />
          <Box>
            <AdditionalFee
              boxes={boxes}
              appliedPromo={appliedPromo}
              deliveryRegion={deliveryRegion}
              deliveryTime={deliveryTime}
              deliveryFee={deliveryFee}
              subtotal={subtotal}
              mode={mode}
            />
          </Box>
          <Box>
            <Typography className={classes.total} align="right">
              {`${t("global.label.total")} HK$${financial(total)}`}
            </Typography>
            <Typography className={classes.note}>
              {t("orderFlow.text.orderSummaryNote")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box pl={2} pr={2} pb={2}>
        <FButton
          title={t("global.form.button.label.submit")}
          icon={SaveIcon}
          iconOptions={{
            size: 24,
            marginLeft: 4,
            marginRight: 4,
          }}
          onClick={handleSubmit}
          disabled={submitting}
        />
      </Box>
    </MemberBox>
  )
}
export default OrderSummary
