import { Box, makeStyles } from "@material-ui/core"
import WaveTop from "../assets/images/desktop/wave_top.png"
import WaveBottom from "../assets/images/desktop/wave_bottom.png"
import PropTypes from "prop-types"

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
  },
  image: {
    width: "100%",
    display: "block",
  },
  topRightIcon: {
    cursor: "pointer",
    width: 44,
    position: "absolute",
    top: -15,
    right: -15,
  },
  bottomLeftIcon: {
    cursor: "pointer",
    width: 44,
    position: "absolute",
    left: -15,
    bottom: -15,
  },
  boxContent: {
    backgroundColor: "#FFFFFF8C",
    borderLeft: "1px solid #4E4E4E45",
    borderRight: "1px solid #4E4E4E45",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: props => theme.spacing(props?.padding?.pl || 2),
    paddingRight: theme.spacing(2),
  },
}))

const WavesBox = props => {
  const classes = useStyles(props)
  const {
    children,
    topRightIcon,
    bottomLeftIcon,
    onDeleteClick,
    onEditClick,
    ...rest
  } = props

  return (
    <Box {...rest} className={classes.root}>
      {topRightIcon && (
        <img
          className={classes.topRightIcon}
          src={topRightIcon}
          alt="top-right"
          onClick={onDeleteClick ? onDeleteClick : null}
        />
      )}
      <img className={classes.image} src={WaveTop} alt="wave-top" />
      <Box className={classes.boxContent}>{children}</Box>
      <img className={classes.image} src={WaveBottom} alt="wave-bottom" />
      {bottomLeftIcon && (
        <img
          className={classes.bottomLeftIcon}
          src={bottomLeftIcon}
          alt="bottom-left"
          onClick={onEditClick ? onEditClick : null}
        />
      )}
    </Box>
  )
}

WavesBox.propTypes = {
  topRightIcon: PropTypes.node,
  bottomLeftIcon: PropTypes.node,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
}

export default WavesBox
