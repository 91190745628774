import { Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import { CircularProgress } from "@material-ui/core"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: props => (props.mt === 0 ? 0 : theme.spacing(props.mt || 2)),
    marginBottom: props => (props.mb ? theme.spacing(props.mb) : 0),
    marginRight: props => (props.mr ? theme.spacing(props.mr) : 0),
    marginLeft: props => (props.ml ? theme.spacing(props.ml) : 0),
    backgroundColor: props => props.backgroundcolor || "#CC0001",
    color: theme.palette.common.white,
    borderRadius: 3,
    width: "100%",
    fontSize: props =>
      props.fontsizemobile
        ? theme.typography.pxToRem(props.fontsizemobile)
        : theme.typography.pxToRem(16),
    fontWeight: 400,
    textTransform: "none",
    height: props => props.height || 40,
    [theme.breakpoints.up("sm")]: {
      fontSize: props =>
        props.fontSize
          ? theme.typography.pxToRem(props.fontSize)
          : theme.typography.pxToRem(18),
    },
    "&:hover": {
      backgroundColor: props => props.backgroundcolor || "#CC0001",
      "&.Mui-disabled": {
        backgroundColor: props => props.backgroundcolor || "#CC0001",
      },
    },
    "& .MuiButton-startIcon": {
      alignItems: "center",
      marginLeft: props => theme.spacing(-(props.iconOptions?.marginLeft || 3)),
      marginRight: props => theme.spacing(props.iconOptions?.marginRight || 3),
    },
    "& .MuiButton-endIcon": {
      marginLeft: theme.spacing(3),
    },
    "&.Mui-disabled": {
      color: theme.palette.common.white,
      opacity: 0.5,
    },
  },
}))

const FButton = props => {
  const classes = useStyles(props)
  const { className, icon, iconOptions, disabled, title, ...rest } = props

  return (
    <Button
      {...rest}
      startIcon={
        !disabled &&
        icon && <img width={iconOptions?.size || 20} src={icon} alt={title} />
      }
      className={clsx(classes.root, className)}
      type="submit"
      disabled={disabled}
    >
      {disabled ? <CircularProgress color="inherit" size={20} /> : title}
    </Button>
  )
}

export default FButton
