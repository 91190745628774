import PropTypes from "prop-types"
import {
  Box,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
  Fade,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useRouteMatch } from "react-router-dom"
import HeadLineBg from "../assets/images/desktop/headline_bg.png"
import FormBgDesktop from "../assets/images/desktop/form_bg.png"

const useStyles = makeStyles(theme => ({
  pageLayout: {
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundSize: "contain",
    [theme.breakpoints.up("md")]: {
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
    [theme.breakpoints.between(1441, "xl")]: {
      height: "calc(100vh - 66px)",
      minHeight: 800,
      alignItems: "flex-start",
    },
  },
  headLine: {
    flexGrow: 1,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      alignSelf: "flex-start",
      width: "auto",
    },
    "& .headline-container": {
      color: theme.palette.common.white,
      margin: "55px auto 26px auto",
      width: 350,
      [theme.breakpoints.down(400)]: {
        width: "90%",
      },
      [theme.breakpoints.down(360)]: {
        width: "95%",
      },
      [theme.breakpoints.up("sm")]: {
        margin: "40px 0 26px 0",
        minWidth: 630.75,
        width: "fit-content",
        minHeight: 116,
        backgroundImage: `url(${HeadLineBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "repeat-x",
        display: "flex",
        justifyContent: "flex-end",
      },
      "& .headline-text": {
        color: "#FFF",
        textAlign: "center",
        fontSize: 21,
        lineHeight: "normal",
        [theme.breakpoints.up("sm")]: {
          fontSize: 39,
          // maxWidth: 300.75,
          marginRight: 50,
          marginLeft: 50,
        },
      },
      "& .sub-headline-text": {
        marginTop: theme.spacing(0.5),
        textAlign: "center",
      },
    },
  },
  boxContent: {
    position: "relative",
    maxWidth: 370,
    width: 370,
    minHeight: 720,
    maxHeight: 743,
    overflowY: "scroll",
    scrollbarWidth: "none" /* Firefox */,
    "-ms-overflow-style": "none" /* IE and Edge */,
    "&::-webkit-scrollbar": {
      display: "none" /* Safari and Chrome */,
    },
    backgroundImage: `url(${FormBgDesktop})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    overflow: "hidden",
    flexDirection: "column",
    borderRadius: 20,
    [theme.breakpoints.down(400)]: {
      width: "90%",
    },
    [theme.breakpoints.down(360)]: {
      width: "95%",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "20px 30px 20px 0",
      minHeight: 760,
      maxHeight: 762,
    },
    "& > form": {
      padding: "35px 35px 10px 35px",
      [theme.breakpoints.down(400)]: {
        width: "90%",
      },
      [theme.breakpoints.down(360)]: {
        width: "95%",
      },
      [theme.breakpoints.up("sm")]: {
        marginTop: 60,
        padding: "35px 35px 10px 35px",
      },
      flexGrow: 1,
      marginTop: 15,
      width: 370,
      minWidth: 370,
    },
  },
}))

const Page = props => {
  const { children, headline, description, background } = props
  const classes = useStyles()
  const [t] = useTranslation("common")
  const theme = useTheme()
  const upMd = useMediaQuery(theme.breakpoints.up("md"))
  const upSm = useMediaQuery(theme.breakpoints.up("sm"))
  const match = useRouteMatch()

  return (
    <Fade in={match != null} timeout={700} unmountOnExit>
      <Box
        className={classes.pageLayout}
        display="flex"
        style={
          background && {
            backgroundImage: `url(${
              upMd ? background?.desktop : background?.mobile
            })`,
            backgroundPosition: "top center",
          }
        }
      >
        <Box className={classes.headLine}>
          <Box className="headline-container">
            <Typography className="headline-text">{t(headline)}</Typography>
            {!upSm && description && (
              <Typography
                align="right"
                color="inherit"
                className="sub-headline-text"
              >
                {t(description)}
              </Typography>
            )}
          </Box>
        </Box>
        <Box className={classes.boxContent} display="flex">
          {children}
        </Box>
      </Box>
    </Fade>
  )
}

Page.propTypes = {
  headline: PropTypes.string.isRequired,
}

export default Page
