import {useState} from 'react'
import { Box, Typography, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { getPromotionsFromSettings} from "../../../../common/orderHelpers"

const useStyles = makeStyles(theme => ({
  primaryText: {
    color: "#3B3B3B",
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  secondaryText: {
    color: "#756F86",
  },
}))

const PromoCode = props => {
  const classes = useStyles()
  const { code } = props
  const [t] = useTranslation("common")
  const [promos] = useState(() => getPromotionsFromSettings())
  const promo = promos?.find(promo => promo.code.toUpperCase() === code.toUpperCase())

  return (
    <Box>
      <Typography className={classes.primaryText}>
        {t("orderFlow.label.promo")}
      </Typography>
      <Typography className={classes.secondaryText}>{code}</Typography>
      <Typography className={classes.secondaryText}>
        {t('orderFlow.text.monthContractDescription', {contractMonths: promo?.contractMonths})}
      </Typography>
    </Box>
  )
}

export default PromoCode
