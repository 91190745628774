import { useEffect, useState } from "react"
import { apiGetInvoices } from "../../api/invoice"
import FPagination from "../../components/F.Pagination"
import {
  makeStyles,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core"
import { rowsPerPage } from "../../common/config"
import { useTranslation } from "react-i18next"
import InvoiceItem from "./components/InvoiceItem"
import MiddleBg from "../../assets/images/desktop/middle_bg.png"
import { useLocation, useHistory } from "react-router-dom"
import { invoiceState } from "../../common/config"
const queryString = require("query-string")

const useStyles = makeStyles(theme => ({
  memberInvoiceList: {
    backgroundImage: `url(${MiddleBg})`,
    backgroundRepeat: "repeat",
    backgroundPosition: "center",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  memberInvoiceListContent: {
    padding: "5px 12px 10px 12px",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  title: {
    marginTop: theme.spacing(4),
    color: "#6B6B6B",
    fontSize: 31,
    textAlign: "center",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
}))

const Invoices = () => {
  const classes = useStyles()
  const [t] = useTranslation("common")
  const history = useHistory()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const [page, setPage] = useState(0)
  const [invoices, setInvoices] = useState([])
  const [loading, setLoading] = useState(false)

  const handlePageChange = page => {
    history.push(`?page=${page + 1}`)
  }

  useEffect(() => {
    Number(queryParams.page) > 0 && setPage(+queryParams.page - 1)
  }, [history, queryParams])

  useEffect(
    () =>
      (async () => {
        try {
          setLoading(true)
          const res = await apiGetInvoices()
          const invoices = res.filter(i => i.state !== invoiceState.DRAFT)
          setInvoices(invoices)
          setLoading(false)
        } catch (err) {
          setLoading(false)
        }
      })(),
    [],
  )

  return (
    <Box className={classes.memberInvoiceList}>
      <Typography className={classes.title}>
        {t("global.label.invoices")}
      </Typography>
      <Box className={classes.memberInvoiceListContent}>
        {invoices.length ? (
          <Box flexGrow={1}>
            {invoices
              .slice(rowsPerPage * page, rowsPerPage * (page + 1))
              .map(invoice => (
                <InvoiceItem key={invoice.id} data={invoice} />
              ))}
          </Box>
        ) : null}
        {loading && (
          <Box display="flex" justifyContent="center">
            <CircularProgress size={24} style={{ color: "#CC0001" }} />
          </Box>
        )}
        {invoices.length && invoices.length > rowsPerPage ? (
          <FPagination
            page={page}
            setPage={handlePageChange}
            count={Math.ceil(invoices.length / rowsPerPage)}
          />
        ) : null}
      </Box>
    </Box>
  )
}
export default Invoices
