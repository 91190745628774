import {
  FormControlLabel,
  Box,
  Typography,
  makeStyles,
} from "@material-ui/core"
import FRadio from "../../../../components/F.Radio"
import PropTypes from "prop-types"

const useStyles = makeStyles(theme => ({
  root: {
    border: props =>
      props.checked
        ? `${theme.typography.pxToRem(2)} solid #CA2026`
        : `${theme.typography.pxToRem(2)} solid #F1F1F1`,
    borderRadius: theme.typography.pxToRem(19),
    marginRight: 0,
    marginLeft: 0,
    marginBottom: theme.spacing(1.5),
    padding: theme.spacing(1, 1, 1, 0),
  },
  boxContainer: {
    marginRight: theme.spacing(1),
  },
  title: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(0.5),
    },
  },
  description: {
    fontSize: theme.typography.pxToRem(12),
    color: "#6B6B6B",
  },
  image: {
    width: 41,
    height: 45,
  },
}))
const RadioCollect = props => {
  const classes = useStyles(props)
  const { value, title, description, icon } = props

  return (
    <FormControlLabel
      className={classes.root}
      control={<FRadio value={value} />}
      label={
        <Box display="flex" alignItems="center">
          <Box className={classes.boxContainer}>
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.description}>
              {description}
            </Typography>
          </Box>
          {icon && <img className={classes.image} src={icon} alt={title} />}
        </Box>
      }
    />
  )
}

RadioCollect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.node,
}

export default RadioCollect
