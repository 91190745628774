import { useState } from "react"
import { Typography, FormHelperText } from "@material-ui/core"
import FTextField from "../../../components/F.TextField"
import FButton from "../../../components/F.Button"
import IconRotateLeftDesktop from "../../../assets/images/desktop/rotate_left_circular_arrow.png"
import { makeStyles } from "@material-ui/styles"
import { Formik } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import { apiForgotPassword } from "../../../api/member"

const useStyles = makeStyles(theme => ({
  forgotPasswordForm: {
    "& .title": {
      fontSize: 25,
      fontWeight: 600,
      color: "#6B6B6B",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(0.5),
      [theme.breakpoints.up("sm")]: {
        marginTop: 0,
        marginBottom: theme.spacing(1),
      },
    },
    "& .text-field": {
      marginBottom: 10,
    },
    "& .alert-success": {
      backgroundColor: "#dbf6d3",
      border: "1px solid #aed4a5",
      color: "#569745",
      padding: theme.spacing(2),
      fontSize: 14,
      fontWeight: 400,
    },
    "& .alert-error": {
      color: "#CA2026",
      fontSize: 14,
    },
  },
}))

const ForgotPasswordForm = () => {
  const classes = useStyles()
  const [t] = useTranslation("common")
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .required(t("global.form.message.emailIsRequired"))
          .email(t("global.form.label.email.validation.invalid")),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await apiForgotPassword(values.email)
          setError(false)
          setSuccess(true)
        } catch (err) {
          setError(true)
          setSuccess(false)
          setSubmitting(false)
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit} className={classes.forgotPasswordForm}>
          <Typography className="title" align="center">
            {t("forgot.form.heading.forgotPassword")}
          </Typography>
          {success ? (
            <FormHelperText className="alert-success">
              {t(
                "resetPassword.message.pleaseCheckYourEmailAndResetYourPassword"
              )}
            </FormHelperText>
          ) : (
            <>
              <FTextField
                error={errors.email && touched.email && errors.email}
                className="text-field"
                label={t("global.text.email")}
                name="email"
                onChange={handleChange}
                placeholder={t("global.form.text.pleaseEnterYourEmailAddress")}
              />
              {error && (
                <FormHelperText className="alert-error">
                  {t(
                    "forgotPassword.message.userWithGivenEmailAddressNotFound"
                  )}
                </FormHelperText>
              )}
              <FButton
                title={t("forgot.form.button.label.resetPassword")}
                icon={IconRotateLeftDesktop}
                disabled={isSubmitting}
              />
            </>
          )}
        </form>
      )}
    </Formik>
  )
}

export default ForgotPasswordForm
