import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  withStyles,
  TableBody,
  alpha,
  Typography,
} from "@material-ui/core"
import PropTypes from "prop-types"
import { financial } from "../../../../common/helpers"
import { useTranslation } from "react-i18next"

const StyledTableCell = withStyles(theme => ({
  head: {
    padding: theme.spacing(2, 2, 2, 0),
    borderBottom: "none",
    color: "#3B3B3B",
    fontWeight: theme.typography.fontWeightSemiBold,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  body: {
    verticalAlign: "top",
    padding: theme.spacing(0.5, 0.5, 0.5, 0),
    paddingLeft: props => (props.highlight ? "4px" : 0),
    borderBottom: "none",
    color: "#3B3B3B",
    backgroundColor: props =>
      props.highlight ? alpha("#DBE2EA", 0.25) : "transparent",
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      padding: theme.spacing(1, 1, 1, 0),
    },
  },
}))(TableCell)

const OrderDetailTable = props => {
  const [t] = useTranslation("common")
  const { items } = props

  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell>{t("orderFlow.label.quantity")}</StyledTableCell>
          <StyledTableCell>{t("global.label.items")}</StyledTableCell>
          <StyledTableCell>{t("global.label.price")}</StyledTableCell>
          <StyledTableCell>{t("orderFlow.label.subTotal")}</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items?.map(item => (
          <TableRow key={item.id}>
            <StyledTableCell>{item.quantity}</StyledTableCell>
            <StyledTableCell>{item.title}</StyledTableCell>
            <StyledTableCell>
              <Typography
                style={
                  item.appliedCode
                    ? { textDecoration: "line-through" }
                    : { textDecoration: "none" }
                }
              >{`HK$${financial(item.price)}`}</Typography>
              {item.appliedCode && (
                <Typography>{`HK$${financial(
                  item.priceAfterAppliedCode
                )}`}</Typography>
              )}
            </StyledTableCell>
            <StyledTableCell highlight="true">{`HK$${financial(
              item.subtotal
            )}`}</StyledTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

OrderDetailTable.propTypes = {
  items: PropTypes.array.isRequired,
}

export default OrderDetailTable
