import { useState, useEffect } from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom"
import { useHistory } from "react-router-dom"
import Page from "../../layout/Page"
import getStepHelper, { buildOrderData } from "../../common/orderHelpers"
import { apiSaveOrder } from "../../api/order"
import { ROUTES, scheduleType } from "../../common/config"
import { useSelector, useDispatch } from "react-redux"
import {
  clearSelectedDeliverItems,
  clearSelectedCollectItems,
} from "../../store/features/schedule/scheduleSlice"

const STEPS = ["scheduleAddress", "summary"]

const getStepPath = (curStep, routeMatch) => {
  return `${routeMatch}/${curStep}`
}

const ScheduleFlow = props => {
  const { routes } = props
  const history = useHistory()
  const routeMatch = useRouteMatch()
  const dispatch = useDispatch()
  const { selectedDeliverItems, selectedCollectItems, scheduleAction } =
    useSelector(state => state.schedule)
  const [data, setData] = useState()
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    scheduleAction
      ? history.replace(getStepPath(STEPS[0], routeMatch.path))
      : history.goBack()
  }, [scheduleAction, routeMatch.path, history])

  const saveOrder = async data => {
    const { buildingName, roomFloor, boxCounts, ...newData } =
      buildOrderData(data)
    try {
      setSubmitting(true)
      scheduleAction === scheduleType.DELIVER_TO_ME
      ? await apiSaveOrder({
          ...newData,
          deliverItems: selectedDeliverItems,
        })
      : await apiSaveOrder({
          ...newData,
          collectItems: selectedCollectItems,
        })
      history.replace(`${ROUTES.VALET}/items-user`)
    } catch (err) {
      console.error(err)
    } finally {
      scheduleAction === scheduleType.DELIVER_TO_ME
        ? dispatch(clearSelectedDeliverItems())
        : dispatch(clearSelectedCollectItems())
      setSubmitting(false)
    }
  }

  const onStepComplete = ({ step, stepData }) => {
    const newData = { ...data, ...stepData }
    const newStep = getStepHelper(newData, { step, steps: STEPS })
    if (!newStep) {
      saveOrder(data)
    } else {
      setData(newData)
      history.push(getStepPath(newStep, routeMatch.path))
    }
  }

  const stepProps = {
    ...data,
    onComplete: onStepComplete,
    numberOfItems:
      scheduleAction === scheduleType.DELIVER_TO_ME
        ? selectedDeliverItems.length
        : selectedCollectItems.length,
    submitting,
  }

  return (
    <Switch>
      {routes?.map(({ path, Component, meta, exact }) => (
        <Route
          path={`${routeMatch.url}${path}`}
          key={`${routeMatch.url}${path}`}
          exact={exact}
        >
          <Page headline={meta?.headline} background={meta?.background}>
            <Component {...stepProps} />
          </Page>
        </Route>
      ))}
    </Switch>
  )
}

export default ScheduleFlow
