import { useEffect } from "react"
import SaveIcon from "../../assets/icons/save_file.png"
import FTexField from "../../components/F.TextField"
import FSelect from "../../components/F.Select"
import FButton from "../../components/F.Button"
import { useTranslation } from "react-i18next"
import {
  makeStyles,
  Typography,
  CircularProgress,
  Box,
} from "@material-ui/core"
import { Formik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { apiUpdateMemberProfile } from "../../api/member"
import * as Yup from "yup"
import { getAreaOptions, addressMappingRegion } from "../../common/helpers"
import { fetchMemberInfo } from "../../store/features/member/memberSlice"
import { useSnackbar } from "notistack"
import MiddleBg from "../../assets/images/desktop/middle_bg.png"
import FAutoComplete from "../../components/F.Autocomplete"
import { phoneRegExp } from "../../common/config"

const useStyles = makeStyles(theme => ({
  memberProfile: {
    backgroundImage: `url(${MiddleBg})`,
    backgroundPosition: "center",
    flexGrow: 1,
    "& form": {
      padding: "35px 35px 10px 35px",
      marginTop: 0,
      "& .text-field": {
        marginBottom: theme.spacing(2),
      },
      "& .textarea": {
        marginTop: theme.spacing(2),
        borderRadius: 5,
        border: "1px solid #DBE2EA",
        width: "100%",
        resize: "none",
      },
    },
  },
  title: {
    marginTop: theme.spacing(4),
    color: "#6B6B6B",
    fontSize: 31,
    textAlign: "center",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
  areaFormControl: {
    marginBottom: theme.spacing(3),
  },
  inputLabel: {
    color: "#000000",
    fontSize: 14,
    transform: "translate(0, 1.5px) scale(1)",
    "& .MuiFormLabel-asterisk": {
      color: "#CA2026",
    },
    "& + .MuiInput-formControl": {
      marginTop: theme.spacing(3),
    },
  },
  areaSelect: {
    backgroundColor: "#FFF",
    borderRadius: 5,
    border: "1px solid #DBE2EA",
    paddingLeft: 10,
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: 14,
    "& .MuiSelect-select:focus": {
      backgroundColor: "#FFF",
    },
  },
  areaSelectItem: {
    fontSize: 14,
  },
  areaError: {
    color: "#CA2026",
  },
}))

const Profile = () => {
  const classes = useStyles()
  const [t] = useTranslation("common")
  const dispatch = useDispatch()
  const { user, fetchMemberDataLoading } = useSelector(state => state.member)
  const settings = useSelector(state => state.settings)
  const { lng } = useSelector(state => state.lng)
  const { enqueueSnackbar } = useSnackbar()
  const areaOptions = getAreaOptions(
    settings?.areas,
    lng,
    t("global.label.surcharge"),
  )

  useEffect(() => {
    dispatch(fetchMemberInfo())
  }, [dispatch])

  return (
    <div className={classes.memberProfile}>
      <Typography className={classes.title}>
        {t("profile.header.label.editYourProfile")}
      </Typography>
      <Formik
        enableReinitialize
        initialValues={{
          firstName: user?.firstName || "",
          lastName: user?.lastName || "",
          phone: user?.phone || "",
          roomFloor: user?.customFields?.address_line1 || "",
          buildingName: user?.customFields?.address_line2 || "",
          area: user?.area || "",
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required(
            t("global.form.message.firstNameIsRequired"),
          ),
          lastName: Yup.string().required(
            t("global.form.message.lastNameIsRequired"),
          ),
          phone: Yup.string()
            .required(t("global.form.text.pleaseEnterYourMobileNumber"))
            .matches(
              phoneRegExp,
              t("global.form.message.mobileNumberIsInvalid"),
            ),
          roomFloor: Yup.string().required(
            t("global.form.message.addressLine1IsRequired"),
          ),
          buildingName: Yup.string().required(
            t("global.form.message.addressLine2IsRequired"),
          ),
          area: Yup.string().required(t("global.form.region.label")),
        })}
        onSubmit={async (values, { setSubmiting }) => {
          try {
            const { roomFloor, buildingName, ...rest } = values
            await apiUpdateMemberProfile({
              ...rest,
              address: `${roomFloor}, ${buildingName}`,
              customFields: {
                address_line1: roomFloor,
                address_line2: buildingName,
              },
            })
            enqueueSnackbar(t("global.label.save.success"), {
              variant: "success",
            })
            dispatch(fetchMemberInfo())
          } finally {
            setSubmiting(false)
          }
        }}>
        {({
          errors,
          touched,
          handleSubmit,
          handleChange,
          isSubmitting,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            {fetchMemberDataLoading ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress size={24} style={{ color: "#CC0001" }} />
              </Box>
            ) : (
              <>
                <FTexField
                  className="text-field"
                  label={t("global.form.label.firstName")}
                  important={true}
                  placeholder={t("global.form.text.pleaseEnterYourFirstName")}
                  name="firstName"
                  onChange={handleChange}
                  error={
                    errors.firstName && touched.firstName && errors.firstName
                  }
                  value={values.firstName}
                />
                <FTexField
                  className="text-field"
                  label={t("global.form.label.lastName")}
                  important={true}
                  placeholder={t("global.form.text.pleaseEnterYourLastName")}
                  name="lastName"
                  onChange={handleChange}
                  error={errors.lastName && touched.lastName && errors.lastName}
                  value={values.lastName}
                />
                <FTexField
                  label={t("global.label.mobile")}
                  className="text-field"
                  type="number"
                  placeholder={t(
                    "orderFlow.contact.label.toConfirmDetailsAndTextYou",
                  )}
                  name="phone"
                  important={true}
                  onChange={handleChange}
                  value={values.phone}
                  error={errors.phone && touched.phone && errors.phone}
                />
                <FTexField
                  label={t("global.form.label.addressLine1")}
                  className="text-field"
                  multiline
                  name="roomFloor"
                  important={true}
                  onChange={handleChange}
                  value={values.roomFloor}
                  error={
                    errors.roomFloor && touched.roomFloor && errors.roomFloor
                  }
                />
                <FAutoComplete
                  autoComplete="off"
                  important={true}
                  label={t("global.form.label.addressLine2")}
                  noOptionsText={t("global.form.message.addressCantBeFound")}
                  value={values.buildingName}
                  error={
                    errors.buildingName &&
                    touched.buildingName &&
                    errors.buildingName
                  }
                  name="buildingName"
                  onChange={handleChange}
                  onSelected={(region, addressLine) => {
                    setFieldValue("buildingName", addressLine)
                    const area = addressMappingRegion(
                      settings?.areas,
                      region,
                      lng,
                    )
                    area && setFieldValue("area", area.id)
                  }}
                />
                <FSelect
                  className="text-field"
                  label={t("global.label.region")}
                  defaultOption={{
                    title: t("global.form.select.label.selectRegion"),
                    value: "",
                  }}
                  name="area"
                  options={areaOptions}
                  onChange={handleChange}
                  value={values.area}
                  error={errors.area && touched.area && errors.area}
                />
                <FButton
                  title={t("global.form.label.saveChanges")}
                  icon={SaveIcon}
                  disabled={isSubmitting}
                />
              </>
            )}
          </form>
        )}
      </Formik>
    </div>
  )
}
export default Profile
