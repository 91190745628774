import axios from "axios"
import { store } from "../store"
import { removeLocalStorage } from "../common/helpers"
import { ROUTES } from "./config"

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
}

const request = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  withCredentials: false,
  headers,
})

request.interceptors.request.use(
  config => {
    const { auth } = store.getState()
    if (auth?.accessToken) {
      config.headers.Authorization = `Bearer ${auth?.accessToken}`
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)
request.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response && error.response.data) {
      if (
        error.response.data.error?.type === "Unauthorized" &&
        window.location.pathname !== ROUTES.LOGIN
      ) {
        removeLocalStorage("accessToken")
        window.location.reload()
      }
      return Promise.reject(error.response.data)
    }
    return Promise.reject(error.response)
  }
)

export default request
