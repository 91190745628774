import request from "../common/request"

export const apiSaveOrder = async data => {
  try {
    const res = await request.post("/v1/valet/orders", data)
    return res.data
  } catch (err) {
    throw err
  }
}

export const apiCancelOrder = async id => {
  try {
    const res = await request.put(`/v1/valet/orders/${id}/cancel`)
    return res.data
  } catch (err) {
    throw err
  }
}

export const apiGetOrders = async () => {
  try {
    const res = await request.get(`/v1/valet/orders?include=customFields`)
    return res.data
  } catch (err) {
    console.log(err)
  }
}

export const apiEditOrder = async (orderId, data) => {
  try {
    const res = await request.put(`/v1/valet/orders/${orderId}`, data)
    return res.data
  } catch (err) {
    throw err
  }
}
