import { useState } from "react"
import PropTypes from "prop-types"
import { Typography, FormHelperText, Box } from "@material-ui/core"
import FTextField from "../../../components/F.TextField"
import FButton from "../../../components/F.Button"
import IconLockDesktop from "../../../assets/images/desktop/lock.png"
import { makeStyles } from "@material-ui/styles"
import { Formik } from "formik"
import * as Yup from "yup"
import { orderSteps } from "../../../common/config"
import {
  mapSiteLngToBackendLng,
  mapBackendLngToSiteLang,
} from "../../../common/helpers"
import { useTranslation } from "react-i18next"
import { apiLogin } from "../../../api/member"
import { useDispatch, useSelector } from "react-redux"
import { loggedIn } from "../../../store/features/auth/authSlice"
import { setUser } from "../../../store/features/member/memberSlice"
import { setAfterLoginPopup } from "../../../store/features/ui/uiSlice"
import { changeLng } from "../../../store/features/lng/lngSlice"

const useStyles = makeStyles(theme => ({
  loginForm: {
    "& .title": {
      fontSize: 25,
      fontWeight: 600,
      color: "#6B6B6B",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(0.5),
      [theme.breakpoints.up("sm")]: {
        marginTop: 0,
        marginBottom: theme.spacing(1),
      },
    },
    "& .text-field": {
      marginBottom: 10,
    },
    "& .helper-text": {
      color: "#CA2026",
      fontSize: 14,
    },
    "& .terms-text1, .terms-text2": {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: "normal",
    },
    "& .terms-text2": {
      color: "#000",
      textDecoration: "underline",
      display: "inline-block",
    },
  },
}))

const LoginForm = props => {
  const { onComplete } = props
  const classes = useStyles()
  const [t] = useTranslation("common")
  const [error, setError] = useState(false)
  const { lng } = useSelector(state => state.lng)
  const { customFields, termsUrl } = useSelector(state => state.settings)
  const dispatch = useDispatch()

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .required(t("global.form.message.emailIsRequired"))
          .email(t("global.form.label.email.validation.invalid")),
        password: Yup.string().required(
          t("global.form.message.passwordIsRequired")
        ),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const response = await apiLogin(values.email, values.password)
          if (response) {
            dispatch(loggedIn(response))
            dispatch(setUser(response.user))
            if (mapSiteLngToBackendLng(lng) !== response.user.language) {
              dispatch(
                changeLng(mapBackendLngToSiteLang(response.user.language))
              )
            }
            if (
              customFields?.landing_alert_message_box_enable?.toLowerCase() ===
              "on"
            ) {
              dispatch(setAfterLoginPopup(true))
            }
            onComplete && onComplete({ step: orderSteps.ACCOUNT })
          }
        } catch (err) {
          setError(true)
          setSubmitting(false)
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit} className={classes.loginForm}>
          <Typography className="title" align="center">
            {t("login.form.heading.login")}
          </Typography>
          <FTextField
            error={errors.email && touched.email && errors.email}
            className="text-field"
            label={t("global.text.email")}
            name="email"
            onChange={handleChange}
            placeholder={t("global.form.text.pleaseEnterYourEmailAddress")}
          />
          <FTextField
            error={errors.password && touched.password && errors.password}
            className="text-field"
            label={t("global.form.label.password")}
            name="password"
            type="password"
            onChange={handleChange}
            placeholder={t("global.form.label.pleaseEnterYourPassword")}
          />
          {error && (
            <FormHelperText className="helper-text">
              {t("login.form.input.emailPass.validation.invalid")}
            </FormHelperText>
          )}
          <FButton
            title={t("login.button.label.login")}
            icon={IconLockDesktop}
            disabled={isSubmitting}
          />
          <Box style={{ marginTop: "20px" }}>
            <Typography className="terms-text1" component="span">
              {t("login.form.text.byLoggingThisSiteYouAgreeToBeBoundByThe")}
            </Typography>
            &nbsp;
            <a
              rel="noreferrer"
              target="_blank"
              href={termsUrl ? termsUrl : "#"}
              className="terms-text2"
              component="span"
            >
              {t("global.label.termsAndConditions")}
            </a>
            &nbsp;
            <Typography className="terms-text1" component="span">
              {t("global.label.and").toLowerCase()}
            </Typography>
            &nbsp;
            <a
              rel="noreferrer"
              target="_blank"
              href={
                customFields?.privacy_policy
                  ? customFields?.privacy_policy
                  : "#"
              }
              className="terms-text2"
              component="span"
            >
              {t("global.label.privacyPolicy")}
            </a>
          </Box>
        </form>
      )}
    </Formik>
  )
}

LoginForm.propTypes = {
  onComplete: PropTypes.func,
}

export default LoginForm
