import { useState } from "react"
import Button from "@material-ui/core/Button"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Paper from "@material-ui/core/Paper"
import DownArrowIcon from "../assets/icons/down_arrow.png"
import LogoutIcon from "../assets/icons/logout.png"
import BillingIcon from "../assets/icons/billing.png"
import InvoiceIcon from "../assets/icons/invoice.png"
import PersonIcon from "../assets/icons/person.png"
import { makeStyles } from "@material-ui/core/styles"
import { useSelector, useDispatch } from "react-redux"
import { apiLogout } from "../api/member"
import { logout } from "../store/features/auth/authSlice"
import {
  Box,
  useTheme,
  useMediaQuery,
  Slide,
  CircularProgress,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import LanguageSwitcher from "../components/F.LanguageSwitcher"
import { useHistory } from "react-router-dom"
import { ROUTES } from "../common/config"
import { getSubString } from "../common/helpers"
import ReactGA from "react-ga4"

const useStyles = makeStyles(theme => ({
  memberMenu: {
    display: "flex",
    alignItems: "center",
    // cursor: "pointer",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      position: "relative",
      // minWidth: 185,
      justifiyContent: "center",
    },
  },
  menuListPaper: {
    background: "#CA2026 0% 0% no-repeat padding-box",
    opacity: "0.91",
    position: "absolute",
    top: 53,
    left: 0,
    boxShadow: "none",
    borderRadius: 0,
    [theme.breakpoints.down("xs")]: {
      right: 0,
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  menuList: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    marginLeft: "-50%",
    transform: "translateX(50%)",
    [theme.breakpoints.up("sm")]: {
      marginLeft: 0,
      transform: "none",
      justifiyContent: "flex-start",
    },
  },
  memuItem: {
    color: theme.palette.common.white,
    textTransform: "initial",
    padding: theme.spacing(1, 2),
    justifyContent: "flex-start",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
    "& .MuiButton-startIcon": {
      marginLeft: 0,
      marginRight: 38,
      alignItems: "center",
      [theme.breakpoints.up("sm")]: {
        marginRight: 22,
      },
    },
    "& .MuiButton-endIcon": {
      marginLeft: 38,
      // marginRight: 38,
      alignItems: "center",
      [theme.breakpoints.up("sm")]: {
        marginLeft: 22,
      },
    },
    "&.Mui-disabled": {
      color: theme.palette.common.white,
    },
  },
  btnMember: {
    padding: "6px 0",
    textTransform: "initial",
    minWidth: "auto",
    fontSize: 10,
    fontWeight: 400,
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
    "& .MuiButton-label": {
      alignItems: "flex-end",
      flexDirection: "column",
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        justifyContent: "flex-end",
      },
      "& .MuiButton-startIcon": {
        marginLeft: 0,
        marginRight: 0,
        alignSelf: "center",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
          alignSelf: "initial",
          marginLeft: theme.spacing(-0.5),
          marginRight: theme.spacing(2),
        },
      },
    },
  },
  downArrow: {
    width: 8,
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
    },
  },
}))

const menuItems = [
  {
    title: "global.label.profile",
    startIcon: PersonIcon,
    route: ROUTES.MEMBER_PROFILE,
    ga: "Profile",
  },
  {
    title: "global.label.invoices",
    startIcon: InvoiceIcon,
    route: ROUTES.MEMBER_INVOICES,
    ga: "Invoices",
  },
  {
    title: "global.label.billing",
    startIcon: BillingIcon,
    route: `${ROUTES.VALET}/billing`,
    ga: "Billing",
  },
]

const MemberMenu = () => {
  const classes = useStyles()
  const theme = useTheme()
  const upSm = useMediaQuery(theme.breakpoints.up("sm"))
  const [open, setOpen] = useState(false)
  const [logoutLoading, setLogoutLoading] = useState(false)
  const { user } = useSelector(state => state.member)
  const dispatch = useDispatch()
  const history = useHistory()
  const [t] = useTranslation("common")

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    setOpen(false)
  }

  const handleMenuItemClick = menuItem => {
    history.push(menuItem.route)
    ReactGA.ga("send", {
      hitType: "pageview",
      page: `${menuItem.route}`,
      title: `${menuItem.ga}`,
    })
    setOpen(false)
  }

  const handleLogout = async event => {
    try {
      setLogoutLoading(true)
      await apiLogout()
      dispatch(logout())
      setLogoutLoading(false)
      history.push(ROUTES.LOGIN)
    } catch (err) {
      setLogoutLoading(false)
    }
    handleClose(event)
  }

  return (
    <div className={classes.memberMenu}>
      <Box onClick={handleToggle}>
        <Button
          className={classes.btnMember}
          color="inherit"
          startIcon={
            <img src={PersonIcon} width={upSm ? 28 : 24} alt="person-icon" />
          }>
          {user?.name && getSubString(user?.name, 15)}
        </Button>
        <img
          className={classes.downArrow}
          src={DownArrowIcon}
          alt="down-arrow"
        />
      </Box>
      <Slide direction="left" in={open} timeout={350} unmountOnExit>
        <Paper className={classes.menuListPaper}>
          <ClickAwayListener onClickAway={handleClose}>
            <Box className={classes.menuList}>
              {menuItems.map((menuItem, index) => (
                <Button
                  key={`${index}${menuItem.title}`}
                  className={classes.memuItem}
                  color="inherit"
                  fullWidth
                  startIcon={
                    <img
                      src={menuItem.startIcon}
                      width={18}
                      alt={t(menuItem.title)}
                    />
                  }
                  onClick={() => handleMenuItemClick(menuItem)}>
                  {t(menuItem.title)}
                </Button>
              ))}
              <Button
                className={classes.memuItem}
                color="inherit"
                disabled={logoutLoading}
                fullWidth
                startIcon={
                  <img
                    src={LogoutIcon}
                    width={18}
                    alt={t("global.label.logout")}
                  />
                }
                onClick={handleLogout}
                endIcon={
                  logoutLoading && (
                    <CircularProgress color="inherit" size={16} thickness={2} />
                  )
                }>
                {t("global.label.logout")}
              </Button>
              <LanguageSwitcher />
            </Box>
          </ClickAwayListener>
        </Paper>
      </Slide>
    </div>
  )
}

export default MemberMenu
