import AddIconDesktop from "../../../assets/images/desktop/add.png"
import { Box, Typography, makeStyles } from "@material-ui/core"
import FButton from "../../../components/F.Button"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(theme => ({
  typography1: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.black,
  },
  typography2: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#6B6B6B",
    margin: theme.spacing(2, 0, 2, 0),
  },
  typography3: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightSemiBold,
    color: "#3B3B3B",
  },
}))

const AddCard = props => {
  const { setOpenModal } = props
  const classes = useStyles()
  const [t] = useTranslation("common")

  return (
    <Box>
      <Typography className={classes.typography1}>
        {t("billing.form.text.pleaseAddYourCardToYourAccount")}
      </Typography>
      <Typography className={classes.typography2}>
        {t("global.form.text.youWontBeChargeUntilYourItemsInStorage")}
      </Typography>
      <Typography className={classes.typography3}>
        {t("billing.form.text.pleaseAddYourCreditCardOrDebitCard")}
      </Typography>
      <FButton
        onClick={() => setOpenModal(true)}
        title={t("billing.form.button.addANewCard")}
        icon={AddIconDesktop}
        iconOptions={{
          size: 24,
          marginLeft: 2,
          marginRight: 2,
        }}
        mt={6}
        mb={6}
        height={38}
      />
    </Box>
  )
}

AddCard.propTypes = {
  setOpenModal: PropTypes.func.isRequired,
}

export default AddCard
