import { useState, useEffect, useMemo } from "react"
import {
  makeStyles,
  Box,
  InputAdornment,
  alpha,
  RadioGroup,
  Slide,
  FormHelperText,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"
import TimeIcon from "../../../assets/images/desktop/time.png"
import DeliveryIcon from "../../../assets/icons/fast_delivery.png"
import StairsIcon from "../../../assets/icons/stairs.png"
import EventIcon from "../../../assets/images/desktop/event.png"
import BoxesIcon from "../../../assets/images/desktop/boxes.png"
import FStepper from "../../../components/F.Stepper"
import FButton from "../../../components/F.Button"
import SaveIcon from "../../../assets/icons/save_file.png"
import FSelect from "../../../components/F.Select"
import RadioCollect from "./components/RadioCollect"
import FSwitch from "../../../components/F.Switch"
import FBoxInfo from "../../../components/F.BoxInfo"
import { apiGetTimeSlots } from "../../../api/member"
import { useSelector, useDispatch } from "react-redux"
import { compareAsc } from "date-fns"
import { collectType, orderSteps } from "../../../common/config"
import { stairCarryImages } from "../../../data/dialogImages"
import { addDeliveryInfo } from "../../../store/features/orderBoxes/orderBoxesSlice"
import {
  formatTimeslotOption,
  parseyyyyMMdd2Date,
  formatDate2yyyy_MM_dd,
  mapSiteLngToBackendLng,
  isDateInRange,
  getOnlyDate,
} from "../../../common/helpers"
import {
  getInsurancesFromSettings,
  getFirstInsurancesOption,
} from "../../../common/orderHelpers"
import { findIndex, find, isEmpty } from "lodash"
import { useTranslation } from "react-i18next"
import FDatePicker from "../../../components/F.DatePicker"
import FDialogImage from "../../../components/F.DialogImage"
import { useSnackbar } from "notistack"
import { last } from "lodash"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 3, 2, 3),
    backgroundColor: alpha(theme.palette.common.white, 0.5),
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  deliveryForm: {
    flexGrow: 1,
    position: "relative",
    padding: "0 !important",
    width: "auto !important",
    minWidth: "auto !important",
    marginTop: `${theme.spacing(4)}px !important`,
    "& .text-field": {
      marginBottom: theme.spacing(1.8),
    },
  },
  deliveryFormContent: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
    overflowY: "scroll",
    scrollbarWidth: "none" /* Firefox */,
    "-ms-overflow-style": "none" /* IE and Edge */,
    "&::-webkit-scrollbar": {
      display: "none" /* Safari and Chrome */,
    },
  },
  inputLabelBold: {
    color: "#3B3B3B",
    fontWeight: theme.typography.fontWeightBold,
  },
  selectDatePicker: {
    border: "1px solid #0000000D",
    boxShadow: "0 2px 3px #0000000D",
  },
  boxContainer: {
    border: "1px solid #DBE2EA",
    borderRadius: 8,
    padding: theme.spacing(2, 2, 2, 1),
    marginBottom: theme.spacing(2),
  },
  alertError: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
  },
}))

const OrderTime = props => {
  const {
    // boxes,
    appliedPromo,
    settings,
    deliveryTime,
    submitting,
    error,
    onComplete,
    gotoStep,
  } = props
  const classes = useStyles()
  const theme = useTheme()
  const upSm = useMediaQuery(theme.breakpoints.up("sm"))
  const [t] = useTranslation("common")
  const { lng } = useSelector(state => state.lng)
  const dispatch = useDispatch()
  const [openStairsFee, setOpenStairsFee] = useState(false)
  const [timeslots, setTimeslots] = useState([])
  const [deliveryDate, setDeliveryDate] = useState(null)
  const [deliveryTimeSlot, setDeliveryTimeSlot] = useState(null)
  const [collectDate, setCollectDate] = useState(null)
  const [collectTime, setCollectTime] = useState(null)
  const [collect, setCollect] = useState(collectType.IMMEDIATELY)
  const [covidexposure, setCovidexposure] = useState(false)
  const [staircarry, setStaircarry] = useState(false)
  const [exxtracarecover, setExxtracarecover] = useState(false)
  const [exxtracarecoverValue, setExxtracarecoverValue] = useState(() =>
    getFirstInsurancesOption(),
  )
  const { enqueueSnackbar } = useSnackbar()

  const insurances = useMemo(
    () =>
      getInsurancesFromSettings().map(item => ({
        id: item.amount,
        title: item.title[mapSiteLngToBackendLng(lng)],
        value: item.amount,
      })),
    [lng],
  )

  useEffect(() => {
    const fetchTimeSlots = async () => {
      try {
        const res = await apiGetTimeSlots()
        setTimeslots(res)
        if (isEmpty(deliveryTime)) {
          setDeliveryDate(res[0])
          setDeliveryTimeSlot(res[0].times[0])
          setCollectDate(res[1])
          setCollectTime(res[1].times[0])
        } else {
          const getTimes = (date, timeslotDate, timeslots) => {
            const compared = compareAsc(
              parseyyyyMMdd2Date(date),
              parseyyyyMMdd2Date(timeslotDate),
            )
            if (compared >= 1) {
              const dateInTimeslots = find(timeslots, { date })
              return dateInTimeslots.times
            }
            return null
          }
          // edit mode
          // delivery date
          if (!deliveryTime.date.times) {
            const times = getTimes(deliveryTime.date.date, res[0].date, res)
            times
              ? setDeliveryDate({
                  date: deliveryTime.date.date,
                  times,
                })
              : setDeliveryDate({
                  date: deliveryTime.date.date,
                  times: [deliveryTime.timeslot],
                })
          } else {
            setDeliveryDate(deliveryTime.date)
          }
          // edit mode
          // collect date
          if (!deliveryTime.collectDate.times) {
            if (deliveryTime.collect === collectType.IMMEDIATELY) {
              // check if current delivery date greater than timeslot first date
              const compared = compareAsc(
                parseyyyyMMdd2Date(deliveryTime.date.date),
                parseyyyyMMdd2Date(res[0].date),
              )
              if (compared > -1) {
                const startDateIndex = findIndex(
                  res,
                  timeslot => timeslot.date >= deliveryTime.date.date,
                )
                if (startDateIndex >= 0) {
                  setCollectDate(res[startDateIndex + 1])
                  setCollectTime(res[startDateIndex + 1].times[0])
                }
              } else {
                setCollectDate(res[1])
                setCollectTime(res[1].times[0])
              }
            } else {
              const times = getTimes(
                deliveryTime.collectDate.date,
                res[0].date,
                res,
              )
              times
                ? setCollectDate({
                    date: deliveryTime.collectDate.date,
                    times,
                  })
                : setCollectDate({
                    date: deliveryTime.collectDate.date,
                    times: [deliveryTime.collectTimeslot],
                  })
              setCollectTime(deliveryTime.collectTimeslot)
            }
          } else {
            setCollectDate(deliveryTime.collectDate)
            setCollectTime(deliveryTime.collectTimeslot)
          }
          setDeliveryTimeSlot(deliveryTime.timeslot)
          setCollect(deliveryTime.collect)
          setCovidexposure(deliveryTime.covidexposure)
          setStaircarry(deliveryTime.staircarry)
          setExxtracarecover(deliveryTime.exxtracarecover)
          setExxtracarecoverValue(deliveryTime.exxtracarecoverValue)
        }
      } catch (err) {
        console.error(err)
      }
    }

    timeslots.length <= 0 && fetchTimeSlots()
  }, [timeslots, deliveryTime])

  const getDeliveryTimeslots = () => {
    const availableTimeslots = timeslots.slice(0, -7)
    return availableTimeslots.map(timeslot => parseyyyyMMdd2Date(timeslot.date))
  }

  const handleDeliveryDateChange = date => {
    const seletedDate = find(timeslots, { date: formatDate2yyyy_MM_dd(date) })
    if (seletedDate) {
      setDeliveryDate(seletedDate)
      setDeliveryTimeSlot(seletedDate.times[0])
    }
    if (compareAsc(date, parseyyyyMMdd2Date(collectDate?.date)) > -1) {
      let index = findIndex(timeslots, { date: formatDate2yyyy_MM_dd(date) })
      setCollectDate(timeslots[index + 1])
      setCollectTime(timeslots[index + 1].times[0])
    } else {
      const allowedCollectTimeslots = getCollectTimeslots(
        formatDate2yyyy_MM_dd(date),
      )
      const lastItem = last(allowedCollectTimeslots)
      if (
        lastItem &&
        compareAsc(
          parseyyyyMMdd2Date(collectDate?.date),
          parseyyyyMMdd2Date(lastItem.date),
        ) === 1
      ) {
        setCollectDate(lastItem)
        setCollectTime(lastItem.times[0])
      }
    }
  }

  const getCollectTimeslots = date => {
    if (!date) return []

    const startDateIndex = findIndex(
      timeslots,
      timeslot => timeslot.date >= date,
    )
    return timeslots.slice(
      startDateIndex + 1,
      startDateIndex + settings?.customFields?.max_day_collect_date,
    )
  }

  const handleCollectDateChange = date => {
    const seletedDate = find(timeslots, { date: formatDate2yyyy_MM_dd(date) })
    if (seletedDate) {
      setCollectDate(seletedDate)
      setCollectTime(seletedDate.times[0])
    }
  }

  const handleSubmit = () => {
    if (!deliveryDate) return

    if (appliedPromo?.code) {
      // check code is expired
      const inRange = isDateInRange(
        getOnlyDate(new Date()),
        parseyyyyMMdd2Date(appliedPromo?.validFrom),
        parseyyyyMMdd2Date(appliedPromo?.validTo),
      )
      if (!inRange) {
        enqueueSnackbar(t("orderFlow.message.theCodeEnteredIsExpired"), {
          variant: "error",
        })
        return
      }
    }

    dispatch(
      addDeliveryInfo({
        date: deliveryDate,
        timeslot: deliveryTimeSlot,
        collectDate: collectDate,
        collectTimeslot: collectTime,
        collect: collect,
        covidexposure: covidexposure,
        staircarry: staircarry,
        exxtracarecover: exxtracarecover,
        exxtracarecoverValue: exxtracarecoverValue,
      }),
    )
    onComplete && onComplete({ step: orderSteps.TIME })
  }

  return (
    <Box className={classes.root}>
      <FStepper gotoStep={gotoStep} activeStep={2} />
      <Box className={classes.deliveryForm}>
        <Box className={classes.deliveryFormContent}>
          <FDatePicker
            label={t("global.form.label.deliveryDate")}
            selected={deliveryDate && parseyyyyMMdd2Date(deliveryDate?.date)}
            onChange={handleDeliveryDateChange}
            includeDates={getDeliveryTimeslots()}
          />
          {deliveryDate && (
            <FSelect
              required={false}
              className="text-field"
              inputLabelAdditionalClass={classes.inputLabelBold}
              label={t("global.form.label.deliveryTime")}
              name="deliveryTime"
              value={deliveryTimeSlot}
              startAdornment={
                <InputAdornment position="start">
                  <img src={TimeIcon} width={24} alt="date" />
                </InputAdornment>
              }
              options={deliveryDate.times?.map(time => ({
                id: time,
                title: formatTimeslotOption(time),
                value: time,
              }))}
              onChange={e => setDeliveryTimeSlot(e.target.value)}
            />
          )}
          <RadioGroup
            name="collect"
            value={collect}
            onChange={e => setCollect(e.target.value)}>
            <RadioCollect
              title={t("orderFlow.form.label.collectImmediately")}
              description={t("orderFlow.form.text.driverCanWaitUpTo20Minutes")}
              icon={BoxesIcon}
              value={collectType.IMMEDIATELY}
              checked={collect === collectType.IMMEDIATELY}
            />
            <RadioCollect
              title={t("orderFlow.form.label.collectLater")}
              description={t("orderFlow.form.text.ifYouNeedTimeToPack")}
              icon={EventIcon}
              value={collectType.LATER}
              checked={collect === collectType.LATER}
            />
          </RadioGroup>
          <Slide
            in={collect === collectType.LATER}
            direction="left"
            timeout={350}
            unmountOnExit>
            <Box>
              <FDatePicker
                label={t("global.form.label.collectDate")}
                selected={collectDate && parseyyyyMMdd2Date(collectDate?.date)}
                onChange={handleCollectDateChange}
                includeDates={getCollectTimeslots(deliveryDate?.date).map(
                  timeslot => parseyyyyMMdd2Date(timeslot.date),
                )}
              />
              {collectDate && (
                <FSelect
                  required={false}
                  className="text-field"
                  inputLabelAdditionalClass={classes.inputLabelBold}
                  label={t("global.form.label.collectTime")}
                  name="collectTime"
                  startAdornment={
                    <InputAdornment position="start">
                      <img src={DeliveryIcon} width={24} alt="date" />
                    </InputAdornment>
                  }
                  options={collectDate.times?.map(time => ({
                    id: time,
                    title: formatTimeslotOption(time),
                    value: time,
                  }))}
                  onChange={e => setCollectTime(e.target.value)}
                  value={collectTime}
                />
              )}
            </Box>
          </Slide>
          {/* <FTexField
            onChange={e => {
              setPromoCode(e.target.value)
              setInternalError(false)
            }}
            className="text-field"
            name="promoCode"
            value={promoCode}
            label={t("orderFlow.form.label.gotAPromoOrReferralCode")}
          /> */}
          <Box className={classes.boxContainer}>
            <FSwitch
              name="covidexposure"
              checked={covidexposure}
              onChange={e => setCovidexposure(e.target.checked)}
              label={t("global.form.text.covidexposure")}
            />
            <FSwitch
              name="staircarry"
              checked={staircarry}
              onChange={e => setStaircarry(e.target.checked)}
              label={t("global.form.text.staircarry")}
            />
            <Box display="flex" justifyContent="flex-end">
              <FBoxInfo
                onClick={() => setOpenStairsFee(true)}
                text={t("global.label.staircarryFees")}
                icon={StairsIcon}
              />
              <FDialogImage
                setOpen={setOpenStairsFee}
                open={openStairsFee}
                imageSrc={
                  upSm
                    ? `${process.env.PUBLIC_URL}/images/${stairCarryImages[lng].desktop}`
                    : `${process.env.PUBLIC_URL}/images/${stairCarryImages[lng].mobile}`
                }
              />
            </Box>
          </Box>
          <Box className={classes.boxContainer}>
            <FSwitch
              checked={exxtracarecover}
              name="exxtracarecover"
              label={t("orderFlow.form.text.exXtraCareCover")}
              onChange={e => setExxtracarecover(e.target.checked)}
            />
            {exxtracarecover && (
              <FSelect
                name="exxtracarecoverValue"
                value={exxtracarecoverValue}
                required={false}
                className="text-field"
                options={insurances}
                onChange={e => setExxtracarecoverValue(e.target.value)}
              />
            )}
          </Box>
        </Box>
      </Box>
      {error && (
        <FormHelperText className={classes.alertError}>{error}</FormHelperText>
      )}
      <FButton
        disabled={submitting}
        title={t("global.form.button.label.submit")}
        iconOptions={{
          size: 24,
          marginLeft: 4,
          marginRight: 4,
        }}
        icon={SaveIcon}
        onClick={handleSubmit}
      />
    </Box>
  )
}

export default OrderTime
