import request from "../common/request"

export const apiGetItems = async () => {
  try {
    const res = await request.get("v1/items")
    return res.data
  } catch (err) {
    console.error(err)
  }
}

export const apiItemInfo = async (id, data) => {
  try {
    const res = await request.put(`v1/items/${id}`, data)
    return res.data
  } catch (err) {
    throw err
  }
}

export const apiUploadImage = async (id, data) => {
  try {
    const res = await request.post(`v1/items/${id}/images`, data)
    return res.data
  } catch (err) {
    throw err
  }
}
