import axios from "axios"

export const apiAddressLookup = async searchText => {
  try {
    const res = await axios.get(
      `https://www.als.ogcio.gov.hk/lookup?q=${searchText}`,
      {
        headers: {
          "Accept-Language": "en,zh-Hant",
        },
      }
    )
    return res.data
  } catch (err) {
    console.error(err)
  }
}
