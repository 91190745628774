import { useState } from "react"
import { Typography, FormHelperText } from "@material-ui/core"
import FTextField from "../../../components/F.TextField"
import FButton from "../../../components/F.Button"
import IconRotateLeftDesktop from "../../../assets/images/desktop/rotate_left_circular_arrow.png"
import { makeStyles } from "@material-ui/styles"
import { Formik } from "formik"
import * as Yup from "yup"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { apiResetPassword } from "../../../api/member"
import { valuesIn } from "lodash"
const queryString = require("query-string")

const useStyles = makeStyles(theme => ({
  resetPasswordForm: {
    "& .title": {
      fontSize: 25,
      fontWeight: 600,
      color: "#6B6B6B",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(0.5),
      [theme.breakpoints.up("sm")]: {
        marginTop: 0,
        marginBottom: theme.spacing(1),
      },
    },
    "& .text-field": {
      marginBottom: 10,
    },
    "& .alert-success": {
      backgroundColor: "#dbf6d3",
      border: "1px solid #aed4a5",
      color: "#569745",
      padding: theme.spacing(2),
      fontSize: 14,
      fontWeight: 400,
    },
    "& .alert-error": {
      color: "#CA2026",
      fontSize: 14,
    },
  },
}))

const ResetPasswordForm = () => {
  const classes = useStyles()
  const location = useLocation()
  const [t] = useTranslation("common")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)
  const parsed = queryString.parse(location.search)

  return (
    <Formik
      initialValues={{ email: "", password: "", confirmPassword: "" }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .required(t("global.form.message.emailIsRequired"))
          .email(t("global.form.label.email.validation.invalid")),
        password: Yup.string()
          .required(t("global.form.message.passwordIsRequired"))
          .min(7, t("resetPassword.form.message.passwordAtLeast7Char")),
        confirmPassword: Yup.string()
          .required(t("resetPassword.form.message.confirmPasswordIsRequired"))
          .when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref("password")],
              t("resetPassword.form.input.error.passwordMissmatch")
            ),
          }),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          if (parsed.token) {
            await apiResetPassword(parsed.token, values.email, values.password)
            setSuccess(true)
            setError("")
          } else {
            setError(t("global.message.invalidToken"))
          }
        } catch (err) {
          if (err.error) {
            const { errors } = err.error
            if (errors) {
              const errorMessage = valuesIn(errors)
                .map(e => e.message || e)
                .join("\n")
              errorMessage && setError(errorMessage)
            } else {
              setError(err.error.message)
            }
          } else {
            setError(t("global.message.serverError"))
          }
          setSuccess(false)
          setSubmitting(false)
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit} className={classes.resetPasswordForm}>
          <Typography className="title" align="center">
            {t("global.label.resetYourPassword")}
          </Typography>
          {success ? (
            <FormHelperText className="alert-success">
              {t("resetPassword.message.resetPasswordSuccess")}
            </FormHelperText>
          ) : (
            <>
              <FTextField
                error={errors.email && touched.email && errors.email}
                className="text-field"
                label={t("global.text.email")}
                name="email"
                onChange={handleChange}
                placeholder={t("global.form.text.pleaseEnterYourEmailAddress")}
              />
              <FTextField
                error={errors.password && touched.password && errors.password}
                className="text-field"
                label={t("global.form.label.password")}
                name="password"
                type="password"
                onChange={handleChange}
                placeholder={t("global.form.label.pleaseEnterYourPassword")}
              />
              <FTextField
                error={
                  errors.confirmPassword &&
                  touched.confirmPassword &&
                  errors.confirmPassword
                }
                className="text-field"
                label={t("resetPassword.form.input.label.confirmPassword")}
                name="confirmPassword"
                type="password"
                onChange={handleChange}
                placeholder={t(
                  "resetPassword.message.invalid.pleaseEnterConfirmPassword"
                )}
              />
              {error && (
                <FormHelperText className="alert-error">{error}</FormHelperText>
              )}
              <FButton
                title={t("resetPassword.form.button.label.resetPassword")}
                icon={IconRotateLeftDesktop}
                disabled={isSubmitting}
              />
            </>
          )}
        </form>
      )}
    </Formik>
  )
}

export default ResetPasswordForm
