import request from "../common/request"

export const apiGetInvoices = async () => {
  try {
    const res = await request.get("/v1/invoices")
    return res.data
  } catch (err) {
    throw err
  }
}

export const apiGetInvoiceById = async sid => {
  try {
    const res = await request.get(`/v1/invoices/${sid}`)
    return res.data
  } catch (err) {
    throw err
  }
}
