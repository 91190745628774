import { Box, makeStyles, Typography, alpha } from "@material-ui/core"
import FTexField from "../../../components/F.TextField"
import FButton from "../../../components/F.Button"
import FSelect from "../../../components/F.Select"
import { useTranslation } from "react-i18next"
import { Formik } from "formik"
import { ConnectedFocusError } from "focus-formik-error"
import { useSelector, useDispatch } from "react-redux"
import { getAreaOptions, addressMappingRegion } from "../../../common/helpers"
import * as Yup from "yup"
import FStepper from "../../../components/F.Stepper"
import { orderSteps, phoneRegExp } from "../../../common/config"
import { addAddress } from "../../../store/features/orderBoxes/orderBoxesSlice"
import FAutoComplete from "../../../components/F.Autocomplete"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 3, 2, 3),
    backgroundColor: alpha(theme.palette.common.white, 0.93),
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  addressForm: {
    padding: "0 !important",
    width: "auto !important",
    minWidth: "auto !important",
    marginTop: `${theme.spacing(4)}px !important`,
    "& .text-field": {
      marginBottom: theme.spacing(1.8),
    },
  },
  deliveryContactNote: {
    color: "#6B6B6B",
    fontSize: theme.typography.pxToRem(14),
  },
}))

const OrderAddress = props => {
  const classes = useStyles()
  const [t] = useTranslation("common")
  const { settings, deliveryAddress, onComplete, gotoStep } = props
  const { user } = useSelector(state => state.member)
  const { lng } = useSelector(state => state.lng)
  const dispatch = useDispatch()
  const areaOptions = getAreaOptions(
    settings?.areas,
    lng,
    t("global.label.surcharge")
  )

  return (
    <Box className={classes.root}>
      <FStepper gotoStep={gotoStep} activeStep={1} />
      <Formik
        enableReinitialize
        initialValues={{
          phone: deliveryAddress?.phone || user?.phone,
          roomFloor:
            deliveryAddress?.roomFloor ||
            user?.customFields?.address_line1 ||
            "",
          buildingName:
            deliveryAddress?.buildingName ||
            user?.customFields?.address_line2 ||
            "",
          area: deliveryAddress?.area || user?.area || "",
          instructions: deliveryAddress?.instructions || "",
          altPhone: deliveryAddress?.altPhone || "",
        }}
        validationSchema={Yup.object().shape({
          phone: Yup.string()
            .required(t("global.form.text.pleaseEnterYourMobileNumber"))
            .matches(
              phoneRegExp,
              t("global.form.message.mobileNumberIsInvalid")
            ),
          roomFloor: Yup.string().required(
            t("global.form.message.addressLine1IsRequired")
          ),
          buildingName: Yup.string().required(
            t("global.form.message.addressLine2IsRequired")
          ),
          area: Yup.string().required(t("global.form.region.label")),
        })}
        onSubmit={(values, { setSubmiting }) => {
          dispatch(addAddress(values))
          onComplete && onComplete({ step: orderSteps.ADDRESS })
        }}
      >
        {({
          errors,
          touched,
          handleSubmit,
          handleChange,
          isSubmitting,
          setFieldValue,
          values,
        }) => (
          <form onSubmit={handleSubmit} className={classes.addressForm}>
            <ConnectedFocusError />
            <FTexField
              label={t("global.label.mobile")}
              className="text-field"
              type="number"
              placeholder={t(
                "orderFlow.contact.label.toConfirmDetailsAndTextYou"
              )}
              name="phone"
              important={true}
              onChange={handleChange}
              value={values.phone}
              error={errors.phone && touched.phone && errors.phone}
            />
            <FTexField
              label={t("global.form.label.addressLine1")}
              className="text-field"
              multiline
              name="roomFloor"
              important={true}
              onChange={handleChange}
              value={values.roomFloor}
              error={errors.roomFloor && touched.roomFloor && errors.roomFloor}
            />
            <FAutoComplete
              autoComplete="off"
              important={true}
              label={t("global.form.label.addressLine2")}
              noOptionsText={t("global.form.message.addressCantBeFound")}
              value={values.buildingName}
              error={
                errors.buildingName &&
                touched.buildingName &&
                errors.buildingName
              }
              name="buildingName"
              onChange={handleChange}
              onSelected={(region, addressLine) => {
                setFieldValue("buildingName", addressLine)
                const area = addressMappingRegion(settings?.areas, region, lng)
                area && setFieldValue("area", area.id)
              }}
            />
            <FSelect
              className="text-field"
              label={t("global.label.region")}
              defaultOption={{
                title: t("global.form.select.label.selectRegion"),
                value: "",
              }}
              name="area"
              options={areaOptions}
              onChange={handleChange}
              value={values.area}
              error={errors.area && touched.area && errors.area}
            />
            <FTexField
              className="text-field"
              label={t("orderFlow.form.input.label.deliveryInstruction")}
              name="instructions"
              value={values.instructions}
              multiline
              maxRows={3}
              onChange={handleChange}
              placeholder={t(
                "orderFlow.form.input.label.deliveryInstructionPlaceholder"
              )}
            />
            <FTexField
              value={values.altPhone}
              className="text-field"
              label={t(
                "orderFlow.form.input.label.deliveryContactNameAndPhone"
              )}
              name="altPhone"
              onChange={handleChange}
            />
            <Typography className={classes.deliveryContactNote}>
              {t(
                "orderFlow.form.input.label.ifSomeoneElseWillReceiveTheDelivery"
              )}
            </Typography>
            <FButton title={t("global.form.button.label.next")} mt={1} />
          </form>
        )}
      </Formik>
    </Box>
  )
}

export default OrderAddress
