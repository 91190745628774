import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { Box, makeStyles, Typography, Button } from "@material-ui/core"
import { changeBoxes } from "../../../../store/features/orderBoxes/orderBoxesSlice"

const useStyles = makeStyles(theme => ({
  root: {
    padding: "0px 2px 0 2px",
  },
  control: {
    width: 62,
    height: 62,
    borderRadius: "50%",
    backgroundColor: theme.palette.common.white,
    color: "#656565",
    boxShadow: theme.shadows[2],
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(55),
    cursor: "pointer",
    "& .MuiButton-label": {
      height: 62,
      display: "inline-block",
      lineHeight: "52px",
    },
  },
  quantity: {
    fontSize: theme.typography.pxToRem(62),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#656565",
  },
}))

const QuantityControl = props => {
  const classes = useStyles()
  const { boxType } = props
  const { boxes } = useSelector(state => state.orderBoxes)
  const dispatch = useDispatch()

  const handleIncrease = () => {
    dispatch(changeBoxes({ boxType, quantity: 1 }))
  }

  const handleDecrease = () => {
    if (boxes[boxType] === 0) return
    dispatch(changeBoxes({ boxType, quantity: -1 }))
  }

  return (
    <Box
      className={classes.root}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Button onClick={handleDecrease} className={classes.control}>
        -
      </Button>
      <Typography className={classes.quantity}>{boxes[boxType]}</Typography>
      <Button onClick={handleIncrease} className={classes.control}>
        +
      </Button>
    </Box>
  )
}

QuantityControl.propTypes = {
  boxType: PropTypes.string.isRequired,
}

export default QuantityControl
