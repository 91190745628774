import { useEffect, useState } from "react"
import { useRouteMatch, Switch, Route, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import getStepHelper, {
  canGoToStep,
  buildOrderData,
} from "../../common/orderHelpers"
import {
  clearOrderData,
  orderFailed,
} from "../../store/features/orderBoxes/orderBoxesSlice"
import Page from "../../layout/Page"
import Account from "./account"
import { apiSaveOrder } from "../../api/order"
import { orderSteps, ROUTES } from "../../common/config"
import { store } from "../../store"

const STEPS = ["boxes", "address", "time", "account", "billing", "summary"]

const getStepPath = (curStep, routeMatch) => {
  return `${routeMatch}/${curStep}`
}

const OrderFlow = props => {
  const { routes } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const routeMatch = useRouteMatch()
  const orderData = useSelector(state => state.orderBoxes)
  const settings = useSelector(state => state.settings)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    history.replace(getStepPath(STEPS[0], routeMatch.path))
  }, [routeMatch.path, history])

  useEffect(() => {
    return () => {
      dispatch(clearOrderData())
    }
  }, [dispatch])

  const saveOrder = async orderData => {
    try {
      setSubmitting(true)
      const data = buildOrderData(orderData)
      await apiSaveOrder(data)
      history.push(`${ROUTES.VALET}/items-user`)
    } catch (err) {
      err.error && dispatch(orderFailed(err.error.message))
      history.goBack()
    } finally {
      setSubmitting(false)
    }
  }

  const onStepComplete = ({ step }) => {
    const { orderBoxes } = store.getState()
    const newStep = getStepHelper(orderBoxes, { step, steps: STEPS })
    if (!newStep) {
      // done and submit data
      saveOrder(orderBoxes)
    } else {
      // if step equal then replace that
      if (step === orderSteps.ACCOUNT) {
        history.replace(getStepPath(newStep, routeMatch.path))
      } else {
        history.push(getStepPath(newStep, routeMatch.path))
      }
    }
  }

  const gotoStep = step => {
    const { orderBoxes } = store.getState()
    const newStep = canGoToStep(orderBoxes, { step, steps: STEPS })
    if (!newStep) {
      history.push(getStepPath(step, routeMatch.path))
    }
  }

  const data = {
    ...orderData,
    settings: settings,
    onComplete: onStepComplete,
    gotoStep: gotoStep,
    submitting,
  }

  return (
    <Switch>
      {routes?.map(({ path, Component, meta, exact }) => (
        <Route
          path={`${routeMatch.path}${path}`}
          key={`${routeMatch.path}${path}`}
          exact={exact}>
          <Page
            headline={meta?.headline}
            description={meta?.description}
            background={meta?.background}>
            <Component {...data} />
          </Page>
        </Route>
      ))}
      <Route path={`${routeMatch.path}/account`} exact>
        <Account onComplete={onStepComplete} />
      </Route>
    </Switch>
  )
}

export default OrderFlow
