import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 16,
    marginRight: 16,
    [theme.breakpoints.down(400)]: {
      marginLeft: 8,
      marginRight: 8,
    },
  },
}))

const Arrow = props => {
  const classes = useStyles()
  const { icon } = props

  return <img className={classes.root} src={icon} width={14} alt="arrow" />
}

export default Arrow
