import { useState, useEffect } from "react"
import {
  Box,
  makeStyles,
  InputAdornment,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import TimeIcon from "../../../assets/images/desktop/time.png"
import OrderIcon from "../../../assets/icons/order.png"
import FTexField from "../../../components/F.TextField"
import FSelect from "../../../components/F.Select"
import FButton from "../../../components/F.Button"
import {
  formatTimeslotOption,
  getAreaOptions,
  parseyyyyMMdd2Date,
  addressMappingRegion,
  getTimeOptions,
  formatDate2yyyy_MM_dd,
} from "../../../common/helpers"
import { apiGetTimeSlots } from "../../../api/member"
import MemberBox from "../../../layout/MemberBox"
import { useSelector } from "react-redux"
import { find } from "lodash"
import { Formik } from "formik"
import { ConnectedFocusError } from "focus-formik-error"
import { collectType, orderSteps, phoneRegExp } from "../../../common/config"
import * as Yup from "yup"
import { isEmpty } from "lodash"
import FAutoComplete from "../../../components/F.Autocomplete"
import FSwitch from "../../../components/F.Switch"
import StairsIcon from "../../../assets/icons/stairs.png"
import FDialogImage from "../../../components/F.DialogImage"
import FBoxInfo from "../../../components/F.BoxInfo"
import { stairCarryImages } from "../../../data/dialogImages"
import FDatePicker from "../../../components/F.DatePicker"

const useStyles = makeStyles(theme => ({
  scheduleDelivery: {
    position: "relative",
    flexGrow: 1,
    "& .text-field": {
      marginBottom: theme.spacing(1.8),
    },
  },
  scheduleDeliveryContent: {
    padding: theme.spacing(2, 3, 2, 3),
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
    overflowY: "scroll",
    scrollbarWidth: "none" /* Firefox */,
    "-ms-overflow-style": "none" /* IE and Edge */,
    "&::-webkit-scrollbar": {
      display: "none" /* Safari and Chrome */,
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3, 3, 2, 3),
    },
  },
  inputLabelBold: {
    color: "#3B3B3B",
    fontWeight: theme.typography.fontWeightBold,
  },
  contactInfoWarningMessage: {
    color: "#3B3B3B",
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(1.8),
  },
  deliveryContactNote: {
    color: "#6B6B6B",
    fontSize: theme.typography.pxToRem(14),
  },
  boxContainer: {
    border: "1px solid #DBE2EA",
    borderRadius: 8,
    padding: theme.spacing(1, 2, 2, 1),
    marginTop: theme.spacing(2),
  },
}))

const ScheduleAddress = props => {
  const { onComplete, deliveryAddress, deliveryTime } = props
  const classes = useStyles()
  const [t] = useTranslation("common")
  const { user } = useSelector(state => state.member)
  const settings = useSelector(state => state.settings)
  const { lng } = useSelector(state => state.lng)
  const theme = useTheme()
  const upSm = useMediaQuery(theme.breakpoints.up("sm"))
  const [openStairsFee, setOpenStairsFee] = useState(false)
  const [timeslots, setTimeslots] = useState([])
  const [date, setDate] = useState()
  const [timeslot, setTimeslot] = useState("")
  const areaOptions = getAreaOptions(
    settings?.areas,
    lng,
    t("global.label.surcharge"),
  )

  useEffect(
    () =>
      (async () => {
        if (timeslots.length <= 0) {
          const res = await apiGetTimeSlots()
          setTimeslots(res)
          if (isEmpty(deliveryTime)) {
            setDate(res[0])
            setTimeslot(res[0].times[0])
          } else {
            if (!deliveryTime.date.times) {
              const times = getTimeOptions(
                deliveryTime.date.date,
                res[0].date,
                res,
              )
              times
                ? setDate({
                    date: deliveryTime.date.date,
                    times,
                  })
                : setDate({
                    date: deliveryTime.date.date,
                    times: [deliveryTime.timeslot],
                  })
            } else {
              setDate(deliveryTime.date)
            }
            setTimeslot(deliveryTime.timeslot)
          }
        }
      })(),
    [timeslots, deliveryTime],
  )

  const getDeliveryTimeslots = () => {
    const availableTimeslots = timeslots.slice(0, -7)
    return availableTimeslots.map(timeslot => parseyyyyMMdd2Date(timeslot.date))
  }

  const handleDeliveryDateChange = date => {
    const seletedDate = find(timeslots, { date: formatDate2yyyy_MM_dd(date) })
    if (seletedDate) {
      setDate(seletedDate)
      setTimeslot(seletedDate.times[0])
    }
  }

  return (
    <MemberBox title={t("scheduleDelivery.form.heading.scheduleYourDelivery")}>
      <Box className={classes.scheduleDelivery}>
        <Box className={classes.scheduleDeliveryContent}>
          <Formik
            enableReinitialize
            initialValues={{
              date: date && parseyyyyMMdd2Date(date.date),
              timeslot: timeslot,
              phone: deliveryAddress?.phone || user?.phone || "",
              roomFloor:
                deliveryAddress?.roomFloor ||
                user?.customFields?.address_line1 ||
                "",
              buildingName:
                deliveryAddress?.buildingName ||
                user?.customFields?.address_line2 ||
                "",
              area: deliveryAddress?.area || user?.area || "",
              instructions: deliveryAddress?.instructions || "",
              altPhone: deliveryAddress?.altPhone || "",
              covidexposure: deliveryTime?.covidexposure || false,
              staircarry: deliveryTime?.staircarry || false,
            }}
            validationSchema={Yup.object().shape({
              phone: Yup.string()
                .required(t("global.form.text.pleaseEnterYourMobileNumber"))
                .matches(
                  phoneRegExp,
                  t("global.form.message.mobileNumberIsInvalid"),
                ),
              area: Yup.string().required(t("global.form.region.label")),
              roomFloor: Yup.string().required(
                t("global.form.message.addressLine1IsRequired"),
              ),
              buildingName: Yup.string().required(
                t("global.form.message.addressLine2IsRequired"),
              ),
            })}
            onSubmit={async (values, { setSubmiting }) => {
              onComplete &&
                onComplete({
                  step: orderSteps.SCHEDULE_ADDRESS,
                  stepData: {
                    settings,
                    deliveryAddress: {
                      phone: values.phone,
                      roomFloor: values.roomFloor,
                      buildingName: values.buildingName,
                      area: values.area,
                      instructions: values.instructions,
                      altPhone: values.altPhone,
                    },
                    deliveryTime: {
                      collect: collectType.IMMEDIATELY,
                      date,
                      timeslot: values.timeslot,
                      covidexposure: values.covidexposure,
                      staircarry: values.staircarry,
                    },
                    mode: "deliver_to_me",
                  },
                })
            }}>
            {({
              errors,
              touched,
              handleSubmit,
              handleChange,
              isSubmitting,
              values,
              setFieldValue,
              setFieldError,
            }) => (
              <form onSubmit={handleSubmit}>
                <ConnectedFocusError />
                <FDatePicker
                  label={t("global.form.label.deliveryDate")}
                  selected={values.date}
                  onChange={handleDeliveryDateChange}
                  includeDates={getDeliveryTimeslots()}
                />
                {date && (
                  <FSelect
                    required={false}
                    className="text-field"
                    inputLabelAdditionalClass={classes.inputLabelBold}
                    label={t("global.form.label.deliveryTime")}
                    name="deliveryTime"
                    value={values.timeslot}
                    startAdornment={
                      <InputAdornment position="start">
                        <img src={TimeIcon} width={24} alt="date" />
                      </InputAdornment>
                    }
                    options={date?.times?.map(time => ({
                      id: time,
                      title: formatTimeslotOption(time),
                      value: time,
                    }))}
                    onChange={e => setTimeslot(e.target.value)}
                  />
                )}
                <Typography className={classes.contactInfoWarningMessage}>
                  {t(
                    "scheduleDelivery.label.confirmAddressAndContactInformation",
                  )}
                </Typography>
                <FTexField
                  label={t("global.label.mobile")}
                  className="text-field"
                  type="number"
                  placeholder={t(
                    "orderFlow.contact.label.toConfirmDetailsAndTextYou",
                  )}
                  name="phone"
                  important={true}
                  onChange={handleChange}
                  value={values.phone}
                  error={errors.phone && touched.phone && errors.phone}
                />
                <FTexField
                  label={t("global.form.label.addressLine1")}
                  className="text-field"
                  multiline
                  name="roomFloor"
                  important={true}
                  onChange={handleChange}
                  value={values.roomFloor}
                  error={
                    errors.roomFloor && touched.roomFloor && errors.roomFloor
                  }
                />
                <FAutoComplete
                  autoComplete="off"
                  important={true}
                  label={t("global.form.label.addressLine2")}
                  noOptionsText={t("global.form.message.addressCantBeFound")}
                  value={values.buildingName}
                  error={
                    errors.buildingName &&
                    touched.buildingName &&
                    errors.buildingName
                  }
                  name="buildingName"
                  onChange={handleChange}
                  onSelected={(region, addressLine) => {
                    setFieldValue("buildingName", addressLine)
                    const area = addressMappingRegion(
                      settings?.areas,
                      region,
                      lng,
                    )
                    area && setFieldValue("area", area.id)
                  }}
                />
                <FSelect
                  className="text-field"
                  label={t("global.label.region")}
                  defaultOption={{
                    title: t("global.form.select.label.selectRegion"),
                    value: "",
                  }}
                  name="area"
                  options={areaOptions}
                  onChange={handleChange}
                  value={values.area}
                  error={errors.area && touched.area && errors.area}
                />
                <FTexField
                  className="text-field"
                  label={t("orderFlow.form.input.label.deliveryInstruction")}
                  name="instructions"
                  value={values.instructions}
                  multiline
                  maxRows={3}
                  onChange={handleChange}
                  placeholder={t(
                    "orderFlow.form.input.label.deliveryInstructionPlaceholder",
                  )}
                />
                <FTexField
                  value={values.altPhone}
                  className="text-field"
                  label={t(
                    "orderFlow.form.input.label.deliveryContactNameAndPhone",
                  )}
                  name="altPhone"
                  onChange={handleChange}
                />
                <Typography className={classes.deliveryContactNote}>
                  {t(
                    "orderFlow.form.input.label.ifSomeoneElseWillReceiveTheDelivery",
                  )}
                </Typography>
                <Box className={classes.boxContainer}>
                  <FSwitch
                    name="covidexposure"
                    checked={values.covidexposure}
                    onChange={handleChange}
                    label={t("global.form.text.covidexposure")}
                  />
                  <FSwitch
                    name="staircarry"
                    checked={values.staircarry}
                    onChange={handleChange}
                    label={t("global.form.text.staircarry")}
                  />
                  <Box display="flex" justifyContent="flex-end">
                    <FBoxInfo
                      onClick={() => setOpenStairsFee(true)}
                      text={t("global.label.staircarryFees")}
                      icon={StairsIcon}
                    />
                    <FDialogImage
                      setOpen={setOpenStairsFee}
                      open={openStairsFee}
                      imageSrc={
                        upSm
                          ? `${process.env.PUBLIC_URL}/images/${stairCarryImages[lng].desktop}`
                          : `${process.env.PUBLIC_URL}/images/${stairCarryImages[lng].mobile}`
                      }
                    />
                  </Box>
                </Box>
                <FButton
                  disabled={isSubmitting}
                  type="submit"
                  icon={OrderIcon}
                  iconOptions={{ size: 24 }}
                  title={t("scheduleDelivery.form.button.deliverConfirm")}
                  backgroundcolor="#FFCC02"
                />
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </MemberBox>
  )
}

export default ScheduleAddress
