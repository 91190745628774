import ReactGA from "react-ga4"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getLocalStorage, setLocalStorage } from "../../../common/helpers"
import { apiGetSettings } from "../../../api/settings"

export const fetchSettings = createAsyncThunk(
  "settings/getSettings",
  async () => {
    return await apiGetSettings()
  },
)

const initialState = getLocalStorage("settings", {})

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  extraReducers: {
    [fetchSettings.fulfilled]: (state, action) => {
      //initialize ga
      if (action.payload.ga.id) {
        try {
          const id = JSON.parse(action.payload.ga.id)
          id &&
            ReactGA.initialize(`${id[process.env.REACT_APP_ENV]}`, {
              gaOptions: {
                cookieFlags: "SameSite=None; Secure",
                cookieDomain: "auto",
              },
            })
        } catch (err) {}
      }
      setLocalStorage("settings", action.payload)
      return (state = action.payload)
    },
  },
})

export default settingsSlice.reducer
