export const sitePrefix = "exxtra_"
export const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  SIGN_UP: "/sign-up",
  FORGET_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  VALET: "/valet",
  MEMBER_PROFILE: "/valet/profile",
  MEMBER_INVOICES: "/valet/invoices",
}
export const siteLanguages = [
  {
    name: "繁",
    code: "zh",
  },
  {
    name: "简",
    code: "cn",
  },
  {
    name: "ENG",
    code: "en",
  },
]

export const collectType = {
  IMMEDIATELY: "immediately",
  LATER: "later",
}

export const orderState = {
  STARTED: "started",
}

export const sortType = {
  ASC: "asc",
  DESC: "desc",
}

export const itemState = {
  USER: "user",
  STORAGE: "storage",
}

export const rowsPerPage = 4

export const orderSteps = {
  BOXES: "boxes",
  ADDRESS: "address",
  SCHEDULE_ADDRESS: "scheduleAddress",
  TIME: "time",
  ACCOUNT: "account",
  BILLING: "billing",
  SUMMARY: "summary",
}

export const invoiceState = {
  DRAFT: "draft",
}

export const scheduleType = {
  DELIVER_TO_ME: "deliver_to_me",
  ADD_TO_STORAGE: "add_to_storage",
}

export const itemType = {
  BIN: "bin",
  DOCUMENT: "doc",
}

export const phoneRegExp = /^[3,4,5,6,7,9]{1}[0-9]{7}$/
