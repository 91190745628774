import { Route, Redirect } from "react-router-dom"
import { useSelector } from "react-redux"
import { ROUTES } from "../common/config"

const ProtectedRoute = props => {
  const { accessToken, user } = useSelector(state => state.auth)
  // const { user, memberValetOrders } = useSelector(state => state.member)
  return !accessToken ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname:
          user?.valetOrders?.length > 0
            ? `${ROUTES.VALET}/items-user`
            : `${ROUTES.VALET}/order-boxes`,
        state: { from: props.location },
      }}
    />
  )
}

export default ProtectedRoute
