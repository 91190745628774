import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  selectedDeliverItems: [],
  selectedCollectItems: [],
  scheduleAction: null,
}

export const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    addDeliverItem: (state, action) => {
      const item = state.selectedDeliverItems.find(
        item => item === action.payload
      )
      item
        ? (state.selectedDeliverItems = state.selectedDeliverItems.filter(
            item => item !== action.payload
          ))
        : state.selectedDeliverItems.push(action.payload)
    },
    clearSelectedDeliverItems: state => {
      state.selectedDeliverItems = []
      state.scheduleAction = null
    },
    addCollectItem: (state, action) => {
      const item = state.selectedCollectItems.find(
        item => item === action.payload
      )
      item
        ? (state.selectedCollectItems = state.selectedCollectItems.filter(
            item => item !== action.payload
          ))
        : state.selectedCollectItems.push(action.payload)
    },
    clearSelectedCollectItems: state => {
      state.selectedCollectItems = []
      state.scheduleAction = null
    },
    setScheduleAction: (state, action) => {
      state.scheduleAction = action.payload
    },
  },
})

export const {
  addDeliverItem,
  clearSelectedDeliverItems,
  addCollectItem,
  clearSelectedCollectItems,
  setScheduleAction,
} = scheduleSlice.actions

export default scheduleSlice.reducer
