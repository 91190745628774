import { useMemo } from "react"
import {
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core"
import { useSelector } from "react-redux"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import PropTypes from "prop-types"
import CardElements from "./CardElements"
import { makeStyles } from "@material-ui/core"
import { mapSiteLngToStripe } from "../../../common/helpers"

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    "& h2": {
      color: "#6B6B6B",
      fontSize: theme.typography.pxToRem(25),
      fontWeight: theme.typography.fontWeightRegular,
      [theme.breakpoints.down(400)]: {
        fontSize: theme.typography.pxToRem(20),
      },
    },
  },
  dialogActions: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  closeButton: {
    color: "#CCCCCC",
    fontSize: theme.typography.pxToRem(16),
    width: 16,
    height: 16,
  },
}))

const AddCardDialog = props => {
  const classes = useStyles()
  const { openModal, setOpenModal, onComplete } = props
  const { user } = useSelector(state => state.member)
  const { lng } = useSelector(state => state.lng)
  const stripePromise = useMemo(
    () =>
      loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
        locale: mapSiteLngToStripe(lng),
      }),
    [lng]
  )

  return (
    <Box>
      <Dialog open={openModal} fullWidth maxWidth="sm">
        <DialogTitle className={classes.dialogTitle}>{user?.email}</DialogTitle>
        <DialogContent>
          <Elements stripe={stripePromise}>
            <CardElements onComplete={onComplete} setOpenModal={setOpenModal} />
          </Elements>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <IconButton
            onClick={() => setOpenModal(false)}
            className={classes.closeButton}
          >
            x
          </IconButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

AddCardDialog.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
}

export default AddCardDialog
