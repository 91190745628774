import { useState, useEffect } from "react"
import { Box, makeStyles, Divider } from "@material-ui/core"
import {
  fetchMemberValetOrders,
  fetchMemberItems,
} from "../../../store/features/member/memberSlice"
import { useSelector, useDispatch } from "react-redux"
import MemberBox from "../../../layout/MemberBox"
import FSort from "../../../components/F.Sort"
import { useTranslation } from "react-i18next"
import CargoIcon from "../../../assets/icons/cargo.png"
import {
  getOnlyDate,
  sortByDate,
  filterInDateRange,
} from "../../../common/helpers"
import { addMonths } from "date-fns"
import { isEditableOrCancellation } from "../../../common/orderHelpers"
import { apiCancelOrder } from "../../../api/order"
import {
  itemState,
  ROUTES,
  sortType,
  rowsPerPage,
  scheduleType,
} from "../../../common/config"
import { useHistory } from "react-router-dom"
import AddToStorageIcon from "../../../assets/icons/add_to_storage.png"
import NoBoxes from "../../../layout/NoBoxes"
import FBoxArrive from "../../../components/F.BoxArrive"
import FPagination from "../../../components/F.Pagination"
import FUpComingOrder from "../../../components/F.UpComingOrder"
import FDateRangePicker from "../../../components/F.DateRangPicker"
import FButton from "../../../components/F.Button"
import { useSnackbar } from "notistack"
import { setScheduleAction } from "../../../store/features/schedule/scheduleSlice"
import ReactGA from "react-ga4"

const useStyles = makeStyles(theme => ({
  mainContent: {
    position: "relative",
    color: "#707070",
    flexGrow: 1,
  },
  content: {
    padding: theme.spacing(1, 2, 2, 2),
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
    overflowY: "scroll",
    scrollbarWidth: "none" /* Firefox */,
    "-ms-overflow-style": "none" /* IE and Edge */,
    "&::-webkit-scrollbar": {
      display: "none" /* Safari and Chrome */,
    },
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: "#CFCFCF",
  },
  bottomBox: {
    padding: theme.spacing(1, 2, 0, 2),
  },
  btnDisabled: {
    pointerEvents: "none",
  },
  paginationContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
  },
}))

const ItemsWithYou = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [t] = useTranslation("common")
  const history = useHistory()
  const { memberValetOrders, memberItems } = useSelector(state => state.member)
  const { customFields } = useSelector(state => state.settings)
  const { selectedCollectItems } = useSelector(state => state.schedule)
  const [valetOrders, setValetOrders] = useState([])
  const withYou = memberItems?.filter(item => item.state === itemState.USER)
  const [sort, setSort] = useState(sortType.ASC)
  const [page, setPage] = useState(0)
  const today = getOnlyDate(new Date())
  const [startDate, setStartDate] = useState(today)
  const [endDate, setEndDate] = useState(addMonths(today, 1))
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    dispatch(fetchMemberValetOrders())
    dispatch(fetchMemberItems())
  }, [dispatch])

  useEffect(() => {
    if (memberValetOrders) {
      const sorted = sortByDate([...memberValetOrders], sort)
      const inDateRange = filterInDateRange(sorted, startDate, endDate)
      setValetOrders(inDateRange)
    }
  }, [memberValetOrders, startDate, endDate, sort])

  useEffect(() => {
    const numberOfPages = Math.ceil(valetOrders?.length / rowsPerPage)
    if (numberOfPages === page && numberOfPages > 0) {
      setPage(numberOfPages - 1)
    }
  }, [valetOrders, page])

  const handleCancelOrder = async order => {
    if (
      isEditableOrCancellation(order, customFields.minimum_day_of_cancellation)
    ) {
      if (window.confirm(t("global.message.cancleOrder"))) {
        try {
          await apiCancelOrder(order.id)
          dispatch(fetchMemberValetOrders())
        } catch (err) {
          err.error && enqueueSnackbar(err.error.message, { variant: "error" })
        }
      }
    } else {
      alert(
        `${t("global.message.cantEditOrderTitle")}\n\n${t(
          "global.message.cantEditOrderDescription",
        )}`,
      )
    }
  }

  const handleEditOrder = order => {
    order.boxCounts
      ? history.push(`${ROUTES.VALET}/order/${order.sid}`)
      : history.push(`${ROUTES.VALET}/reschedule/${order.sid}`)
  }

  const handleConfirm = (startDate, endDate) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const handleSort = sortDirection => {
    sortDirection !== sort && setSort(sortDirection)
  }

  const handleAddToStorage = () => {
    if (selectedCollectItems.length > 0) {
      dispatch(setScheduleAction(scheduleType.ADD_TO_STORAGE))
      history.push(`${ROUTES.VALET}/schedule`)
      ReactGA.ga("send", {
        hitType: "event",
        eventCategory: "Button",
        eventAction: "Add to storage",
        eventLabel: "Add to storage",
      })
    }
  }

  return (
    <MemberBox title={t("global.label.itemsWithMe")}>
      <Box alignItems="center" display="flex" pt={1} pl={2} pr={2} pb={2}>
        <FSort sortDirection={sort} handleSort={handleSort} />
        <FDateRangePicker
          start={startDate}
          end={endDate}
          onConfirm={handleConfirm}></FDateRangePicker>
      </Box>
      <Box className={classes.mainContent}>
        <Box className={classes.content}>
          {valetOrders
            .slice(rowsPerPage * page, rowsPerPage * (page + 1))
            .map(order => (
              <FUpComingOrder
                key={order.id}
                order={order}
                onDeleteClick={handleCancelOrder}
                onEditClick={handleEditOrder}
              />
            ))}
          {valetOrders.length > 0 && <Divider className={classes.divider} />}
          {withYou?.length ? (
            withYou?.map(item => <FBoxArrive key={item.id} item={item} />)
          ) : (
            <NoBoxes icon={CargoIcon} text={t("itemsWithYou.text.noItems")} />
          )}
        </Box>
      </Box>
      <Box className={classes.bottomBox}>
        <FButton
          className={selectedCollectItems.length > 0 ? "" : classes.btnDisabled}
          mt={0}
          mb={valetOrders.length > rowsPerPage ? 0 : 2}
          icon={AddToStorageIcon}
          iconOptions={{
            size: 30,
          }}
          onClick={handleAddToStorage}
          backgroundcolor={
            selectedCollectItems.length > 0 ? "#FFCC02" : "#6B6B6B"
          }
          title={t("itemsWithYou.button.label.addToStorage")}
        />
        {valetOrders.length > rowsPerPage && (
          <Box className={classes.paginationContainer}>
            <FPagination
              page={page}
              setPage={setPage}
              count={Math.ceil(valetOrders.length / rowsPerPage)}
            />
          </Box>
        )}
      </Box>
    </MemberBox>
  )
}

export default ItemsWithYou
