import PropTypes from "prop-types"
import { Box, makeStyles, Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    border: "1px dashed #CFCFCF",
    borderRadius: theme.typography.pxToRem(7),
    padding: theme.spacing(3, 2),
    alignItems: "center",
  },
  text: {
    color: "#6B6B6B",
    marginLeft: theme.spacing(2),
  },
}))

const NoBoxes = props => {
  const classes = useStyles()
  const { text, icon } = props
  return (
    <Box className={classes.root} display="flex">
      {icon && <img src={icon} width="64px" height="64px" alt="cargon" />}
      <Typography className={classes.text}>{text && text}</Typography>
    </Box>
  )
}

NoBoxes.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string,
}

export default NoBoxes
