import { makeStyles, Box, useMediaQuery, useTheme } from "@material-ui/core"
import HomeBg from "../assets/images/mobile/home_bg.png"
import HomeBgDesktop from "../assets/images/desktop/home_bg.jpg"
import { Switch, Route, Redirect } from "react-router-dom"
import { publicRoutes, protectedRoutes, privateRoutes } from "../router/routes"
import PrivateRoute from "../components/F.PrivateRoute"
import ProtectedRoute from "../components/F.ProtectedRoute"
import NotFoundRoute from "../components/F.NotFoundRoute"
import { ROUTES } from "../common/config"
import FDialogImage from "../components/F.DialogImage"
import { covidImages } from "../data/dialogImages"
import { useSelector, useDispatch } from "react-redux"
import { setAfterLoginPopup } from "../store/features/ui/uiSlice"
import Page from "./Page"

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
  },
  mainLayout: {
    backgroundImage: `url(${HomeBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundSize: "contain",
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${HomeBgDesktop})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
    [theme.breakpoints.between(1441, "xl")]: {
      height: "calc(100vh - 66px)",
      minHeight: 800,
    },
  },
}))

const MainLayout = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { lng } = useSelector(state => state.lng)
  const { afterLoginPopup } = useSelector(state => state.ui)
  const theme = useTheme()
  const upSm = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <div className={classes.root}>
      <Box className={classes.mainLayout}>
        <Switch>
          <Route exact path={ROUTES.HOME}>
            <Redirect to={ROUTES.LOGIN} />
          </Route>
          {publicRoutes.map(({ path, exact, Component, routes }) => (
            <Route key={path} exact={exact} path={path}>
              <Component routes={routes} />
            </Route>
          ))}
          {protectedRoutes.map(({ path, exact, Component, meta }) => (
            <ProtectedRoute key={path} exact={exact} path={path}>
              <Page headline={meta?.headline} background={meta?.background}>
                <Component />
              </Page>
            </ProtectedRoute>
          ))}
          {privateRoutes.map(({ path, exact, Component, meta, routes }) => (
            <PrivateRoute key={path} exact={exact} path={path}>
              {routes ? (
                <Component routes={routes} />
              ) : (
                <Page headline={meta?.headline} background={meta?.background}>
                  <Component />
                </Page>
              )}
            </PrivateRoute>
          ))}
          <NotFoundRoute path="*" exact={true} />
        </Switch>
      </Box>
      <FDialogImage
        setOpen={() => dispatch(setAfterLoginPopup(false))}
        open={afterLoginPopup}
        imageSrc={
          upSm
            ? `${process.env.PUBLIC_URL}/images/${covidImages[lng].desktop}`
            : `${process.env.PUBLIC_URL}/images/${covidImages[lng].mobile}`
        }
      />
    </div>
  )
}

export default MainLayout
