import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  boxes: {
    bin: 0,
    doc: 0,
  },
  appliedPromo: null,
  deliveryAddress: null,
  deliveryTime: null,
  error: null,
}

export const OrderBoxesSlice = createSlice({
  name: "orderBoxes",
  initialState,
  reducers: {
    initOrderData: (state, action) => {
      return (state = action.payload)
    },
    changeBoxes: (state, action) => {
      const { boxType, quantity } = action.payload
      boxType && (state.boxes[boxType] = state.boxes[boxType] + quantity)
    },
    changePromoCode: (state, action) => {
      state.appliedPromo = { code: action.payload }
    },
    applyPromoCode: (state, action) => {
      state.appliedPromo = action.payload
    },
    addAddress: (state, action) => {
      state.deliveryAddress = action.payload
    },
    addDeliveryInfo: (state, action) => {
      state.deliveryTime = action.payload
    },
    clearOrderData: state => initialState,
    orderFailed: (state, action) => {
      state.error = action.payload
    },
  },
})

export const {
  changeBoxes,
  changePromoCode,
  applyPromoCode,
  addAddress,
  addDeliveryInfo,
  clearOrderData,
  initOrderData,
  orderFailed,
} = OrderBoxesSlice.actions

export default OrderBoxesSlice.reducer
