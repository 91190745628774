import { createSlice } from "@reduxjs/toolkit"
import { getSessionStorage, setSessionStorage } from "../../../common/helpers"

const initialState = {
  afterLoginPopup: false,
  showBannerDownloadApp: getSessionStorage("downloadApp", true),
}

export const uiSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setAfterLoginPopup: (state, action) => {
      state.afterLoginPopup = action.payload
    },
    setShowBannerDownloadApp: (state, action) => {
      state.showBannerDownloadApp = action.payload
      setSessionStorage("downloadApp", action.payload)
    },
  },
})

export const { setAfterLoginPopup, setShowBannerDownloadApp } = uiSlice.actions

export default uiSlice.reducer
