import { useState } from "react"
import PropTypes from "prop-types"
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js"
import {
  Box,
  Typography,
  Button,
  makeStyles,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core"
import { apiGetBillingStripToken, apiSaveCard } from "../../../api/billing"
import { useSelector, useDispatch } from "react-redux"
import { fetchMemberCards } from "../../../store/features/member/memberSlice"
import { useTranslation } from "react-i18next"
import { orderSteps } from "../../../common/config"

const cardNumberOptions = {
  showIcon: true,
  style: {
    base: {
      "::placeholder": {
        color: "#6B6B6B",
        fontSize: "16px",
        fontWeight: "500",
      },
    },
  },
}

const CardExpiryOptions = {
  style: {
    base: {
      "::placeholder": {
        color: "#DBE2EA",
        fontSize: "16px",
        fontWeight: "500",
      },
    },
  },
}

const CardCvcOptions = {
  style: {
    base: {
      "::placeholder": {
        color: "#DBE2EA",
        fontSize: "16px",
        fontWeight: "500",
      },
    },
  },
}

const useStyles = makeStyles(theme => ({
  title: {
    color: "#6B6B6B",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(2, 0, 0, 0),
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
      margin: theme.spacing(1.5, 0, 0, 0),
    },
  },
  cardNum: {
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },
  },
  cardExpiryAndCvc: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      marginBottom: theme.spacing(1),
    },
  },
  addButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "none",
    padding: theme.spacing(1, 3),
    width: "100%",
    margin: theme.spacing(4, "auto", 4, "auto"),
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      margin: theme.spacing(1.5, "auto", 2, "auto"),
      display: "flex",
      minWidth: 180,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      "&.Mui-disabled": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    "&.Mui-disabled": {
      color: theme.palette.common.white,
      opacity: 0.5,
    },
  },
  errorMessage: {
    color: "#CA2026",
    backgroundColor: "#F2DEDE",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightSemiBold,
    display: "block",
    width: "fit-content",
    padding: theme.spacing(0, 0.5, 0, 0.5),
  },
}))

const CardElements = props => {
  const { setOpenModal, onComplete } = props
  const classes = useStyles()
  const [t] = useTranslation("common")
  const stripe = useStripe()
  const elements = useElements()
  const { user } = useSelector(state => state.member)
  const { lng } = useSelector(state => state.lng)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [cardNumError, setCardNumError] = useState("")
  const [cardExpiryError, setCardExpiryError] = useState("")
  const [cardCvcError, setCardCvcError] = useState("")
  const [confirmCardError, setConFirmCardError] = useState(
    t("billing.form.input.card.invalid"),
  )
  cardNumberOptions.placeholder = t("billing.form.input.label.cardNumber")

  const handleAdd = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    if (!cardNumError && !cardExpiryError && !cardCvcError) {
      try {
        setLoading(true)
        const res = await apiGetBillingStripToken()
        const { setupIntent, error } = await stripe.confirmCardSetup(
          res.clientSecret,
          {
            payment_method: {
              billing_details: {
                email: user?.email,
              },
              type: "card",
              card: elements.getElement(CardNumberElement),
            },
          },
        )
        // if error when confirm card setup from stripe
        if (error) {
          setLoading(false)
          error.message && setConFirmCardError(error.message)
          return
        }
        // save card if succeeded
        if (setupIntent.status === "succeeded") {
          try {
            await apiSaveCard(res.id)
            setLoading(false)
            setOpenModal(false)
            dispatch(fetchMemberCards())
            onComplete && onComplete({ step: orderSteps.BILLING })
          } catch (err) {
            setLoading(false)
          }
        }
      } catch (err) {
        setLoading(false)
      }
    }
  }
  const handleCardNumChange = e => {
    e.complete && elements?.getElement(CardExpiryElement)?.focus()
    e.error ? setCardNumError(e.error.message) : setCardNumError("")
    confirmCardError && setConFirmCardError("")
  }

  const handleCardExpiryChange = e => {
    e.empty && elements?.getElement(CardNumberElement)?.focus()
    e.complete && elements?.getElement(CardCvcElement)?.focus()
    e.error ? setCardExpiryError(e.error.message) : setCardExpiryError("")
    confirmCardError && setConFirmCardError("")
  }

  const handleCardCvcChange = e => {
    e.empty && elements?.getElement(CardExpiryElement)?.focus()
    e.error ? setCardCvcError(e.error.message) : setCardCvcError("")
    confirmCardError && setConFirmCardError("")
  }

  return (
    <div>
      <Typography className={classes.title}>
        {t("billing.form.label.creditCard/DebitCard")}
      </Typography>
      <Box className={classes.card}>
        <Box flexGrow={1} className={classes.cardNum}>
          <CardNumberElement
            onChange={handleCardNumChange}
            options={cardNumberOptions}
          />
        </Box>
        <Box className={classes.cardExpiryAndCvc} display="flex">
          <Box width={lng === "en" ? 70 : 75}>
            <CardExpiryElement
              onChange={handleCardExpiryChange}
              options={CardExpiryOptions}
            />
          </Box>
          <Box width={lng === "en" ? 50 : 70}>
            <CardCvcElement
              onChange={handleCardCvcChange}
              options={CardCvcOptions}
            />
          </Box>
        </Box>
      </Box>
      {cardNumError && (
        <FormHelperText className={classes.errorMessage}>
          {cardNumError}
        </FormHelperText>
      )}
      {cardExpiryError && (
        <FormHelperText className={classes.errorMessage}>
          {cardExpiryError}
        </FormHelperText>
      )}
      {cardCvcError && (
        <FormHelperText className={classes.errorMessage}>
          {cardCvcError}
        </FormHelperText>
      )}
      {!cardNumError &&
        !cardExpiryError &&
        !cardCvcError &&
        confirmCardError && (
          <FormHelperText className={classes.errorMessage}>
            {confirmCardError}
          </FormHelperText>
        )}
      <Button
        color="primary"
        disabled={loading}
        className={classes.addButton}
        onClick={handleAdd}>
        {loading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          t("billing.form.button.addANewCard")
        )}
      </Button>
    </div>
  )
}

CardElements.propTypes = {
  setOpenModal: PropTypes.func.isRequired,
}

export default CardElements
