import {
  Box,
  Typography,
  makeStyles,
  alpha,
  CardMedia,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import EmailImage from "../assets/icons/email.png"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: alpha(theme.palette.common.white, 0.5),
    padding: theme.spacing(4, 3, 2, 3),
    flexGrow: 1,
  },
  title: {
    color: "#6B6B6B",
    textAlign: "center",
    fontSize: theme.typography.pxToRem(22),
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(26),
      marginBottom: theme.spacing(5),
    },
  },
  desc: {
    color: "#756F86",
    marginBottom: theme.spacing(16),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(18),
    },
  },
  image: {
    margin: "0 auto",
    width: 180,
    height: 180,
  },
}))

const CantEditOrder = () => {
  const classes = useStyles()
  const [t] = useTranslation("common")

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>
        {t("global.message.cantEditOrderTitle")}
      </Typography>
      <Typography className={classes.desc}>
        {t("global.message.cantEditOrderDescription")}
      </Typography>
      <CardMedia image={EmailImage} className={classes.image} />
    </Box>
  )
}

export default CantEditOrder
