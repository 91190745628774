import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getLocalStorage, setLocalStorage } from "../../../common/helpers"
import { apiGetMemberProfile } from "../../../api/member"
import { apiGetItems } from "../../../api/items"
import { apiGetOrders } from "../../../api/order"
import { apiGetCards } from "../../../api/billing"

export const fetchMemberInfo = createAsyncThunk(
  "member/fetchMemberInfo",
  async () => {
    return await apiGetMemberProfile()
  }
)

export const fetchMemberCards = createAsyncThunk(
  "member/fetchMemberCards",
  async () => {
    return await apiGetCards()
  }
)

export const fetchMemberValetOrders = createAsyncThunk(
  "member/fetchMemberValetOrders",
  async () => {
    return await apiGetOrders()
  }
)

export const fetchMemberItems = createAsyncThunk(
  "member/fetchMemberItems",
  async () => {
    return await apiGetItems()
  }
)

const initialState = {
  user: getLocalStorage("user", null),
  memberValetOrders: getLocalStorage("valetOrders", null),
  memberItems: getLocalStorage("items", null),
  memberCards: [],
  fetchMemberCardsLoading: false,
  fetchMemberDataLoading: false,
}

export const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
      state.memberValetOrders = action.payload.valetOrders
      state.memberItems = action.payload.items
      setLocalStorage("user", action.payload)
      setLocalStorage("valetOrders", action.payload.valetOrders)
      setLocalStorage("items", action.payload.items)
    },
    updateUserLng: (state, action) => {
      state.user.language = action.payload
      setLocalStorage("user", state.user)
    },
    updateMemberItem: (state, action) => {
      const items = state.memberItems.map(i =>
        i.id === action.payload.id ? action.payload : i
      )
      state.memberItems = items
      setLocalStorage("items", items)
    },
    updateMemberItemImage: (state, action) => {
      const items = state.memberItems.map(i => {
        if (i.id === action.payload.id) {
          i.images.push(action.payload.image)
          return i
        }
        return i
      })
      state.memberItems = items
      setLocalStorage("items", items)
    },
  },
  extraReducers: {
    // user
    [fetchMemberInfo.pending]: state => {
      state.fetchMemberDataLoading = true
    },
    [fetchMemberInfo.fulfilled]: (state, action) => {
      state.fetchMemberDataLoading = false
      if (action.payload) {
        state.user = action.payload
        setLocalStorage("user", action.payload)
      }
    },
    [fetchMemberInfo.rejected]: state => {
      state.fetchMemberDataLoading = false
    },
    // cards
    [fetchMemberCards.pending]: state => {
      state.fetchMemberCardsLoading = true
    },
    [fetchMemberCards.fulfilled]: (state, action) => {
      state.memberCards = action.payload
      state.fetchMemberCardsLoading = false
    },
    [fetchMemberCards.rejected]: state => {
      state.fetchMemberCardsLoading = false
    },
    // valetOrders
    [fetchMemberValetOrders.fulfilled]: (state, action) => {
      state.memberValetOrders = action.payload
      setLocalStorage("valetOrders", action.payload)
    },
    // items
    [fetchMemberItems.fulfilled]: (state, action) => {
      state.memberItems = action.payload
      setLocalStorage("items", action.payload)
    },
  },
})

export const {
  setUser,
  updateUserLng,
  updateMemberItem,
  updateMemberItemImage,
} = memberSlice.actions

export default memberSlice.reducer
