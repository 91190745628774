import { Typography, Divider } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { ROUTES } from "../../../common/config"
import { useTranslation } from "react-i18next"
import FLink from "../../../components/F.Link"
import AuthLayout from "../../../layout/AuthLayout"
import ResetPasswordForm from "./resetPasswordForm"

const ResetPassword = () => {
  const history = useHistory()
  const [t] = useTranslation("common")

  return (
    <AuthLayout>
      <ResetPasswordForm />
      <Divider style={{ margin: "20px 0" }} />
      <FLink onClick={() => history.push(ROUTES.LOGIN)}>
        <Typography>
          {`${t("forgot.form.link.label.alreadyMember")}?`}
        </Typography>
        <Typography>{`${t("forgot.form.link.label.loginHere")}?`}</Typography>
      </FLink>
    </AuthLayout>
  )
}

export default ResetPassword
