import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import { makeStyles, Typography, Box } from "@material-ui/core"
import FCustomInput from "../components/F.CustomInput"
import { enUS, zhCN } from "date-fns/locale"
import { useSelector } from "react-redux"

const useStyles = makeStyles(theme => ({
  label: {
    color: "#3B3B3B",
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: 5,
  },
  popper: {
    paddingTop: "0px !important",
  },
}))

const FDatePicker = props => {
  const { label, ...rest } = props
  const { lng } = useSelector(state => state.lng)
  const classes = useStyles()

  return (
    <Box mb={1.8}>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <DatePicker
        {...rest}
        useWeekdaysShort={true}
        locale={lng === "en" ? enUS : zhCN}
        dateFormat="dd. MM. yyyy"
        showPopperArrow={false}
        customInput={<FCustomInput />}
        popperClassName={classes.popper}
      />
    </Box>
  )
}

FDatePicker.propTypes = {
  label: PropTypes.string,
}

export default FDatePicker
