import { useState, useEffect } from "react"
import MemberBox from "../../layout/MemberBox"
import { useTranslation } from "react-i18next"
import WaveTop from "../../assets/images/desktop/wave_top.png"
import WaveBottom from "../../assets/images/desktop/wave_bottom.png"
import BackButtonIcon from "../../assets/images/desktop/back_button.png"
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core"
import InvoiceLine from "./components/InvoiceLine"
import { financial, formatDate2ddMMyyyy } from "../../common/helpers"
import { useHistory, useParams } from "react-router-dom"
import { apiGetInvoiceById } from "../../api/invoice"

const useStyles = makeStyles(theme => ({
  invoiceDetail: {
    margin: "5px 24px 10px 24px",
    [theme.breakpoints.up("sm")]: {
      margin: "16px 16px 10px 16px",
    },
    "& img": {
      maxWidth: "100%",
      display: "block",
    },
  },
  invoiceDetailContent: {
    backgroundColor: "#FFFFFF8C",
    borderLeft: "1px solid #4E4E4E45",
    borderRight: "1px solid #4E4E4E45",
    padding: theme.spacing(1, 2, 2, 3),
    maxHeight: 530,
    overflowY: "scroll",
    scrollbarWidth: "none" /* Firefox */,
    "-ms-overflow-style": "none" /* IE and Edge */,
    "&::-webkit-scrollbar": {
      display: "none" /* Safari and Chrome */,
    },
    [theme.breakpoints.up("sm")]: {
      maxHeight: 560,
      padding: theme.spacing(2, 2, 2, 3),
    },
  },
  invoiceDetailContentHeader: {
    "& .MuiTypography-root": {
      color: "#CA2026",
      fontSize: 14,
      fontWeight: 600,
      [theme.breakpoints.up("sm")]: {
        fontSize: 16,
      },
    },
  },
  invoiceDetailContentBody: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(2.5),
    },
  },
  divider: {
    backgroundColor: "#707070",
    opacity: 0.25,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  total: {
    color: "#756F86",
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
  },
  lastAmountPaid: {
    color: "#756F86",
    fontSize: 14,
    fontWeight: 400,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
  },
  amountDue: {
    color: "#756F86",
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
  },
  backButton: {
    marginTop: theme.spacing(2),
    backgroundColor: "#CC0001",
    color: "#FFF",
    borderRadius: 3,
    width: "100%",
    fontSize: 16,
    fontWeight: 400,
    textTransform: "none",
    height: 40,
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(3),
      fontSize: 18,
    },
    "&:hover": {
      backgroundColor: "#CC0001",
    },
    "& .MuiButton-startIcon": {
      marginLeft: theme.spacing(-12),
      marginRight: theme.spacing(8),
      alignItems: "center",
    },
  },
}))

const InvoiceDetail = () => {
  const classes = useStyles()
  const [invoice, setInvoice] = useState(null)
  const [loading, setLoading] = useState(false)
  const [t] = useTranslation("common")
  const history = useHistory()
  const { id } = useParams()
  useEffect(
    () =>
      (async () => {
        setLoading(true)
        try {
          const response = await apiGetInvoiceById(id)
          setInvoice(response)
          setLoading(false)
        } catch (err) {
          setLoading(false)
          if (err.error?.type === "NotFound") {
            history.goBack()
          }
        }
      })(),
    [id, history]
  )

  return (
    <MemberBox title={t("invoiceDetail.label.invoiceDetail")}>
      <Box className={classes.invoiceDetail}>
        {invoice ? (
          <Box>
            <img src={WaveTop} alt="wave-top" />
            <Box className={classes.invoiceDetailContent}>
              <Box
                display="flex"
                className={classes.invoiceDetailContentHeader}
              >
                <Box>
                  <Typography>{t("invoiceDetail.label.invoice")}</Typography>
                  <Typography>
                    {t("invoiceDetail.label.invoiceDate")}
                  </Typography>
                </Box>
                <Box pl={0.5} pr={2}>
                  <Typography>:</Typography>
                  <Typography>:</Typography>
                </Box>
                <Box>
                  <Typography>{`#${invoice?.sid.toUpperCase()}`}</Typography>
                  <Typography>
                    {invoice?.startDate &&
                      formatDate2ddMMyyyy(invoice?.startDate)}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.invoiceDetailContentBody}>
                {invoice?.items &&
                  invoice?.items.map(item => (
                    <InvoiceLine key={item.id} data={item} />
                  ))}
              </Box>
              <Divider className={classes.divider} />
              <Box>
                <Typography className={classes.total} align="right">
                  {`${t("global.label.total")} : HK$${financial(
                    invoice?.total
                  )}`}
                </Typography>
                <Typography className={classes.lastAmountPaid} align="right">
                  {`${t("invoiceDetail.label.lastAmountPaid")} : HK$${financial(
                    invoice?.amountPaid
                  )}`}
                </Typography>
                <Typography className={classes.amountDue} align="right">
                  {`${t("invoiceDetail.label.amountDue")} : HK$${financial(
                    invoice?.balance
                  )}`}
                </Typography>
              </Box>
            </Box>
            <img src={WaveBottom} alt="wave-bottom" />
          </Box>
        ) : null}
        {loading && (
          <Box display="flex" justifyContent="center">
            <CircularProgress size={24} style={{ color: "#CC0001" }} />
          </Box>
        )}
        <Button
          onClick={() => history.goBack()}
          className={classes.backButton}
          startIcon={
            <img
              width={20}
              src={BackButtonIcon}
              alt={t("invoiceDetail.button.label.back")}
            />
          }
        >
          {t("invoiceDetail.button.label.back")}
        </Button>
      </Box>
    </MemberBox>
  )
}

export default InvoiceDetail
