import { useState, useEffect } from "react"

export default function useOSDetect() {
  const [OS, setOS] = useState("")

  useEffect(() => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      setOS("Windows Phone")
    }
    if (/android/i.test(userAgent)) {
      setOS("Android")
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setOS("iOS")
    }
  }, [])
  return { OS }
}
