import { createSlice } from "@reduxjs/toolkit"
import {
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
} from "../../../common/helpers"
// import { apiGetMemberProfile } from "../../../api/member"
// import { apiGetCards } from "../../../api/billing"

// export const fetchMemberInfo = createAsyncThunk(
//   "auth/fetchMemberInfo",
//   async (_, { getState }) => {
//     const { auth } = getState()
//     return await apiGetMemberProfile(auth?.accessToken)
//   }
// )

// export const fetchMemberCards = createAsyncThunk(
//   "auth/fetchMemberCards",
//   async accessToken => {
//     return await apiGetCards(accessToken)
//   }
// )

const initialState = {
  accessToken: getLocalStorage("accessToken", null),
  // userInfo: getLocalStorage("userInfo", null),
  user: getLocalStorage("user", null),
  // fetchMemberInfoLoading: false,
  // memberCards: [],
  // fetchMemberCardsLoading: false,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loggedIn: (state, action) => {
      state.accessToken = action.payload.accessToken
      state.user = action.payload.user
      setLocalStorage("accessToken", action.payload.accessToken)
      // setLocalStorage("userInfo", action.payload.user)
    },
    logout: state => {
      removeLocalStorage("accessToken")
      removeLocalStorage("user")
      removeLocalStorage("valetOrders")
      removeLocalStorage("items")
      // removeLocalStorage("settings")
      window.location.reload()
    },
  },
  extraReducers: {
    // [fetchMemberInfo.pending]: state => {
    //   state.fetchMemberInfoLoading = true
    // },
    // [fetchMemberInfo.fulfilled]: (state, action) => {
    //   state.userInfo = action.payload
    //   state.fetchMemberInfoLoading = false
    //   setLocalStorage("userInfo", action.payload)
    // },
    // [fetchMemberInfo.rejected]: state => {
    //   state.fetchMemberInfoLoading = false
    // },
    // [fetchMemberCards.pending]: state => {
    //   state.fetchMemberCardsLoading = true
    // },
    // [fetchMemberCards.fulfilled]: (state, action) => {
    //   state.memberCards = action.payload
    //   state.fetchMemberCardsLoading = false
    // },
    // [fetchMemberCards.rejected]: state => {
    //   state.fetchMemberCardsLoading = false
    // },
  },
})

export const { loggedIn, logout } = authSlice.actions

export default authSlice.reducer
