import { useState, useEffect, useRef, useCallback } from "react"
import {
  Box,
  Button,
  makeStyles,
  ClickAwayListener,
  Typography,
  CircularProgress,
} from "@material-ui/core"
import FTextField from "./F.TextField"
import { apiAddressLookup } from "../api/address"
import { getAddressLine, getAddressLookupRegion } from "../common/helpers"
import { useSelector } from "react-redux"
import PinIcon from "../assets/icons/pin.png"
import DeleteIcon from "../assets/icons/delete.png"
import useDebounce from "../hooks/useDebounce"
import { uniqWith, isEqual } from "lodash"

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
  },
  addresses: {
    borderRadius: 5,
    border: "1px solid #DBE2EA",
    position: "absolute",
    marginTop: theme.spacing(-1.5),
    backgroundColor: theme.palette.common.white,
    zIndex: 1,
    width: "100%",
    maxHeight: 272,
    overflow: "hidden",
    overflowY: "scroll",
    scrollbarWidth: "none" /* Firefox */,
    "-ms-overflow-style": "none" /* IE and Edge */,
    "&::-webkit-scrollbar": {
      display: "none" /* Safari and Chrome */,
    },
  },
  addressLine: {
    fontSize: theme.typography.pxToRem(12),
    textAlign: "left",
    paddingLeft: 10,
    paddingRight: 10,
    "& .MuiButton-label": {
      justifyContent: "flex-start",
    },
  },
  noOptions: {
    padding: "4px 0 4px 10px",
  },
  noOptionsText: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
  },
  close: {
    cursor: "pointer",
  },
}))

export default function FAutoComplete(props) {
  const classes = useStyles()
  const { onSelected, onChange, value, noOptionsText, ...rest } = props
  const { lng } = useSelector(state => state.lng)
  const controlSearchFunc = useRef(true)
  const [isSearching, setIsSearching] = useState(false)
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const debouncedSearchTerm = useDebounce(value, 300)

  const handleSearch = useCallback(
    async value => {
      if (value && value.length > (lng === "en" ? 3 : 2)) {
        setIsSearching(true)
        const res = await apiAddressLookup(value)
        res?.SuggestedAddress
          ? setOptions(res.SuggestedAddress)
          : setOptions(null)
        setIsSearching(false)
        setOpen(true)
      } else {
        // setOptions([])
        setOpen(false)
      }
    },
    [lng]
  )

  useEffect(
    () => {
      if (debouncedSearchTerm && !controlSearchFunc.current) {
        handleSearch(debouncedSearchTerm)
      }
    },
    [handleSearch, debouncedSearchTerm] // Only call effect if debounced search term changes
  )

  const handleAddressLookup = e => {
    onChange(e)
    if (controlSearchFunc.current) {
      controlSearchFunc.current = false
    }
  }

  const handleSelected = value => {
    controlSearchFunc.current = true
    setOpen(false)
    onSelected(value.region, value.addressLine)
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={classes.root}>
        <FTextField
          mt={36}
          multiline
          {...rest}
          value={value}
          onFocus={e =>
            options?.length > 0 &&
            value.length > (lng === "en" ? 3 : 2) &&
            setOpen(true)
          }
          onChange={handleAddressLookup}
          className="text-field"
          fullWidth
          endAdornmentIcon={isSearching ? <CircularProgress size={20} /> : null}
        />
        {open && (
          <Box className={classes.addresses}>
            {options ? (
              uniqWith(
                options?.map(option => ({
                  addressLine: getAddressLine(option, lng),
                  region: getAddressLookupRegion(option, lng),
                })),
                isEqual
              ).map((address, index) => (
                <Button
                  fullWidth
                  key={index}
                  onClick={() => handleSelected(address)}
                  startIcon={<img width={14} src={PinIcon} alt="pin" />}
                  className={classes.addressLine}
                >
                  {address.addressLine}
                </Button>
              ))
            ) : (
              <Box
                className={classes.noOptions}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography className={classes.noOptionsText}>
                  {noOptionsText}
                </Typography>
                <img
                  onClick={() => setOpen(false)}
                  className={classes.close}
                  src={DeleteIcon}
                  width={32}
                  alt="close"
                />
              </Box>
            )}
          </Box>
        )}
      </div>
    </ClickAwayListener>
  )
}
