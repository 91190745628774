import PropTypes from "prop-types"
import { Typography, makeStyles } from "@material-ui/core"
import WavesBox from "../layout/WavesBox"
import DeleteIcon from "../assets/icons/delete.png"
import EditIcon from "../assets/icons/edit.png"
import { formatEEEddLLLL, formatTimeslotOption } from "../common/helpers"
import { getTotalBoxes } from "../common/orderHelpers"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(theme => ({
  orderNumber: {
    color: theme.palette.primary.main,
  },
  deliveryTime: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightSemiBold,
  },
}))

const UpComingOrder = props => {
  const classes = useStyles()
  const [t] = useTranslation("common")
  const { order, onDeleteClick, onEditClick } = props
  const { lng } = useSelector(state => state.lng)

  return (
    <WavesBox
      key={order.id}
      mb={2}
      padding={{ pl: 3 }}
      topRightIcon={DeleteIcon}
      bottomLeftIcon={EditIcon}
      onDeleteClick={() => onDeleteClick(order)}
      onEditClick={() => onEditClick(order)}
    >
      <Typography display="inline">
        {t("global.text.youHaveUpcomingDeliveryOn")}
      </Typography>
      <Typography className={classes.deliveryTime} display="inline">
        {` ${
          order.date && formatEEEddLLLL(order.date, lng)
        }, ${formatTimeslotOption(order.timeslot)} `}
      </Typography>
      <Typography display="inline">{t("global.label.order")}</Typography>
      <Typography
        className={classes.orderNumber}
        display="inline"
      >{` #${order.sid.toUpperCase()}:`}</Typography>

      {getTotalBoxes(order.boxCounts) > 0 && (
        <Typography display="inline">
          {` ${t("global.label.delivering").toLowerCase()} ${getTotalBoxes(
            order.boxCounts
          )} ${t("global.label.emptyBoxes").toLowerCase()}`}
        </Typography>
      )}
      {order.deliverItems?.length > 0 && (
        <Typography display="inline">
          {` ${t("global.label.delivering").toLowerCase()} ${
            order.deliverItems?.length
          } ${t("global.label.items").toLowerCase()}`}
        </Typography>
      )}
      {order.collectItems?.length > 0 && (
        <Typography display="inline">
          {` ${t("global.label.collecting").toLowerCase()} ${
            order.collectItems?.length
          } ${t("global.label.items").toLowerCase()}`}
        </Typography>
      )}
    </WavesBox>
  )
}

UpComingOrder.propTypes = {
  order: PropTypes.object.isRequired,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
}

export default UpComingOrder
