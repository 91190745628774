import { configureStore } from "@reduxjs/toolkit"
import authReducer from "./features/auth/authSlice"
import lngReducer from "./features/lng/lngSlice"
import orderBoxesReducer from "./features/orderBoxes/orderBoxesSlice"
import settingsReducer from "./features/settings/settingsSlice"
import memberReducer from "./features/member/memberSlice"
import uiReducer from "./features/ui/uiSlice"
import scheduleReducer from "./features/schedule/scheduleSlice"

export const store = configureStore({
  reducer: {
    auth: authReducer,
    lng: lngReducer,
    orderBoxes: orderBoxesReducer,
    settings: settingsReducer,
    member: memberReducer,
    ui: uiReducer,
    schedule: scheduleReducer,
  },
})
