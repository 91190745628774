import { Box, makeStyles, Typography } from "@material-ui/core"
import WaveTop from "../../../assets/images/desktop/wave_top.png"
import WaveBottom from "../../../assets/images/desktop/wave_bottom.png"
import { useTranslation } from "react-i18next"
import { financial, formatDate2ddMMyyyy } from "../../../common/helpers"
import { useHistory } from "react-router-dom"
import { ROUTES } from "../../../common/config"

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
    cursor: "pointer",
    "& img": {
      maxWidth: "100%",
      display: "block",
    },
  },
  invoiceItem: {
    marginTop: "-10px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    backgroundColor: "#FFFFFF8C",
    borderLeft: "1px solid #4E4E4E45",
    borderRight: "1px solid #4E4E4E45",
    padding: theme.spacing(0, 0, 0, 3),
  },
  group: {
    marginTop: 10,
  },
  title: {
    color: "#3B3B3B",
    fontSize: 16,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  value: {
    color: "#807F7F",
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
}))

const InvoiceItem = props => {
  const classes = useStyles()
  const history = useHistory()
  const [t] = useTranslation("common")
  const { data } = props
  const { sid, state, startDate, total } = data

  return (
    <Box
      className={classes.root}
      onClick={() => history.push(`${ROUTES.MEMBER_INVOICES}/${sid}`)}
    >
      <img src={WaveTop} alt="wave-top" />
      <Box className={classes.invoiceItem}>
        <Box className={classes.group}>
          <Typography className={classes.title}>
            {t("invoiceDetail.label.invoiceNumber")}
          </Typography>
          <Typography className={classes.value}>{sid.toUpperCase()}</Typography>
        </Box>
        <Box className={classes.group}>
          <Typography className={classes.title}>
            {t("invoiceDetail.label.amount")}
          </Typography>
          <Typography className={classes.value}>{`$${financial(
            total
          )}`}</Typography>
        </Box>
        <Box className={classes.group}>
          <Typography className={classes.title}>
            {t("global.label.date")}
          </Typography>
          <Typography className={classes.value}>
            {startDate && formatDate2ddMMyyyy(startDate)}
          </Typography>
        </Box>
        <Box className={classes.group}>
          <Typography className={classes.title}>
            {t("global.label.status")}
          </Typography>
          <Typography className={classes.value}>
            {state.toUpperCase()}
          </Typography>
        </Box>
      </Box>
      <img src={WaveBottom} alt="wave-bottom" />
    </Box>
  )
}

export default InvoiceItem
