import PropTypes from "prop-types"
import { Box, Typography, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(-1),
    marginBottom: theme.spacing(3),
  },
  box: {
    marginLeft: theme.spacing(1),
  },
  feeType: {
    color: "#3B3B3B",
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  feeDesc: {
    color: "#807F7F",
  },
  fee: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}))

const FeeItem = props => {
  const classes = useStyles()
  const { title, desc, subDesc, fee } = props

  return (
    <Box
      className={classes.root}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box className={classes.box}>
        <Typography className={classes.feeType}>{title}</Typography>
        <Typography className={classes.feeDesc}>{desc}</Typography>
        {subDesc && (
          <Typography className={classes.feeDesc}>{subDesc}</Typography>
        )}
      </Box>
      <Typography className={classes.fee}>{fee}</Typography>
    </Box>
  )
}

FeeItem.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string,
  subDesc: PropTypes.string,
  fee: PropTypes.string,
}

export default FeeItem
