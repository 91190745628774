import { useState } from "react"
import { Divider, Typography, Box } from "@material-ui/core"
import SignUpForm from "../../auth/signUp/signUpForm"
import LoginForm from "../../auth/login/loginForm"
import ForgotPasswordForm from "../../auth/forgotPassword/forgotPasswordForm"
import AuthLayout from "../../../layout/AuthLayout"
import Page from "../../../layout/Page"
import FLink from "../../../components/F.Link"
import { useTranslation } from "react-i18next"

const config = {
  signup: {
    component: SignUpForm,
    meta: {
      headline: "signup.label.createYourAccount",
    },
  },
  login: {
    component: LoginForm,
    meta: {
      headline: "global.text.slogan",
    },
  },
  forgot: {
    component: ForgotPasswordForm,
    meta: {
      headline: "forgot.label.forgotPassword",
    },
  },
}

const Account = props => {
  const { onComplete } = props
  const [layout, setLayout] = useState("signup")
  const [t] = useTranslation("common")
  const LayoutComponent = config[layout].component

  return (
    <Page
      headline={config[layout].meta?.headline}
      background={config[layout].meta?.background}
    >
      <AuthLayout>
        <LayoutComponent onComplete={onComplete} />
        <Divider style={{ margin: "20px 0" }} />
        {layout === "login" ? (
          <Box display="flex" justifyContent="space-between">
            <FLink onClick={() => setLayout("signup")}>
              <Typography>{`${t("login.form.text.notAMember")}?`}</Typography>
              <Typography>{t("login.form.text.signUpHere")}</Typography>
            </FLink>
            <FLink onClick={() => setLayout("forgot")}>{`${t(
              "login.form.link.forgetPass"
            )}?`}</FLink>
          </Box>
        ) : (
          <FLink onClick={() => setLayout("login")}>
            <Typography>
              {layout === "signup"
                ? `${t("signup.form.link.label.alreadyMember")}?`
                : `${t("forgot.form.link.label.alreadyMember")}?`}
            </Typography>
            <Typography>
              {layout === "signup"
                ? `${t("signup.form.link.label.loginHere")}?`
                : `${t("forgot.form.link.label.loginHere")}?`}
            </Typography>
          </FLink>
        )}
      </AuthLayout>
    </Page>
  )
}

export default Account
