import React from "react"
import PropTypes from "prop-types"
import { usePagination } from "@material-ui/lab/Pagination"
import { makeStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
  ul: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
    justifyContent: "center",
  },
  paginationItem: {
    width: 36,
    height: 36,
    backgroundColor: "#FFF",
    borderRadius: "50%",
    color: "#CBCBCB",
    fontSize: 22,
    fontWeight: 400,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.down(400)]: {
      width: 34,
      height: 34,
    },
  },
  paginationItemActive: {
    backgroundColor: "#CA2026",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#CA2026",
    },
  },
  navItem: {
    width: 36,
    height: 36,
    fontSize: 32,
    color: "#CBCBCB",
    fontWeight: 400,
    padding: 0,
    [theme.breakpoints.down(400)]: {
      width: 32,
      height: 32,
    },

    "&:hover": {
      backgroundColor: "none",
    },
    "&.Mui-disabled": {
      color: "#CBCBCB",
      opacity: 0.5,
    },
  },
}))

const FPagination = props => {
  const classes = useStyles()
  const { setPage, page, count } = props
  const change = (event, page) => {
    setPage(page - 1)
  }
  const { items } = usePagination({
    count,
    page: page + 1,
    onChange: change,
  })

  return (
    <nav>
      <ul className={classes.ul}>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null
          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…"
          } else if (type === "page") {
            children = (
              <IconButton
                className={
                  selected
                    ? clsx(classes.paginationItem, classes.paginationItemActive)
                    : classes.paginationItem
                }
                {...item}
              >
                {page}
              </IconButton>
            )
          } else if (type === "previous") {
            children = (
              <IconButton
                className={classes.navItem}
                {...item}
              >{`<`}</IconButton>
            )
          } else {
            children = (
              <IconButton
                className={classes.navItem}
                {...item}
              >{`>`}</IconButton>
            )
          }
          return (
            <li key={index} style={{ margin: "auto 0" }}>
              {children}
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

FPagination.propTypes = {
  setPage: PropTypes.func,
  page: PropTypes.number,
  count: PropTypes.number,
}

export default FPagination
