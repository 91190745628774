import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import Radio from "@material-ui/core/Radio"

const useStyles = makeStyles(theme => ({
  root: {},
  icon: {
    borderRadius: "50%",
    width: 20,
    height: 20,
    border: `${theme.typography.pxToRem(2)} solid #DBE2EA`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
  },
  checkedIcon: {
    "&:before": {
      display: "block",
      width: 10,
      height: 10,
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
      content: '""',
    },
  },
}))

const FRadio = props => {
  const classes = useStyles()

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  )
}

export default FRadio
