import Login from "../containers/auth/login"
import SignUp from "../containers/auth/signUp"
import ForgotPassword from "../containers/auth/forgotPassword"
import ResetPassword from "../containers/auth/resetPassword"
import Profile from "../containers/profile"
import Invoices from "../containers/invoices"
import InvoiceDetail from "../containers/invoiceDetail"
import Billing from "../containers/billing"
import ProfileBg from "../assets/images/mobile/member_profile_bg.png"
import ProfileBgDesktop from "../assets/images/desktop/member_profile_bg.jpeg"
import OrderBoxesBg from "../assets/images/mobile/order_boxes_bg.jpeg"
import OrderBoxesBgDesktop from "../assets/images/desktop/order_boxes_bg.jpeg"
import { ROUTES } from "../common/config"
import ItemsWithYou from "../containers/items/withYou"
import itemsInStorage from "../containers/items/inStorage"
import ScheduleAddress from "../containers/orderFlow/scheduleAddress"
import OrderFlow from "../containers/orderFlow"
import OrderBoxes from "../containers/orderFlow/boxes"
import OrderAddress from "../containers/orderFlow/address"
import OrderTime from "../containers/orderFlow/time"
import OrderSummary from "../containers/orderFlow/summary"
import EditOrderFlow from "../containers/orderFlow/edit"
import ScheduleFlow from "../containers/orderFlow/schedule"
import ReScheduleFlow from "../containers/orderFlow/reschedule"

const orderStepRoutes = [
  {
    path: "/boxes",
    Component: OrderBoxes,
    exact: true,
    meta: {
      headline: "orderFlow.slogan.label.whatWouldYouLikeToStore",
      background: {
        desktop: OrderBoxesBgDesktop,
        mobile: OrderBoxesBg,
      },
    },
  },
  {
    path: "/address",
    Component: OrderAddress,
    exact: true,
    meta: {
      headline: "orderFlow.slogan.label.confirmAddressAndContactInformation",
      background: {
        desktop: OrderBoxesBgDesktop,
        mobile: OrderBoxesBg,
      },
    },
  },
  {
    path: "/time",
    Component: OrderTime,
    meta: {
      headline: "orderFlow.slogan.label.whenCanWeCollectYourItems",
    },
  },
  {
    path: "/billing",
    Component: Billing,
    meta: {
      headline: "billing.label.howWouldYouLikeToPay",
    },
  },
  {
    path: "/summary",
    Component: OrderSummary,
    meta: {
      headline: "orderFlow.slogan.label.whenCanWeCollectYourItems",
    },
  },
]

const orderEditStepRoutes = [
  {
    path: "/boxes",
    Component: OrderBoxes,
    exact: true,
    meta: {
      headline: "orderFlow.slogan.label.whatWouldYouLikeToStore",
      background: {
        desktop: OrderBoxesBgDesktop,
        mobile: OrderBoxesBg,
      },
    },
  },
  {
    path: "/address",
    Component: OrderAddress,
    exact: true,
    meta: {
      headline: "orderFlow.slogan.label.confirmAddressAndContactInformation",
      background: {
        desktop: OrderBoxesBgDesktop,
        mobile: OrderBoxesBg,
      },
    },
  },
  {
    path: "/time",
    Component: OrderTime,
    meta: {
      headline: "orderFlow.slogan.label.whenCanWeCollectYourItems",
    },
  },
  {
    path: "/summary",
    Component: OrderSummary,
    meta: {
      headline: "orderFlow.slogan.label.whenCanWeCollectYourItems",
    },
  },
]

const scheduleStepRoutes = [
  {
    path: "/scheduleAddress",
    Component: ScheduleAddress,
    exact: true,
    meta: {
      headline: "global.text.slogan",
    },
  },
  {
    path: "/summary",
    Component: OrderSummary,
    meta: {
      headline: "orderFlow.slogan.label.whenCanWeCollectYourItems",
    },
  },
]

export const publicRoutes = [
  {
    path: `${ROUTES.VALET}/get-started`,
    exact: false,
    Component: OrderFlow,
    routes: orderStepRoutes,
  },
]

export const protectedRoutes = [
  {
    path: ROUTES.LOGIN,
    exact: true,
    Component: Login,
    meta: {
      headline: "global.text.slogan",
    },
  },
  {
    path: ROUTES.SIGN_UP,
    exact: true,
    Component: SignUp,
    meta: {
      headline: "signup.label.createYourAccount",
    },
  },
  {
    path: ROUTES.FORGET_PASSWORD,
    exact: true,
    Component: ForgotPassword,
    meta: {
      headline: "forgot.label.forgotPassword",
    },
  },
  {
    path: ROUTES.RESET_PASSWORD,
    exact: true,
    Component: ResetPassword,
    meta: {
      headline: "global.label.resetYourPassword",
    },
  },
]

export const privateRoutes = [
  {
    path: ROUTES.MEMBER_PROFILE,
    exact: true,
    Component: Profile,
    meta: {
      headline: "global.text.slogan",
      background: {
        desktop: ProfileBgDesktop,
        mobile: ProfileBg,
      },
    },
  },
  {
    path: ROUTES.MEMBER_INVOICES,
    exact: true,
    Component: Invoices,
    meta: {
      headline: "global.text.slogan",
    },
  },
  {
    path: `${ROUTES.MEMBER_INVOICES}/:id?`,
    exact: true,
    Component: InvoiceDetail,
    meta: {
      headline: "global.text.slogan",
    },
  },
  {
    path: `${ROUTES.VALET}/billing`,
    exact: true,
    Component: Billing,
    meta: {
      headline: "billing.label.howWouldYouLikeToPay",
    },
  },
  {
    path: `${ROUTES.VALET}/order-boxes`,
    exact: false,
    Component: OrderFlow,
    routes: orderStepRoutes,
  },
  {
    path: `${ROUTES.VALET}/items-user`,
    exact: true,
    Component: ItemsWithYou,
    meta: {
      headline: "global.text.slogan",
    },
  },
  {
    path: `${ROUTES.VALET}/items-storage`,
    exact: true,
    Component: itemsInStorage,
    meta: {
      headline: "global.text.slogan",
    },
  },
  {
    path: `${ROUTES.VALET}/order/:sid`,
    exact: false,
    Component: EditOrderFlow,
    routes: orderEditStepRoutes,
  },
  {
    path: `${ROUTES.VALET}/schedule`,
    exact: false,
    Component: ScheduleFlow,
    routes: scheduleStepRoutes,
  },
  {
    path: `${ROUTES.VALET}/reschedule/:sid`,
    exact: false,
    Component: ReScheduleFlow,
    routes: scheduleStepRoutes,
  },
]
