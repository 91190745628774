import { Redirect } from "react-router-dom"
import { ROUTES } from "../common/config"
import { useSelector } from "react-redux"

const NotFoundRoute = props => {
  const { accessToken } = useSelector(state => state.auth)
  return accessToken ? (
    <Redirect
      to={{
        pathname: `${ROUTES.VALET}/items-user`,
        state: { from: props.location },
      }}
    />
  ) : (
    <Redirect
      to={{
        pathname: ROUTES.LOGIN,
        state: { from: props.location },
      }}
    />
  )
}

export default NotFoundRoute
