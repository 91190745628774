export const promoImages = {
  en: {
    mobile: "promo_en_600x400_mobile.jpg",
    desktop: "promo_en_800x533_desktop.jpg",
  },
  zh: {
    mobile: "promo_tc_600x400_mobile.jpg",
    desktop: "promo_tc_800x533_desktop.jpg",
  },
  cn: {
    mobile: "promo_sc_600x400_mobile.jpg",
    desktop: "promo_sc_800x533_desktop.jpg",
  },
}

export const stairCarryImages = {
  en: {
    mobile: "staircarry_en_600x400_mobile.jpeg",
    desktop: "staircarry_en_800x533_desktop.jpeg",
  },
  zh: {
    mobile: "staircarry_tc_600x400_mobile.jpeg",
    desktop: "staircarry_tc_800x533_desktop.jpeg",
  },
  cn: {
    mobile: "staircarry_sc_600x400_mobile.jpeg",
    desktop: "staircarry_sc_800x533_desktop.jpeg",
  },
}

export const covidImages = {
  en: {
    mobile: "covid_en_600x400_mobile.jpeg",
    desktop: "covid_en_800x533_desktop.jpeg",
  },
  zh: {
    mobile: "covid_tc_600x400_mobile.jpeg",
    desktop: "covid_tc_800x533_desktop.jpeg",
  },
  cn: {
    mobile: "covid_sc_600x400_mobile.jpeg",
    desktop: "covid_sc_800x533_desktop.jpeg",
  },
}
