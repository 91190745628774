import { useState, useRef } from "react"
import PropTypes from "prop-types"
import {
  Box,
  ButtonBase,
  makeStyles,
  Typography,
  CircularProgress,
  CardMedia,
  Divider,
} from "@material-ui/core"
import UploadImageIcon from "../assets/icons/upload_image.png"
import EditIcon from "../assets/icons/edit.png"
import ResetIcon from "../assets/icons/reset.png"
import BackGreyIcon from "../assets/icons/back_button_grey.png"
import SaveIcon from "../assets/icons/save.png"
import UnCheckIcon from "../assets/icons/unchecked.png"
import CheckIcon from "../assets/icons/checked.png"
import FTexField from "./F.TextField"
import { mapSiteLngToBackendLng, formatddLLL_EEEE } from "../common/helpers"
import { useSelector, useDispatch } from "react-redux"
import { Formik } from "formik"
import { apiItemInfo, apiUploadImage } from "../api/items"
import { last } from "lodash"
import { useTranslation } from "react-i18next"
import { itemState } from "../common/config"
import {
  addDeliverItem,
  addCollectItem,
} from "../store/features/schedule/scheduleSlice"
import {
  updateMemberItem,
  updateMemberItemImage,
} from "../store/features/member/memberSlice"

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
  },
  imgCheckBox: {
    position: "absolute",
    top: -10,
    left: -13,
    zIndex: 1,
  },
  boxArrive: {
    border: "1px dashed #CFCFCF",
    borderRadius: theme.typography.pxToRem(7),
    padding: theme.spacing(3, 3, 2, 3),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
  },
  imageContainer: {
    position: "relative",
  },
  boxImage: {
    width: "100%",
    height: "auto",
    borderRadius: theme.typography.pxToRem(5),
  },
  uploadImage: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    cursor: "pointer",
  },
  boxContent: {
    marginTop: theme.spacing(0.5),
  },
  textFieldTitle: {
    marginBottom: theme.spacing(1),
  },
  editImage: {
    width: 44,
    alignSelf: "flex-start",
    marginLeft: theme.spacing(-6),
    marginRight: theme.spacing(0.5),
    cursor: "pointer",
    // zIndex: 1,
  },
  boxTitle: {
    color: "#6B6B6B",
  },
  boxSid: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
  },
  boxDescription: {
    color: "#6B6B6B",
  },
  boxEditContent: {
    position: "relative",
    boxShadow: "0 2px 3px #0000000D",
    border: "1px solid #0000000D",
    borderRadius: theme.typography.pxToRem(25),
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2, 3, 3, 3),
    margin: theme.spacing(1.5, 0, 1, 0),
  },
  resetImage: {
    cursor: "pointer",
    width: 44,
    position: "absolute",
    top: -16,
    right: -16,
  },
  backImage: {
    cursor: "pointer",
    width: 44,
    position: "absolute",
    top: 22,
    right: -16,
  },
  saveImage: {
    cursor: "pointer",
    position: "absolute",
    bottom: 12,
    left: -16,
    "& img": {
      width: 44,
    },
  },
  divider: {
    margin: theme.spacing(1.5, 0),
  },
}))

const FBoxArrive = props => {
  const classes = useStyles()
  const [t] = useTranslation("common")
  const dispatch = useDispatch()
  // const [item, setItem] = useState(props.item)
  const { item } = props
  const image = item.images?.length ? last(item.images).url : item.image
  // const [image, setImage] = useState(
  //   item.images?.length ? last(item.images).url : item.image
  // )
  const { lng } = useSelector(state => state.lng)
  const { memberValetOrders } = useSelector(state => state.member)
  const { selectedDeliverItems, selectedCollectItems } = useSelector(
    state => state.schedule
  )
  const [openEdit, setOpenEdit] = useState(false)
  const [uploadLoading, setUploadLoading] = useState(false)
  const inputFileRef = useRef(null)
  const isIncludeInOrder = item.valetOrderId
    ? memberValetOrders?.find(order => order.id === item.valetOrderId)
    : memberValetOrders?.find(order =>
        item.state === itemState.USER
          ? order.collectItems?.includes(item.id)
          : order.deliverItems?.includes(item.id)
      )
  const isCheck =
    item.state === itemState.USER
      ? selectedCollectItems.includes(item.id)
      : selectedDeliverItems.includes(item.id)

  const handleFileInput = () => {
    inputFileRef.current.click()
  }

  const handleImageChange = async e => {
    if (e.target.files && e.target.files[0]) {
      try {
        setUploadLoading(true)
        let formData = new FormData()
        formData.append("file", e.target.files[0])
        const res = await apiUploadImage(item.id, formData)
        dispatch(updateMemberItemImage({ id: item.id, image: res }))
        setUploadLoading(false)
      } catch (err) {
        console.error(err)
        setUploadLoading(false)
      }
    }
  }
  const handleSelect = id => {
    item.state === itemState.USER
      ? dispatch(addCollectItem(id))
      : dispatch(addDeliverItem(id))
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.boxArrive}>
        {!isIncludeInOrder && (
          <img
            className={classes.imgCheckBox}
            onClick={() => handleSelect(item.id)}
            src={isCheck ? CheckIcon : UnCheckIcon}
            width={44}
            alt="check"
          />
        )}
        <Box className={classes.imageContainer}>
          <CardMedia
            component="img"
            className={classes.boxImage}
            width={"100%"}
            height={"200px"}
            image={image}
            alt={
              item.title
                ? item.title
                : item.typeTitle[mapSiteLngToBackendLng(lng)]
            }
          />
          <Box className={classes.uploadImage}>
            {uploadLoading ? (
              <CircularProgress style={{ color: "#CC0001" }} size={44} />
            ) : (
              <img
                onClick={handleFileInput}
                src={UploadImageIcon}
                width={80}
                alt="upload"
              />
            )}
          </Box>
          <input
            style={{ display: "none" }}
            ref={inputFileRef}
            type="file"
            name="avatar"
            onChange={handleImageChange}
          />
        </Box>
        {openEdit ? (
          <Box className={classes.boxEditContent}>
            <Formik
              initialValues={{
                title: item.title,
                desc: item.desc,
              }}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const res = await apiItemInfo(item.id, values)
                  dispatch(updateMemberItem(res))
                  // setItem(res)
                  setOpenEdit(false)
                  setSubmitting(false)
                } catch (err) {
                  setSubmitting(false)
                }
              }}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                handleReset,
                isSubmitting,
              }) => (
                <>
                  <img
                    className={classes.resetImage}
                    src={ResetIcon}
                    alt="reset"
                    onClick={handleReset}
                  />
                  <img
                    className={classes.backImage}
                    onClick={() => setOpenEdit(false)}
                    src={BackGreyIcon}
                    alt="back"
                  />
                  <form onSubmit={handleSubmit}>
                    <FTexField
                      className={classes.textFieldTitle}
                      label={t("global.label.title")}
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                    />
                    <FTexField
                      label={t("global.label.description")}
                      onChange={handleChange}
                      value={values.desc}
                      name="desc"
                      multiline
                      rows={4}
                    />
                    <ButtonBase
                      className={classes.saveImage}
                      disableRipple
                      disabled={isSubmitting}
                      type="submit"
                    >
                      <img src={SaveIcon} alt="save" />
                    </ButtonBase>
                  </form>
                </>
              )}
            </Formik>
          </Box>
        ) : (
          <Box display="flex" className={classes.boxContent}>
            <img
              className={classes.editImage}
              onClick={() => setOpenEdit(true)}
              src={EditIcon}
              alt="edit"
            />
            <Box>
              <Typography
                className={classes.boxTitle}
                title={t("global.label.title")}
              >
                {item.title
                  ? item.title
                  : item.typeTitle[mapSiteLngToBackendLng(lng)]}
              </Typography>
              <Typography className={classes.boxSid}>
                {`#${item.sid.toUpperCase()}`}
              </Typography>
              <Typography
                title={t("global.label.description")}
                className={classes.boxDescription}
              >
                {item.desc}
              </Typography>
              {isIncludeInOrder && (
                <>
                  <Divider className={classes.divider} />
                  <Typography>
                    {item.state === itemState.USER
                      ? `${t("global.text.toBeCollectedOn")} ${formatddLLL_EEEE(
                          isIncludeInOrder.collectDate,
                          lng
                        )}`
                      : `${t("global.text.toBeDeliveredOn")} ${formatddLLL_EEEE(
                          isIncludeInOrder.collectDate,
                          lng
                        )}`}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

FBoxArrive.propTypes = {
  item: PropTypes.object.isRequired,
}

export default FBoxArrive
