import React from "react"
import { makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { mapSiteLngToBackendLng } from "../common/helpers"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#656565",
    padding: theme.spacing(1.5),
    "& a": {
      color: theme.palette.common.white,
      textDecoration: "none",
    },
  },
}))

const Footer = () => {
  const classes = useStyles()
  const [t] = useTranslation("common")
  const { termsUrl, customFields } = useSelector((state) => state.settings)
  const { lng } = useSelector((state) => state.lng)
  const { accessToken } = useSelector((state) => state.auth)

  return (
    <div className={classes.root}>
      <a
        rel="noreferrer"
        target="_blank"
        href={
          termsUrl
            ? accessToken
              ? `${termsUrl}?lang=${mapSiteLngToBackendLng(lng)}`
              : termsUrl
            : "#"
        }
      >
        {t("global.label.terms&Conditions")}
      </a>
      &nbsp;&nbsp;&nbsp;
      <a
        rel="noreferrer"
        target="_blank"
        href={
          customFields?.privacy_policy
            ? accessToken
              ? `${customFields?.privacy_policy}?lang=${mapSiteLngToBackendLng(
                  lng
                )}`
              : customFields?.privacy_policy
            : "#"
        }
      >
        {t("global.label.privacyPolicy")}
      </a>
    </div>
  )
}

export default Footer
