import LeftIcon from "../../../../assets/icons/left.png"
import RightIcon from "../../../../assets/icons/right.png"
import { arrowsPlugin } from "@brainhubeu/react-carousel"
import Arrow from "./Arrow"
export const CarouselPlugins = [
  {
    resolve: arrowsPlugin,
    options: {
      arrowLeft: <Arrow icon={LeftIcon} />,
      arrowRight: <Arrow icon={RightIcon} />,
      addArrowClickHandler: true,
    },
  },
]
