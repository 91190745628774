import { createSlice } from "@reduxjs/toolkit"
import { getLocalStorage, setLocalStorage } from "../../../common/helpers"

const initialState = {
  lng: getLocalStorage("lng", "en"),
}

export const lngSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    changeLng: (state, action) => {
      state.lng = action.payload
      setLocalStorage("lng", action.payload)
    },
  },
})

export const { changeLng } = lngSlice.actions

export default lngSlice.reducer
