import {
  Box,
  Step,
  Stepper,
  StepLabel,
  withStyles,
  makeStyles,
  StepConnector,
} from "@material-ui/core"
import clsx from "clsx"
import PropTypes from "prop-types"

const ColorlibConnector = withStyles({
  root: {
    flex: "none",
  },
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: "#919191",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#919191",
    },
  },
  line: {
    width: 32,
    height: 2,
    border: 0,
    backgroundColor: "#919191",
  },
})(StepConnector)

const useColorlibStepIconStyles = makeStyles(theme => ({
  root: {
    zIndex: 1,
    color: "#CBCBCB",
    width: 39,
    height: 39,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: theme.typography.pxToRem(22),
    fontWeight: theme.typography.fontWeightRegular,
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.common.white,
    paddingRight: 0,
    [theme.breakpoints.up("sm")]: {
      width: 44,
      height: 44,
    },
  },
  active: {
    // backgroundColor: theme.palette.common.white,
    // color: "#CBCBCB",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  completed: {
    // backgroundColor: theme.palette.primary.main,
    // color: theme.palette.common.white,
  },
}))

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props

  const icons = {
    1: "1",
    2: "2",
    3: "3",
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
    },
  },
  stepper: {
    padding: 0,
    justifyContent: "center",
  },
  stepLabel: {
    cursor: "pointer",
    "& .MuiStepLabel-iconContainer": {
      paddingRight: 0,
    },
  },
}))

const StepperStep = {
  1: "boxes",
  2: "address",
  3: "time",
}

const FStepper = props => {
  const classes = useStyles()
  const { activeStep, gotoStep } = props

  const handleClick = stepNum => {
    gotoStep(StepperStep[stepNum])
  }

  return (
    <Box className={classes.root}>
      <Stepper
        className={classes.stepper}
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {[1, 2, 3].map(s => (
          <Step key={s}>
            <StepLabel
              onClick={() => handleClick(s)}
              className={classes.stepLabel}
              StepIconComponent={ColorlibStepIcon}
            ></StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

FStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
}

export default FStepper
