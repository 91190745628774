import { forwardRef } from "react"
import DateIcon from "../assets/images/desktop/date.png"
import DropdownIcon from "../assets/images/desktop/ic_chevron_down.png"
import { Button, makeStyles, Box } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: "center",
    border: "1px solid #DBE2EA",
    borderRadius: 8,
    backgroundColor: theme.palette.common.white,
  },
  button: {
    justifyContent: "flex-start",
    color: "#6B6B6B",
    [theme.breakpoints.down(400)]: {
      padding: "6px 0 6px 3px",
      fontSize: theme.typography.pxToRem(13),
    },
    [theme.breakpoints.down(340)]: {
      padding: "6px 0 6px 3px",
      fontSize: theme.typography.pxToRem(12),
    },
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiButton-startIcon": {
      alignItems: "center",
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(1),
      "& img": {
        width: 24,
        [theme.breakpoints.down(400)]: {
          width: 20,
        },
      },
    },
  },
}))

const FCustomInput2 = forwardRef((props, ref) => {
  const { value, onClick } = props
  const classes = useStyles(props)
  return (
    <Box className={classes.root} display="flex">
      <Button
        fullWidth
        className={classes.button}
        disableRipple
        startIcon={<img src={DateIcon} width={24} alt="date-picker" />}
        onClick={onClick}
        ref={ref}
      >
        {value}
      </Button>
      <img src={DropdownIcon} width={24} alt="date-picker" />
    </Box>
  )
})

export default FCustomInput2
