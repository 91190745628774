import { createTheme } from "@material-ui/core"
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: ["segoeui", "Segoe UI", "Roboto", "Arial"].join(","),
    fontWeightSemiBold: 600,
  },
  spacing: 8,
  palette: {
    primary: {
      main: "#CA2026",
    },
  },
})
theme.typography.body1 = {
  fontSize: theme.typography.pxToRem(14),
  [theme.breakpoints.up("sm")]: {
    fontSize: theme.typography.pxToRem(16),
  },
  [theme.breakpoints.between(1441, "xl")]: {
    fontSize: theme.typography.pxToRem(18),
  },
}

export default theme
