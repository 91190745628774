import { Chip, Divider, makeStyles } from "@material-ui/core"
import { Fragment } from "react"
import { useSelector, useDispatch } from "react-redux"
import { siteLanguages } from "../common/config"
import { changeLng } from "../store/features/lng/lngSlice"
import { updateUserLng } from "../store/features/member/memberSlice"
import { mapSiteLngToBackendLng } from "../common/helpers"
import { apiUpdateMemberProfile } from "../api/member"

const useStyles = makeStyles(theme => ({
  languageSwitcher: {
    display: "flex",
    padding: theme.spacing(1, 0, 1, 1),
    alignItems: "center",
    "& .language-swicher__chip": {
      color: "#FFF",
      backgroundColor: "transparent",
      fontSize: 12,
      width: 32,
      borderRadius: "50%",
      "& .MuiChip-label": {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    "& .language-swicher__chip--active": {
      backgroundColor: "#FFCC02",
      opacity: 0.9,
    },
    "& .language-swicher__divider": {
      backgroundColor: "#FFF",
      marginLeft: theme.spacing(2.5),
      marginRight: theme.spacing(2.5),
      height: 16,
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
      "&:last-child": {
        display: "none",
      },
    },
  },
}))

const LanguageSwitcher = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { lng } = useSelector(state => state.lng)

  const handleChange = code => {
    if (lng !== code) {
      const backendLng = mapSiteLngToBackendLng(code)
      apiUpdateMemberProfile({ language: backendLng }).then(res => {
        dispatch(changeLng(code))
        dispatch(updateUserLng(backendLng))
      })
    }
  }

  return (
    <div className={classes.languageSwitcher}>
      {siteLanguages.map(l => (
        <Fragment key={l.code}>
          <Chip
            className={`language-swicher__chip ${
              lng === l.code && "language-swicher__chip--active"
            }`}
            onClick={() => handleChange(l.code)}
            label={l.name}
            clickable
          />
          <Divider
            orientation="vertical"
            className="language-swicher__divider"
          />
        </Fragment>
      ))}
    </div>
  )
}

export default LanguageSwitcher
