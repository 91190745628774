import PropTypes from "prop-types"
import { makeStyles, Box, CardMedia, Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    // width: "100%",
    backgroundColor: "#FBAD14",
    borderRadius: 23,
    color: theme.palette.common.white,
    alignItems: "center",
    cursor: "pointer",
    padding: theme.spacing(0.5, 3, 0.5, 3),
    // position: "relative",
    justifyContent: "center",
  },
  icon: {
    width: 20,
    height: 20,
    // margin: theme.spacing(0.5, 0.5, 0.5, 1.5),
    // position: "absolute",
  },
  text: {
    textAlign: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // marginLeft: theme.spacing(-3),
    // fontWeight: theme.typography.fontWeightSemiBold,
  },
}))

const FBoxInfo = props => {
  const classes = useStyles()
  const { text, icon, onClick } = props
  return (
    <Box onClick={onClick} className={classes.root} display="flex">
      <CardMedia className={classes.icon} image={icon} />
      <Typography className={classes.text}>{text}</Typography>
    </Box>
  )
}

FBoxInfo.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node,
  onClick: PropTypes.func,
}

export default FBoxInfo
