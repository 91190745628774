import {
  Box,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"
import FButton from "../../../components/F.Button"
import CardIcon from "../../../assets/icons/card.png"
import ExchangeIconDesktop from "../../../assets/images/desktop/exchange.png"
import ExchangeIcon from "../../../assets/images/mobile/exchange.png"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(theme => ({
  paymentMethod: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#3B3B3B",
  },
  cardText: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightSemiBold,
    color: "#3B3B3B",
  },
  cardContent: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#6B6B6B",
    wordSpacing: theme.typography.pxToRem(9),
  },
}))

const ChangeCard = props => {
  const classes = useStyles()
  const [t] = useTranslation("common")
  const { cards, setOpenModal } = props
  const theme = useTheme()
  const upSm = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography className={classes.paymentMethod}>
          {t("billing.form.label.yourDefaultPayment")}
        </Typography>
        <img
          src={CardIcon}
          width={22}
          height={16}
          alt={t("billing.form.label.yourDefaultPayment")}
        />
      </Box>
      {cards.length ? (
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.cardText}>
              {t("billing.form.label.yourCardNumber")}
            </Typography>
            <Typography className={classes.cardText}>
              {t("global.label.expiryDate")}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.cardContent}>
              {`**** **** **** ${cards[0].last4}`}
            </Typography>
            <Typography className={classes.cardContent}>{`${
              cards[0].exp_month < 10
                ? `0${cards[0].exp_month}`
                : cards[0].exp_month
            }/${cards[0].exp_year}`}</Typography>
          </Box>
        </Box>
      ) : null}
      <FButton
        title={t("billing.form.button.addANewCard")}
        onClick={() => setOpenModal(true)}
        icon={upSm ? ExchangeIconDesktop : ExchangeIcon}
        iconOptions={{
          size: 24,
          marginLeft: 2,
          marginRight: 2,
        }}
        mt={6}
        mb={6}
        height={38}
      />
    </Box>
  )
}

ChangeCard.propTypes = {
  cards: PropTypes.array.isRequired,
  setOpenModal: PropTypes.func.isRequired,
}

export default ChangeCard
