import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  FormHelperText,
  InputAdornment,
} from "@material-ui/core"
import DropdownIcon from "../assets/images/desktop/ic_chevron_down.png"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
  areaFormControl: {
    marginBottom: theme.spacing(3),
  },
  inputLabel: {
    color: "#000000",
    fontSize: 14,
    transform: "translate(0, 1.5px) scale(1)",
    "& .MuiFormLabel-asterisk": {
      color: "#CA2026",
    },
    "& + .MuiInput-formControl": {
      marginTop: theme.spacing(3),
    },
  },
  areaSelect: {
    backgroundColor: "#FFF",
    borderRadius: 5,
    border: "1px solid #DBE2EA",
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: 14,
    "& .MuiSelect-selectMenu": {
      whiteSpace: 'normal',
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "#FFF",
    },
    "& .MuiInputAdornment-positionStart": {
      marginLeft: theme.spacing(1),
      position: "absolute",
    },
    "& .MuiInputAdornment-positionEnd": {
      position: "absolute",
      right: 0,
    },
  },
  input: {
    paddingLeft: props => (props.startAdornment ? 51 : 10),
  },
  areaSelectItem: {
    fontSize: 14,
    whiteSpace: 'normal',
  },
  areaError: {
    color: "#CA2026",
  },
}))

const FSelect = props => {
  const classes = useStyles(props)
  const {
    label,
    error,
    defaultOption,
    options,
    required = true,
    inputLabelAdditionalClass,
    ...rest
  } = props

  return (
    <FormControl className={classes.areaFormControl} fullWidth>
      <InputLabel
        className={
          inputLabelAdditionalClass
            ? clsx(classes.inputLabel, inputLabelAdditionalClass)
            : classes.inputLabel
        }
        required={required}
        focused={false}
      >
        {label}
      </InputLabel>
      <Select
        {...rest}
        displayEmpty
        className={classes.areaSelect}
        fullWidth
        disableUnderline
        IconComponent={() => (
          <InputAdornment position="end">
            <img src={DropdownIcon} width={24} alt="dropdown-icon" />
          </InputAdornment>
        )}
        inputProps={{
          className: classes.input,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        {defaultOption && (
          <MenuItem
            className={classes.areaSelectItem}
            value={defaultOption.value}
          >
            {defaultOption.title}
          </MenuItem>
        )}
        {options &&
          options.length &&
          options.map(o => (
            <MenuItem
              key={o.id}
              className={classes.areaSelectItem}
              value={o.value}
            >
              {o.title}
            </MenuItem>
          ))}
      </Select>
      {error && (
        <FormHelperText className={classes.areaError}>{error}</FormHelperText>
      )}
    </FormControl>
  )
}
export default FSelect
