import { Box, makeStyles } from "@material-ui/core"
import { useDispatch } from "react-redux"
import { setShowBannerDownloadApp } from "../store/features/ui/uiSlice"
import DownloadBanner from "../assets/images/mobile/download_banner.png"
import CloseIcon from "../assets/images/mobile/close.svg"
import GooglePlay from "../assets/images/mobile/google_play.png"
import AppStore from "../assets/images/mobile/app_store.png"
import DownloadApp from "../assets/images/mobile/download_app.svg"
import useOSDetect from "../hooks/useOSDetect"

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    position: "relative",
    alignItems: "center",
  },
  banner: {
    width: "100%",
    maxWidth: "100%",
  },
  bannerContent: {
    position: "absolute",
    top: 4,
    bottom: 4,
    right: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    zIndex: 1,
  },
  downloadApp: {
    display: "inherit",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down(400)]: {
      marginBottom: theme.spacing(0.7),
    },
    [theme.breakpoints.down(360)]: {
      width: 160,
    },
  },
  storeImage: {
    width: 83,
    height: "auto",
    [theme.breakpoints.down(360)]: {
      width: 73,
      height: "auto",
    },
  },
  close: {
    width: 34,
    height: 34,
  },
}))

const FBannerDownloadApp = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { OS } = useOSDetect()

  const handleClose = () => {
    dispatch(setShowBannerDownloadApp(false))
  }

  const openApp = () => {
    if (OS === "Android") {
      window.open(
        "https://play.google.com/store/apps/details?id=com.exxtra.app.android",
        "_blank",
      )
    }
    if (OS === "iOS") {
      window.open("https://apps.apple.com/app/exxtra/id1602366562", "_blank")
    }
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <img
          onClick={openApp}
          className={classes.banner}
          src={DownloadBanner}
          alt="banner"
        />
        <Box className={classes.bannerContent}>
          <Box onClick={openApp}>
            <img
              className={classes.downloadApp}
              src={DownloadApp}
              alt="download-app"
            />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center">
              <img
                className={classes.storeImage}
                src={GooglePlay}
                alt="googleplay"
              />
              <img
                className={classes.storeImage}
                src={AppStore}
                alt="appstore"
              />
            </Box>
          </Box>
          <img
            onClick={handleClose}
            className={classes.close}
            src={CloseIcon}
            alt="close"
          />
        </Box>
      </Box>
    </Box>
  )
}

export default FBannerDownloadApp
