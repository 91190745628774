import request from "../common/request"

export const apiGetSettings = async () => {
  try {
    const res = await request.get(
      "/v1/settings?include=customFields,businessCustomFields"
    )
    return res.data
  } catch (err) {
    throw err
  }
}
