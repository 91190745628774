import { forwardRef } from "react"
import DateIcon from "../assets/images/desktop/date.png"
import DropdownIcon from "../assets/images/desktop/ic_chevron_down.png"
import { Button, makeStyles, Box } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: "center",
    border: "1px solid #DBE2EA",
    borderRadius: 8,
    backgroundColor: theme.palette.common.white,
  },
  button: {
    justifyContent: "flex-start",
    color: "rgba(0, 0, 0, 0.87)",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiButton-startIcon": {
      alignItems: "center",
      marginLeft: theme.spacing(0.25),
      marginRight: theme.spacing(2),
    },
  },
}))

const FCustomInput = forwardRef((props, ref) => {
  const { value, onClick } = props
  const classes = useStyles(props)
  return (
    <Box className={classes.root} display="flex">
      <Button
        fullWidth
        className={classes.button}
        disableRipple
        startIcon={<img src={DateIcon} width={24} alt="date-picker" />}
        onClick={onClick}
        ref={ref}
      >
        {value}
      </Button>
      {<img src={DropdownIcon} width={24} alt="date-picker" />}
    </Box>
  )
})

export default FCustomInput
