import { Typography, Divider, Box } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { ROUTES } from "../../../common/config"
import { useTranslation } from "react-i18next"
import LoginForm from "./loginForm"
import AuthLayout from "../../../layout/AuthLayout"
import FLink from "../../../components/F.Link"

const Login = () => {
  const history = useHistory()
  const [t] = useTranslation("common")

  return (
    <AuthLayout>
      <LoginForm />
      <Divider style={{ margin: "20px 0" }} />
      <Box display="flex" justifyContent="space-between">
        <FLink onClick={() => history.push(ROUTES.SIGN_UP)}>
          <Typography>{`${t("login.form.text.notAMember")}?`}</Typography>
          <Typography>{t("login.form.text.signUpHere")}</Typography>
        </FLink>
        <FLink onClick={() => history.push(ROUTES.FORGET_PASSWORD)}>{`${t(
          "login.form.link.forgetPass"
        )}?`}</FLink>
      </Box>
    </AuthLayout>
  )
}
export default Login
