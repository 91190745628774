import { Box, makeStyles } from "@material-ui/core"
import { PropTypes } from "prop-types"

const useStyles = makeStyles(theme => ({
  root: {
    textDecoration: "underline",
    cursor: "pointer",
    marginBottom: theme.spacing(0.5),
    fontSize: 14,
    color: "#000000",
    fontWeight: 400,
    "& .MuiTypography-root": {
      textDecoration: "underline",
      cursor: "pointer",
      marginBottom: theme.spacing(0.5),
      fontSize: 14,
      color: "#000000",
      fontWeight: 400,
    },
  },
}))

const FLink = props => {
  const classes = useStyles()
  const { children, onClick } = props
  return (
    <Box className={classes.root} onClick={onClick}>
      {children}
    </Box>
  )
}

FLink.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default FLink
