import { sitePrefix, sortType } from "./config"
import { format, parse, set, compareAsc, compareDesc, isEqual } from "date-fns"
import { enUS, zhCN } from "date-fns/locale"
import { find } from "lodash"

export const setLocalStorage = (key, value) => {
  localStorage.setItem(`${sitePrefix}${key}`, JSON.stringify(value))
}

export const getLocalStorage = (key, fallbackValue) => {
  try {
    return localStorage.getItem(`${sitePrefix}${key}`)
      ? JSON.parse(localStorage.getItem(`${sitePrefix}${key}`))
      : fallbackValue
  } catch (err) {}
}

export const removeLocalStorage = key => {
  localStorage.removeItem(`${sitePrefix}${key}`)
}

export const setSessionStorage = (key, value) => {
  sessionStorage.setItem(`${sitePrefix}${key}`, JSON.stringify(value))
}

export const getSessionStorage = (key, fallbackValue) => {
  try {
    return sessionStorage.getItem(`${sitePrefix}${key}`)
      ? JSON.parse(sessionStorage.getItem(`${sitePrefix}${key}`))
      : fallbackValue
  } catch (err) {}
}

export const financial = x => {
  return Number.parseFloat(x).toFixed(2)
}

export const formatDate2ddMMyyyy = dateString => {
  if (dateString) {
    try {
      return format(parse(dateString, "yyyy-MM-dd", new Date()), "dd/MM/yyyy")
    } catch (err) {
      throw err
    }
  }
}

export const formatDate2yyyy_MM_dd = date => {
  return date && format(date, "yyyy-MM-dd")
}

export const mapSiteLngToStripe = lng => {
  if (lng === "en") {
    return lng
  }
  return lng === "cn" ? "zh" : "zh-HK"
}

export const mapSiteLngToBackendLng = lng => {
  return lng === "cn" ? "zh-CN" : lng
}

export const mapBackendLngToSiteLang = lng => {
  return lng === "zh-CN" ? "cn" : lng
}

export const getSubString = (text, maxLength) => {
  let endSymbol = "..."
  let result = text
  if (text.length > maxLength) {
    result = text.substring(0, maxLength - endSymbol.length)
  }
  return result === text ? text : result + endSymbol
}

export const formatTimeslotOption = option => {
  if (option) {
    const arr = option.split("-")
    if (arr && arr.length && arr.length === 2) {
      const from = formatHoursMinutes(arr[0])
      const to = formatHoursMinutes(arr[1])
      return `${from} to ${to}`
    }
  }
}

const formatHoursMinutes = value => {
  if (value && value.length && value.length === 4) {
    const fromHoursAndMinutes = value.match(/.{1,2}/g)
    const fromHours = fromHoursAndMinutes[0] ? fromHoursAndMinutes[0] : ""
    const fromMinutes = fromHoursAndMinutes[1] ? fromHoursAndMinutes[1] : ""
    const fromHours2Num = Number(fromHours)
    return fromHours2Num === 12
      ? `12:${fromMinutes}pm`
      : fromHours2Num > 12
      ? `${fromHours2Num % 12}:${fromMinutes}pm`
      : `${fromHours2Num % 12}:${fromMinutes}am`
  }
}

export const formatddLLL_EEEE = (dateString, lng) => {
  if (dateString) {
    return format(
      parse(dateString, "yyyy-MM-dd", new Date()),
      "dd LLL - EEEE",
      {
        locale: lng === "en" ? enUS : zhCN,
      },
    )
  }
}

export const formatEEEddLLLL = (dateString, lng) => {
  if (dateString) {
    return format(parse(dateString, "yyyy-MM-dd", new Date()), "EEEE dd LLLL", {
      locale: lng === "en" ? enUS : zhCN,
    })
  }
}

export const parseyyyyMMdd2Date = dateString => {
  if (dateString) {
    return parse(dateString, "yyyy-MM-dd", new Date())
  }
}

export const getOnlyDate = date => {
  if (date) {
    return set(date, {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    })
  }
}

export const getDaysAgo = (date = new Date(), number = 0) => {
  return set(date, { date: date.getDate() - number })
}

export const addDays = (date = new Date(), number) => {
  return set(date, { date: date.getDate() + number })
}

export const getAreaOptions = (areas, lng, additionText) => {
  if (areas && areas.length > 0) {
    const options = areas.map(area => ({
      id: area.id,
      value: area.id,
      title: `${lng === "cn" ? area.title["zh-CN"] : area.title[lng]} ${
        area.surcharge ? `$${financial(area.surcharge)} ${additionText}` : ""
      }`,
    }))
    return options
  }
  return null
}

export const sortByDate = (arr, sortDirection) => {
  return Array.isArray(arr) && arr.length > 0
    ? [
        ...arr.sort((item, item1) =>
          sortDirection === sortType.ASC
            ? compareAsc(
                parseyyyyMMdd2Date(item.date),
                parseyyyyMMdd2Date(item1.date),
              )
            : compareDesc(
                parseyyyyMMdd2Date(item.date),
                parseyyyyMMdd2Date(item1.date),
              ),
        ),
      ]
    : []
}

/**
 * Get items in daterange
 * @param {Array} arr  array to be filter
 * @param {Date} startDate
 * @param {Date} endDate
 * @return {Array} filtered array
 */
export const filterInDateRange = (arr, startDate, endDate) => {
  if (Array.isArray(arr)) {
    if (isEqual(startDate, endDate) || (startDate && endDate === null)) {
      const newArr = arr?.filter(item => {
        const convertedDate = parseyyyyMMdd2Date(item.date)
        return startDate.getTime() === convertedDate.getTime()
      })
      return newArr
    } else {
      const newArr = arr?.filter(item => {
        const convertedDate = parseyyyyMMdd2Date(item.date)
        return (
          convertedDate.getTime() <= endDate.getTime() &&
          convertedDate.getTime() >= startDate.getTime()
        )
      })
      return newArr
    }
  }
  return []
}

export const isDateInRange = (
  date = new Date(),
  startDate = new Date(),
  endDate = new Date(),
) => {
  return (
    date.getTime() <= endDate.getTime() && date.getTime() >= startDate.getTime()
  )
}

export const getAddressLine = (address, lng) => {
  return lng === "en"
    ? getAddressLineEng(address)
    : getAddressLineZh_cn(address)
}

const getAddressLineEng = address => {
  return [
    address?.Address?.PremisesAddress?.EngPremisesAddress?.BuildingName,
    address?.Address?.PremisesAddress?.EngPremisesAddress?.EngEstate
      ?.EstateName,
    address?.Address?.PremisesAddress?.EngPremisesAddress?.EngStreet
      ?.BuildingNoFrom,
    address?.Address?.PremisesAddress?.EngPremisesAddress?.EngStreet
      ?.StreetName,
    address?.Address?.PremisesAddress?.EngPremisesAddress?.EngDistrict
      ?.DcDistrict,
  ]
    .filter(Boolean)
    .join(", ")
}

const getAddressLineZh_cn = address => {
  return address?.Address?.PremisesAddress?.ChiPremisesAddress?.BuildingName
    ? [
        address?.Address?.PremisesAddress?.ChiPremisesAddress?.ChiDistrict
          ?.DcDistrict,
        address?.Address?.PremisesAddress?.ChiPremisesAddress?.ChiStreet
          ?.StreetName,
        address?.Address?.PremisesAddress?.ChiPremisesAddress?.ChiStreet
          ?.BuildingNoFrom,
        address?.Address?.PremisesAddress?.ChiPremisesAddress?.ChiStreet
          ?.EstateName,
        address?.Address?.PremisesAddress?.ChiPremisesAddress?.BuildingName,
      ]
        .filter(Boolean)
        .join(", ")
    : [
        address?.Address?.PremisesAddress?.ChiPremisesAddress?.ChiDistrict
          ?.DcDistrict,
        address?.Address?.PremisesAddress?.ChiPremisesAddress?.ChiVillage
          ?.LocationName,
        address?.Address?.PremisesAddress?.ChiPremisesAddress?.ChiVillage
          ?.BuildingNoFrom,
        address?.Address?.PremisesAddress?.ChiPremisesAddress?.ChiVillage
          ?.VillageName,
      ]
        .filter(Boolean)
        .join(", ")
}

export const getAddressLookupRegion = (address, lng) => {
  return lng === "en"
    ? address.Address?.PremisesAddress?.EngPremisesAddress?.Region
    : address.Address?.PremisesAddress?.ChiPremisesAddress?.Region
}

export const addressMappingRegion = (areas, region, lng) => {
  return areas.find(area => area.note[mapSiteLngToBackendLng(lng)] === region)
}

export const getTimeOptions = (date, timeslotDate, timeslots) => {
  const compared = compareAsc(
    parseyyyyMMdd2Date(date),
    parseyyyyMMdd2Date(timeslotDate),
  )
  if (compared > -1) {
    const dateInTimeslots = find(timeslots, { date })
    return dateInTimeslots.times
  }
  return null
}
