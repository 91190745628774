import { useState, useRef } from "react"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import FButton from "./F.Button"
import ConfirmIcon from "../assets/icons/confirm.png"
import { useTranslation } from "react-i18next"
import FCustomInput2 from "./F.CustomInput2"
import { makeStyles } from "@material-ui/core"
import { enUS, zhCN } from "date-fns/locale"
import { useSelector } from "react-redux"
import { Box } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  popper: {
    paddingTop: "5px !important",
  },
}))

const FDateRangePicker = props => {
  const classes = useStyles()
  const { start, end, onConfirm, ...rest } = props
  const [t] = useTranslation("common")
  const { lng } = useSelector(state => state.lng)
  const [startDate, setStartDate] = useState(start)
  const [endDate, setEndDate] = useState(end)
  const datePickerRef = useRef(null)

  const onChange = dates => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const handleConfirm = () => {
    if (datePickerRef) {
      datePickerRef.current.setOpen(false)
      onConfirm(startDate, endDate)
    }
  }

  return (
    <DatePicker
      {...rest}
      useWeekdaysShort={true}
      locale={lng === "en" ? enUS : zhCN}
      ref={datePickerRef}
      onChange={onChange}
      dateFormat="dd. MM. yyyy"
      showPopperArrow={false}
      shouldCloseOnSelect={false}
      selectsRange
      selected={startDate}
      startDate={startDate}
      endDate={endDate}
      customInput={<FCustomInput2 />}
      popperClassName={classes.popper}
    >
      <Box style={{ margin: "0 16px 8px 16px" }}>
        <FButton
          icon={ConfirmIcon}
          display="block"
          title={t("global.form.button.label.confirm")}
          onClick={handleConfirm}
          fontsizemobile={14}
          fontSize={16}
          mt={0}
          height={38}
        />
      </Box>
    </DatePicker>
  )
}

FDateRangePicker.propTypes = {
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date),
  onConfirm: PropTypes.func.isRequired,
}

export default FDateRangePicker
