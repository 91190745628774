import { useEffect, useState } from "react"
import {
  Box,
  // Typography,
  makeStyles,
  // useTheme,
  // useMediaQuery,
  // CardMedia,
  CircularProgress,
} from "@material-ui/core"
import MemberBox from "../../layout/MemberBox"
import AddCardDialog from "./components/AddCardDialog"
import AddCard from "./components/AddCard"
import ChangeCard from "./components/ChangeCard"
// import StripeLogoDesktop from "../../assets/images/desktop/stripe_logo.png"
// import StripeLogo from "../../assets/images/mobile/stripe_logo.png"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation, Trans } from "react-i18next"
import { fetchMemberCards } from "../../store/features/member/memberSlice"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(5, 3.5, 3, 3.5),
    [theme.breakpoints.down(360)]: {
      padding: theme.spacing(4, 2.5, 2, 2.5),
    },
  },
  stripeLogo: {
    width: 38,
    height: 16,
    [theme.breakpoints.up("sm")]: {
      width: 40,
      height: 17,
    },
  },
  stripeTerms: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#3B3B3B",
    verticalAlign: "middle",
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(14),
    },
    "& span": {
      color: "#635BFF",
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 900,
    },
  },
}))

const Billing = props => {
  const { onComplete } = props
  const classes = useStyles()
  const { memberCards, fetchMemberCardsLoading } = useSelector(
    state => state.member
  )
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)
  const [t] = useTranslation("common")
  // const theme = useTheme()
  // const upSm = useMediaQuery(theme.breakpoints.up("sm"))

  useEffect(() => {
    dispatch(fetchMemberCards())
  }, [dispatch])

  return (
    <MemberBox title={t("billing.header.label.billing")}>
      {fetchMemberCardsLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress size={24} style={{ color: "#CC0001" }} />
        </Box>
      ) : (
        <Box className={classes.root}>
          {memberCards.length ? (
            <ChangeCard cards={memberCards} setOpenModal={setOpenModal} />
          ) : (
            <AddCard setOpenModal={setOpenModal} />
          )}
          <AddCardDialog
            onComplete={onComplete}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
          <Box className={classes.stripeTerms}>
            <Trans>{t("billing.text.slogan")}</Trans>
            {/* <Typography className={classes.stripeTerms}>
              exXtra's secure payments are powered by
            </Typography>
            <CardMedia
              className={classes.stripeLogo}
              image={upSm ? StripeLogoDesktop : StripeLogo}
              alt="exXtra's secure payments are powered by"
            /> */}
          </Box>
        </Box>
      )}
    </MemberBox>
  )
}
export default Billing
