import request from "../common/request"

export const apiGetBillingStripToken = async () => {
  try {
    const res = await request.post("/v1/billing/stripe/token")
    return res.data
  } catch (err) {
    throw err
  }
}

export const apiGetCards = async () => {
  try {
    const res = await request.get("/v1/billing/stripe/sources")
    return res.data
  } catch (err) {
    throw err
  }
}

export const apiSaveCard = async token => {
  try {
    await request.post("/v1/billing/stripe/sources", { token })
  } catch (err) {
    throw err
  }
}
