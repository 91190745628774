import { TextField, makeStyles, InputAdornment } from "@material-ui/core"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
  inputLabel: {
    color: props => (props.inputlabelcolor ? props.inputlabelcolor : "#000000"),
    fontSize: 14,
    transform: "translate(0, 1.5px) scale(1)",
    "& .MuiFormLabel-asterisk": {
      color: "#CA2026",
    },
  },
  input: {
    backgroundColor: "#FFF",
    borderRadius: 5,
    border: "1px solid #DBE2EA",
    fontSize: 14,
    marginTop: props =>
      props.mt ? `${props.mt}px !important` : "22px !important",
    "& .MuiInputAdornment-positionStart": {
      marginLeft: theme.spacing(1.5),
    },
    "& .MuiInputAdornment-positionEnd": {
      // marginRight: theme.spacing(1.5),
    },
    "& input": {
      padding: "9px 0 9px 10px",
      "&:placeholder": {
        // color: "#BEBEBE",
        fontSize: 14,
      },
    },
    "& + p": {
      color: "#CA2026",
    },
    "& textarea": {
      padding: "2.63px 10px 0px 10px",
      lineHeight: "normal",
    },
  },
}))

const FTexField = props => {
  const classes = useStyles(props)
  const {
    autoComplete,
    error,
    startAdornmentIcon,
    endAdornmentIcon,
    important,
    inputLabelAdditionalClass,
    ...rest
  } = props

  return (
    <TextField
      {...rest}
      fullWidth
      InputLabelProps={{
        shrink: true,
        focused: false,
        error: false,
        className: inputLabelAdditionalClass
          ? clsx(classes.inputLabel, inputLabelAdditionalClass)
          : classes.inputLabel,
        required: important,
      }}
      InputProps={{
        autoComplete: autoComplete ? autoComplete : "on",
        className: classes.input,
        disableUnderline: true,
        startAdornment: startAdornmentIcon && (
          <InputAdornment position="start">{startAdornmentIcon}</InputAdornment>
        ),
        endAdornment: endAdornmentIcon && (
          <InputAdornment position="end">{endAdornmentIcon}</InputAdornment>
        ),
      }}
      helperText={error || ""}
    />
  )
}

export default FTexField
