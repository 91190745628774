import "react-datepicker/dist/react-datepicker.css"
import "./App.css"
import { useEffect } from "react"
import { BrowserRouter as Router } from "react-router-dom"
import Header from "./layout/Header"
import Footer from './layout/Footer'
import MainLayout from "./layout/MainLayout"
import FBannerDownloadApp from "./components/F.BannerDownloadApp"
import useDeviceDetect from "./hooks/useDeviceDetect"
import { CssBaseline } from "@material-ui/core"
import { useSelector, useDispatch } from "react-redux"
import i18next from "i18next"
import { fetchSettings } from "./store/features/settings/settingsSlice"
import { fetchMemberInfo } from "./store/features/member/memberSlice"

function App() {
  const { lng } = useSelector(state => state.lng)
  const { accessToken } = useSelector(state => state.auth)
  const { brand } = useSelector(state => state.settings)
  const { showBannerDownloadApp } = useSelector(state => state.ui)
  const { isMobile } = useDeviceDetect()
  const dispatch = useDispatch()


  useEffect(() => {
    i18next.changeLanguage(lng)
  }, [lng])

  useEffect(() => {
    dispatch(fetchSettings())
  }, [dispatch])

  useEffect(() => {
    accessToken && dispatch(fetchMemberInfo())
  }, [accessToken, dispatch])

  useEffect(() => {
    document.title = brand?.name
  }, [brand?.name])

  return (
    <div className="App">
      <Router>
        <CssBaseline />
        {isMobile && showBannerDownloadApp && <FBannerDownloadApp />}
        <Header />
        <MainLayout />
        <Footer/>
      </Router>
    </div>
  )
}

export default App
