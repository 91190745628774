import PropTypes from "prop-types"
import {
  Typography,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core"
import FTextField from "../../../components/F.TextField"
import FButton from "../../../components/F.Button"
import IconAddUserDesktop from "../../../assets/images/desktop/add_user.png"
import IconTick from "../../../assets/icons/tick.svg"
import { makeStyles } from "@material-ui/styles"
import { Formik } from "formik"
import * as Yup from "yup"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { orderSteps } from "../../../common/config"
import { apiSignUp, apiLogin } from "../../../api/member"
import { useSnackbar } from "notistack"
// import { valuesIn } from "lodash"
import { useDispatch } from "react-redux"
import { loggedIn } from "../../../store/features/auth/authSlice"
import { setUser } from "../../../store/features/member/memberSlice"
import { setAfterLoginPopup } from "../../../store/features/ui/uiSlice"
import { useSelector } from "react-redux"

const useStyles = makeStyles(theme => ({
  signUpForm: {
    "& .title": {
      fontSize: theme.typography.pxToRem(25),
      fontWeight: 600,
      color: "#6B6B6B",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(0.5),
      [theme.breakpoints.up("sm")]: {
        marginTop: 0,
        marginBottom: theme.spacing(1),
      },
    },
    "& .text-field": {
      marginBottom: theme.spacing(1.8),
    },
    "& .checkbox-terms": {
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: theme.spacing(1),
      "& +span": {
        lineHeight: "normal",
      },
    },
    "& .terms-text1, .terms-text2": {
      fontSize: theme.typography.pxToRem(12),
      color: "#756F86",
      lineHeight: "normal",
    },
    "& .terms-text2": {
      color: "#CA2026",
      textDecoration: "underline",
      display: "inline-block",
    },
    "& .helper-text": {
      color: "#CA2026",
    },
  },
  icon: {
    borderRadius: 5,
    width: 18,
    height: 18,
    border: "1px solid #DBE2EA",
    backgroundColor: "#FFF",
  },
  checkedIcon: {
    border: "1px solid #CC0001",
    backgroundColor: "#FFF",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: `url(${IconTick})`,
      backgroundSize: "12px 12px",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      content: '""',
    },
  },
  formControlLabel: {
    marginRight: 0,
  },
}))

const SignUpForm = props => {
  const classes = useStyles()
  const { onComplete } = props
  const dispatch = useDispatch()
  const [t] = useTranslation("common")
  const { termsUrl, customFields } = useSelector(state => state.settings)
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        terms: false,
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required(
          t("global.form.message.firstNameIsRequired")
        ),
        lastName: Yup.string().required(
          t("global.form.message.lastNameIsRequired")
        ),
        email: Yup.string()
          .required(t("global.form.message.emailIsRequired"))
          .email(t("global.form.label.email.validation.invalid")),
        password: Yup.string()
          .required(t("global.form.message.passwordIsRequired"))
          .min(7, t("signup.form.message.passwordAtLeast7Char")),
        terms: Yup.boolean().oneOf(
          [true],
          t("signup.form.message.youMustAgreeToTermsAndConditions")
        ),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await apiSignUp(values)
          const res = await apiLogin(values.email, values.password)
          dispatch(loggedIn(res))
          dispatch(setUser(res.user))
          if (customFields?.landing_alert_message_box_enable?.toLowerCase() === 'on') {
            dispatch(setAfterLoginPopup(true))
          }
          enqueueSnackbar(t("global.message.signupSuccess"), {
            variant: "success",
          })
          onComplete && onComplete({ step: orderSteps.ACCOUNT })
        } catch (err) {
          if (err.error) {
            // const { errors } = err.error
            // const errorMessage = valuesIn(errors)
            //   .map(e => e.message || e)
            //   .join("\n")
            enqueueSnackbar(
              t("signup.form.email.validation.alreadyRegistered", {
                email: values.email,
              }),
              { variant: "error" }
            )
          } else {
            enqueueSnackbar(t("global.message.serverError"), {
              variant: "error",
            })
          }
          setSubmitting(false)
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit} className={classes.signUpForm}>
          <Typography className="title" align="center">
            {t("signup.form.heading.signup")}
          </Typography>
          <FTextField
            error={errors.firstName && touched.firstName && errors.firstName}
            className="text-field"
            label={t("global.form.label.firstName")}
            name="firstName"
            onChange={handleChange}
            placeholder={t("global.form.text.pleaseEnterYourFirstName")}
          />
          <FTextField
            error={errors.lastName && touched.lastName && errors.lastName}
            className="text-field"
            label={t("global.form.label.lastName")}
            name="lastName"
            onChange={handleChange}
            placeholder={t("global.form.text.pleaseEnterYourLastName")}
          />
          <FTextField
            error={errors.email && touched.email && errors.email}
            className="text-field"
            label={t("global.text.email")}
            name="email"
            onChange={handleChange}
            placeholder={t("global.form.text.pleaseEnterYourEmailAddress")}
          />
          <FTextField
            error={errors.password && touched.password && errors.password}
            className="text-field"
            label={t("global.form.label.password")}
            name="password"
            type="password"
            onChange={handleChange}
            placeholder={t("global.form.label.pleaseEnterYourPassword")}
          />
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Checkbox
                className="checkbox-terms"
                checkedIcon={
                  <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                icon={<span className={classes.icon} />}
                onChange={handleChange}
                name="terms"
              />
            }
            label={
              <>
                <Typography className="terms-text1" component="span">
                  {t("signup.form.checkbox.label.termAndCondition")}
                </Typography>
                &nbsp;
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={termsUrl ? termsUrl : "#"}
                  className="terms-text2"
                  component="span"
                >
                  {t("global.label.termsAndConditions")}
                </a>
                &nbsp;
                <Typography className="terms-text1" component="span">
                  {t("global.label.and").toLowerCase()}
                </Typography>
                &nbsp;
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={
                    customFields?.privacy_policy
                      ? customFields?.privacy_policy
                      : "#"
                  }
                  className="terms-text2"
                  component="span"
                >
                  {t("global.label.privacyPolicy")}
                </a>
              </>
            }
          />
          {errors.terms && touched.terms && errors.terms && (
            <FormHelperText className="helper-text">
              {errors.terms}
            </FormHelperText>
          )}
          <FButton
            title={t("signup.form.button.signup")}
            icon={IconAddUserDesktop}
            disabled={isSubmitting}
          />
        </form>
      )}
    </Formik>
  )
}

SignUpForm.propTypes = {
  onComplete: PropTypes.func,
}

export default SignUpForm
