import { Box, Typography, makeStyles } from "@material-ui/core"
import PropTypes from "prop-types"
import MiddleBg from "../assets/images/desktop/middle_bg.png"

const useStyles = makeStyles(theme => ({
  memberBox: {
    backgroundImage: `url(${MiddleBg})`,
    backgroundRepeat: "repeat",
    backgroundPosition: "center",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    marginTop: theme.spacing(4),
    color: "#6B6B6B",
    fontSize: 31,
    textAlign: "center",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      fontSize: 24,
    },
  },
}))

const MemberBox = props => {
  const classes = useStyles()
  const { title, children } = props

  return (
    <Box className={classes.memberBox}>
      <Typography className={classes.title}>{title}</Typography>
      {children}
    </Box>
  )
}

MemberBox.propTypes = {
  title: PropTypes.string.isRequired,
}

export default MemberBox
