import { withStyles } from "@material-ui/styles"
import { Switch, FormControlLabel } from "@material-ui/core"

const FSwitch = withStyles(theme => ({
  root: {
    width: 44,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  formControlLabel: {
    marginRight: 0,
    "& .MuiFormControlLabel-label": {
      fontSize: theme.typography.pxToRem(12),
      color: "#6B6B6B",
    },
  },
  switchBase: {
    padding: "3px 2px",
    "&$checked": {
      //   transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        // border: "none",
      },
    },
    // "&$focusVisible $thumb": {
    //   color: "green",
    //   border: "6px solid #fff",
    // },
  },
  thumb: {
    width: 20,
    height: 20,
    border: `1px solid #DBE2EA`,
    boxShadow: "0 2px 3px #0000000D",
  },
  track: {
    boxShadow: "none",
    borderRadius: 26 / 2,
    border: `1px solid #DBE2EA`,
    backgroundColor: theme.palette.common.white,
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, label, ...rest }) => {
  return (
    <FormControlLabel
      className={classes.formControlLabel}
      control={
        <Switch
          {...rest}
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
        />
      }
      label={label}
    />
  )
})

export default FSwitch
