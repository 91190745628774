import request from "../common/request"
import base64 from "base-64"

export const apiLogin = async (email, password) => {
  try {
    const res = await request.post(
      "/v1/auth/token?include=customFields,valetOrders,items,billingMethod,settings",
      {},
      {
        headers: {
          Authorization: `Basic ${base64.encode(`${email}:${password}`)}`,
        },
      }
    )
    return res.data
  } catch (err) {
    throw err
  }
}

export const apiSignUp = async data => {
  try {
    const res = await request.post("/v1/users", { ...data })
    return res.data
  } catch (err) {
    throw err
  }
}

export const apiForgotPassword = async email => {
  try {
    return await request.post("/v1/auth/forgot-password", { email })
  } catch (err) {
    throw err
  }
}

export const apiResetPassword = async (token, email, password) => {
  try {
    return await request.post("/v1/auth/reset-password", {
      token,
      email,
      password,
    })
  } catch (err) {
    throw err
  }
}

export const apiLogout = async () => {
  try {
    return await request.post("/v1/auth/logout")
  } catch (err) {
    throw err
  }
}

export const apiGetMemberProfile = async () => {
  try {
    const res = await request.get(
      "/v1/user?include=customFields,valetOrders,items,billingMethod,settings"
    )
    return res.data
  } catch (err) {
    console.error(err)
  }
}

export const apiUpdateMemberProfile = async data => {
  try {
    const res = await request.put("v1/user", data)
    return res.data
  } catch (err) {
    throw err
  }
}

export const apiGetTimeSlots = async () => {
  try {
    const res = await request.get("v1/settings/timeslots")
    return res.data
  } catch (err) {
    console.error(err)
  }
}
