export const itemFixedData = {
  bin: {
    maximum_weight: "23kg",
    minimum_storage_period: "3",
    message_key: "orderFlow.label.bin",
  },
  doc: {
    maximum_weight: "15kg",
    minimum_storage_period: "3",
    message_key: "orderFlow.label.box",
  },
}
